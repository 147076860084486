import jwt_decode from "jwt-decode";

const Role = (canRole) => {
    var idToken = localStorage.getItem('token');
    var decoded = jwt_decode(idToken);
    var role = new Array();
    var i;
    for (i = 0; i < decoded['cognito:groups'].length; i++) {
        if (decoded['cognito:groups'][i] === "ROLE_GLAMIT_ADMIN") {
            role.push("ROLE_GLAMIT_ADMIN");
            role.push("ADMIN");
          }
        if (decoded['cognito:groups'][i].indexOf("_" + localStorage.getItem('store_id') + "_") > 0) {
            var r = decoded['cognito:groups'][i].split("_");
            role.push(r[3]);
        }

    }
    var canIn = false;
    for (i = 0; i < canRole.length; i++) {
        if (role.indexOf(canRole[i]) !== -1) {
            canIn = true;
        }
    }
    if (!canIn) {
       window.location.href = window.location.origin + "/unauthorized"
    }
}
export default Role;