import axios from 'axios';
import { getOmsApiBaseUrl } from './utils/omsUrl';

export default function getOmsOrderExchangeTicket(order) {
  axios({
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Glamit-Env': localStorage.getItem('store_id'),
    },
    responseType: 'blob',
    url: `${getOmsApiBaseUrl()}/v1/order/exchange-ticket/${order.order_id}`,
  })
    .then((response) => {
      const objectURL = window.URL.createObjectURL(new Blob([response.data]));
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = objectURL;
      document.body.appendChild(iframe);
      iframe.onload = function () {
        iframe.contentWindow.print();
        iframe.onload = null;
        setTimeout(() => {
          document.body.removeChild(iframe);
          window.URL.revokeObjectURL(objectURL);
        }, 100);
      };

      const link = document.createElement('a');
      link.href = objectURL;
      link.download = `exchange_ticket_${
        order.increment_id
      }_${new Date().toISOString()}.pdf`;

      link.click();

      document.body.appendChild(link);
      setTimeout(() => {
        document.body.removeChild(link);
      }, 100);
    })
    .catch((error) => {
      if ([401, 403].includes(error?.response?.status)) {
        window.location.href = `${window.location.origin}/login`;
      }

      if (error?.response?.status === 404) {
        alert('No se encontró un ticket de cambio para el pedido.');
        return;
      }

      alert(
        'Error al obtener el ticket de cambio. Por favor intentar nuevamente',
      );
      console.error({ error });
    });
}
