const Login = () => {

    localStorage.setItem('token', null);
    localStorage.setItem('role', null);
    localStorage.setItem('PickupChannel', null);

    //localStorage.setItem('store_id', 104);
    localStorage.setItem('store_name', null);
    //document.title = "Dashboard - Vitamina";

    if (window.location.href.indexOf(".uat") > 0 || window.location.href.indexOf("localhost") > 0) {
        window.location.href = "https://glamituat.auth.us-east-1.amazoncognito.com/oauth2/authorize?redirect_uri=https://api.auth.uat.glamit.com.ar/auth/token&response_type=CODE&scope=openid+email+profile" +
            "&client_id=6hqd19ddt4vg057c6fqv129ht0&state=" + btoa(window.location.origin + "/auth");
    } else {
        window.location.href = "https://api.auth.glamit.com.ar/auth/login?state=" + btoa(window.location.origin + "/auth");
    }
}

export default Login;
