import React, { useState, useContext, useEffect } from 'react';
import Context from '../../../context';
import Loader from '../../Loader';
import axios from 'axios';
function useInputStockSeguridad(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}
function useInputStockSeguridadChannel(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}
function useInputShowRma(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}
function useInputSaveOrderLocation(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}

function useInputPopupOperator(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}

function useInputIsMarketplace(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}
function useChannelOnline(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}
const General = (props) => {
    const [loading, setLoading] = useState(false)
    const stockSeguridad = useInputStockSeguridad('')
    const stockSeguridadChannel = useInputStockSeguridadChannel('')
    const showRma = useInputShowRma('')
    const saveOrderLocation=useInputSaveOrderLocation('')

    const popupOperator = useInputPopupOperator('')
    const isMarketplace = useInputIsMarketplace('')
    const { configData } = useContext(Context)
    const { setClearConfig } = useContext(Context)
    const { clearConfig } = useContext(Context)
    const channelOnline = useChannelOnline('')

    useEffect(() => {
        configData.map((config, index) => {
            if (config.path === "stock_seguridad") {
                stockSeguridad.addValue(config.value)
            }
            if (config.path === "stock_seguridad_channel") {
                stockSeguridadChannel.addValue(config.value)
            }
            if (config.path === "show_rma") {
                showRma.addValue(config.value)
            }
            if (config.path === "save_order_location") {
                saveOrderLocation.addValue(config.value)
            }
            if (config.path === "popup_operator") {
                popupOperator.addValue(config.value)
            }
            if (config.path === "is_marketplace") {
                isMarketplace.addValue(config.value)
            }
            if (config.path === "channel_online") {
                channelOnline.addValue(config.value)
            }
        })
    }, [configData])
    function submitHandler(event) {
        event.preventDefault()

        var config_tosend = [
            { path: "stock_seguridad", value: stockSeguridad.value(), store_id: localStorage.getItem('store_id') },
            { path: "stock_seguridad_channel", value: stockSeguridadChannel.value(), store_id: localStorage.getItem('store_id') },
            { path: "show_rma", value: showRma.value(), store_id: localStorage.getItem('store_id') },
            { path: "save_order_location", value: saveOrderLocation.value(), store_id: localStorage.getItem('store_id') },
            { path: "popup_operator", value: popupOperator.value(), store_id: localStorage.getItem('store_id') },
            { path: "is_marketplace", value: isMarketplace.value(), store_id: localStorage.getItem('store_id') },
            { path: "channel_online", value: channelOnline.value() != "" ? channelOnline.value(): "9999", store_id: localStorage.getItem('store_id') },
        ]
        setLoading(true)
        axios({
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Glamit-Env': localStorage.getItem('store_id')
            },
            url: localStorage.getItem('url') + '/config_data',
            data: config_tosend
        }).then(function (response) {
            if (response.status === 200) {
                setClearConfig(clearConfig => clearConfig + 1)
            }
            setLoading(false)
        }).catch(e => {
            if (e.response.status === 401 || e.response.status === 403) {
                window.location.href = window.location.origin + "/login"
            }
        });


    }
    return (

        <div className="main-container" id="main">
            <div className="title">Generales</div>
            <div className="actions-container">
            </div>
            <form onSubmit={submitHandler}>
                <div className="grid-container">
                    <div className="display-table"><div className="label-container"><label className="" >
                        Stock de seguridad:</label></div> </div>
                    <div className="value-container">
                        <div className="display-table">
                            <input className="form-control form-control-sm" type="number" placeholder="Todos los ítems que tengan esta cantidad o menos se guardarán con 0 en el HUB."   {...stockSeguridad.bind} />
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="display-table"><div className="label-container"><label className="" >
                        Usa stock de seguridad por local? :</label></div> </div>
                    <div className="value-container">
                        <div className="display-table">
                            <select className="form-control" {...stockSeguridadChannel.bind} >
                                <option value="1">Si</option>
                                <option value="0">No</option>
                            </select> </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="display-table"><div className="label-container"><label className="" >
                        Usa sistema de visualización de RMAs en locales? :</label></div> </div>
                    <div className="value-container">
                        <div className="display-table">
                            <select className="form-control" {...showRma.bind} >
                                <option value="1">Si</option>
                                <option value="0">No</option>
                            </select> </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="display-table"><div className="label-container"><label className="" >
                    Es tienda Marketplace? :</label></div> </div>
                    <div className="value-container">
                        <div className="display-table">
                            <select className="form-control" {...isMarketplace.bind} >
                                <option value="1">Si</option>
                                <option value="0">No</option>
                            </select> </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="display-table"><div className="label-container"><label className="" >
                        Habilitar guardar ubicación de pedidos en lockers? :</label></div> </div>
                    <div className="value-container">
                        <div className="display-table">
                            <select className="form-control" {...saveOrderLocation.bind} >
                                <option value="1">Si</option>
                                <option value="0">No</option>
                            </select> </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="display-table"><div className="label-container"><label className="" >
                        Usa popup de operador al entregar pedidos? :</label></div> </div>
                    <div className="value-container">
                        <div className="display-table">
                            <select className="form-control" {...popupOperator.bind} >
                                <option value="1">Si</option>
                                <option value="0">No</option>
                            </select> </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="display-table"><div className="label-container"><label className="" >
                       Channel Online:</label></div> </div>
                    <div className="value-container">
                        <div className="display-table">
                            <input className="form-control form-control-sm" type="number"  {...channelOnline.bind} />
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="display-table">
                        <div className="label-container">
                            <label className="">        </label>
                        </div>
                    </div>
                    <div className="display-table">
                        <input type="submit" name="Edit" className="btn btn-primary" />
                    </div>
                </div>
            </form>
            <div>{loading && (<Loader />)}</div>
        </div >
    );
}

export default General;