import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';

import Context from '../../../context';
import useGetQuote from '../../../tools/useGetQuote';
import useSaveQuote from '../../../tools/useSaveQuote';

import { Snackbar } from 'material-ui';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const QuoteModal = (props) => {
  const { urlQuote } = useGetQuote(props.items, props.codigoVendedor);
  const { setcheckoutModal } = useContext(Context);
  const { setIsOpenModal } = useContext(Context);
  const { itemsCart, setItemsCart } = useContext(Context);
  const { setitemsNoDuplicates } = useContext(Context);
  const [quoteDataToSave, setQuoteDataToSave] = useState([]);
  const [sendQuoteData, setSendQuoteData] = useState(false);
  useSaveQuote(sendQuoteData, quoteDataToSave);
  const [quoteVtex, setquoteVtex] = useState(false);
  const [codigoVendedor, setcodigoVendedor] = useState('');
  const [urlVtex, seturlVtex] = useState('');

  const [closeAfterShare, setCloseAfterShare] = useState(false);

  function open() {
    var data;
    if (localStorage.getItem('plataforma') === 'vtex') {
      window.open(props.urlQuoteVtex, '_blank');
      console.log(props.dataVtex);
      data = props.dataVtex;
    } else {
      window.open(
        urlQuote +
          '&codigovendedor=' +
          props.codigoVendedor +
          '_omni&utm_source=omnichannel_dashboard&utm_content=' +
          props.codigoVendedor +
          '&utm_medium=redirect',
        '_blank',
      );
      if (urlQuote !== null && props.action === 'redirect') {
        var url = new URL(urlQuote);
        var quote_id = url.searchParams.get('quote_id');
        data = {
          store_id: localStorage.getItem('store_id'),
          channel_id: localStorage.getItem('PickupChannel'),
          user_id: null,
          user_email: localStorage.getItem('user_email'),
          seller_code: props.codigoVendedor,
          quote_id: window.atob(quote_id),
          type: 'redirect',
        };
      }
    }
    axios({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
        'Glamit-Env': localStorage.getItem('store_id'),
      },
      data: data,
      url: localStorage.getItem('url') + '/savequote',
    })
      .then((response) => {})
      .catch((e) => {});
    setcheckoutModal(false);
    setIsOpenModal(false);
    setitemsNoDuplicates([]);
    setItemsCart([]);
  }

  function saveDataVtex() {
    const data = props.dataVtex;
    setQuoteDataToSave(data);
    setSendQuoteData(true);
  }

  useEffect(() => {
    if (props.urlQuoteVtex !== '' && props.action === 'share') {
      var data = {
        url_quote: props.urlQuoteVtex,
        client_email: props.clientEmail,
        items: removeDuplicated(),
        total: totalSum(),
      };
      axios({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          'Glamit-Env': localStorage.getItem('store_id'),
        },
        url: localStorage.getItem('url') + '/sharequote',
        data: data,
      })
        .then((response) => {
          setCloseAfterShare(true);
          saveDataVtex();
          setTimeout(function () {
            setcheckoutModal(false);
            setIsOpenModal(false);
            setitemsNoDuplicates([]);
            setItemsCart([]);
            setCloseAfterShare(true);
          }, 1000);
        })
        .catch((e) => {
          alert(e.name + ': ' + e.response.data.message);
        });
    }
  }, [props.urlQuoteVtex]);

  const totalSum = () => {
    if (itemsCart.length !== 0) {
      const subtotals = [];
      itemsCart.forEach((element) => {
        subtotals.push(
          element.price
            .substring(2, element.price.length + 1)
            .replace('.', '')
            .replace(',', '.'),
        );
      });
      const total = parseFloat(
        subtotals.reduce((acc, cur) => parseFloat(acc) + parseFloat(cur)),
      ).toLocaleString('es');
      return total;
    }
  };

  function removeDuplicated() {
    const itemsQty = [];
    itemsCart.forEach((element) => {
      const qty = itemsCart.filter((f) => f.skuOmni === element.skuOmni).length;
      element.qty = qty;
      if (itemsQty.filter((f) => f.skuOmni === element.skuOmni).length === 0) {
        itemsQty.push(element);
      }
    });
    return itemsQty;
  }

  useEffect(() => {
    if (
      props.action === 'share' &&
      urlQuote !== null &&
      localStorage.getItem('plataforma') !== 'vtex'
    ) {
      var data = {
        url_quote:
          urlQuote +
          '&codigovendedor=' +
          props.codigoVendedor +
          '_omni&utm_source=omnichannel_dashboard&utm_content=' +
          props.codigoVendedor +
          '&utm_medium=email',
        client_email: props.clientEmail,
        items: removeDuplicated(),
        total: totalSum(),
      };
      axios({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          'Glamit-Env': localStorage.getItem('store_id'),
        },
        url: localStorage.getItem('url') + '/sharequote',
        data: data,
      })
        .then((response) => {
          setCloseAfterShare(true);
          saveQuoteData('email', urlQuote);
          setTimeout(function () {
            setcheckoutModal(false);
            setIsOpenModal(false);
            setitemsNoDuplicates([]);
            setItemsCart([]);
            setCloseAfterShare(true);
          }, 1000);
        })
        .catch((e) => {
          console.log(e);
          alert(e.name + ': ' + e.response.data.message);
        });
    }
  }, [urlQuote]);

  function saveQuoteData(type, url_string) {
    var url = new URL(url_string);
    var quote_id = url.searchParams.get('quote_id');
    var data = {
      store_id: localStorage.getItem('store_id'),
      channel_id: localStorage.getItem('PickupChannel'),
      user_id: null,
      user_email: localStorage.getItem('user_email'),
      seller_code: props.codigoVendedor,
      quote_id: window.atob(quote_id),
      type: type,
    };
    setQuoteDataToSave(data);
    setSendQuoteData(true);
  }

  function handleClose() {
    setCloseAfterShare(false);
  }

  return (
    <div>
      {closeAfterShare && (
        <MuiThemeProvider>
          <Snackbar
            bodyStyle={{ backgroundColor: '#ffffff' }}
            style={{ height: '100%', left: '80%' }}
            anchororigin={{ vertical: 'top', horizontal: 'right' }}
            message={
              <div>
                <FontAwesomeIcon className='text-success mr-1' icon={faCheck} />
                <span style={{ fontWeight: '500' }} className='text-black-50'>
                  Carrito Compartido!
                </span>
              </div>
            }
            open={closeAfterShare}
            onRequestClose={handleClose}
            key='shareCart'
            autoHideDuration={2000}
          ></Snackbar>
        </MuiThemeProvider>
      )}

      {(urlQuote !== null && props.action === 'redirect') |
        (props.urlQuoteVtex !== null && props.action === 'redirect') && open()}
    </div>
  );
};
export default QuoteModal;
