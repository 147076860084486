import React, { useState, useContext, useEffect } from 'react';
import Context from '../../../context';
import Loader from '../../Loader';
import axios from 'axios';
function useInputSincPim(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}
function useInputResetStocks(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}
function useInputSincPimAll(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}
const Sincronizaciones = (props) => {
    const [loading, setLoading] = useState(false)
    const sincPim = useInputSincPim('')
    const resetStocks = useInputResetStocks(0)
    const sincPimAll = useInputSincPimAll(true)
    

    const { configData } = useContext(Context)
    const { setClearConfig } = useContext(Context)
    const { clearConfig } = useContext(Context)

    useEffect(() => {
        configData.map((config, index) => {
            if (config.path === "sinc_pim") {
                sincPim.addValue(config.value)
            }
            if (config.path === "sinc_pim_all") {
                sincPimAll.addValue(config.value)
            }
            if (config.path === "reset_stocks") {
                resetStocks.addValue(config.value)
            }
        })
    }, [configData])
    function submitHandler(event) {
        event.preventDefault()

        var config_tosend = [
            { path: "sinc_pim", value: sincPim.value(), store_id: localStorage.getItem('store_id') },
            { path: "reset_stocks", value: resetStocks.value(), store_id: localStorage.getItem('store_id') },
            { path: "sinc_pim_all", value: sincPimAll.value(), store_id: localStorage.getItem('store_id') },
        ]
        setLoading(true)
        axios({
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Glamit-Env': localStorage.getItem('store_id')
            },
            url: localStorage.getItem('url') + '/config_data',
            data: config_tosend
        }).then(function (response) {
            if (response.status === 200) {
                setClearConfig(clearConfig => clearConfig + 1)
            }
            setLoading(false)
        }).catch(e => {
            if (e.response.status === 401 || e.response.status === 403) {
                window.location.href = window.location.origin + "/login"
            }
        });
    }
    return (

        <div className="main-container" id="main">
            <div className="title">Sincronizaciones</div>
            <div className="actions-container">
            </div>
            <form onSubmit={submitHandler}>

                <div className="grid-container">
                    <div className="display-table"><div className="label-container"><label className="" >
                        ¿Sincronizar al PIM al finalizar sincro? :</label></div> </div>
                    <div className="value-container">
                        <div className="display-table">
                            <select className="form-control" {...sincPim.bind} >
                                <option value="1">Si</option>
                                <option value="0">No</option>
                            </select> </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="display-table"><div className="label-container"><label className="" >
                    ¿Sincronización al PIM completa? :</label></div> </div>
                    <div className="value-container">
                        <div className="display-table">
                            <select className="form-control" {...sincPimAll.bind} >
                                <option value="1">Si</option>
                                <option value="0">No</option>
                            </select> </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="display-table"><div className="label-container"><label className="" >
                        ¿Poner stock en 0 antes de sincronizar? :</label></div> </div>
                    <div className="value-container">
                        <div className="display-table">
                            <select className="form-control" {...resetStocks.bind} >
                                <option value="1">Si</option>
                                <option value="0">No</option>
                            </select> </div>
                    </div>
                </div>

                <div className="grid-container">
                    <div className="display-table">
                        <div className="label-container">
                            <label className="">        </label>
                        </div>
                    </div>
                    <div className="display-table">
                        <input type="submit" name="Edit" className="btn btn-primary" />
                    </div>
                </div>
            </form>
            <div>{loading && (<Loader />)}</div>
        </div>
    );
}

export default Sincronizaciones;