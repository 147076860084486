import React, { useState, useContext, useEffect } from 'react';
import Context from '../../../context';
import Loader from '../../Loader';
import axios from 'axios';
function useInputMappingDelimiter(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}
function useInputMappingSku(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}
function useInputMappingColor(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}
function useInputMappingSize(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}
function useInputMappingChannelId(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}
function useInputMappingQuantity(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}
function useInputMappingPreDelimiter(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}
function useInputMappingColorDelimiter(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}
function useInputMappingSizeDelimiter(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}
const MappingGroup = (props) => {
    const [loading, setLoading] = useState(false)
    const mappingDelimiter = useInputMappingDelimiter('')
    const mappingSku = useInputMappingSku('')
    const mappingColor = useInputMappingColor('')
    const mappingSize = useInputMappingSize('')
    const mappingChannelId = useInputMappingChannelId('')
    const mappingQuantity = useInputMappingQuantity('')
    const mappingPreDelimiter = useInputMappingPreDelimiter('')
    const mappingColorDelimiter = useInputMappingColorDelimiter('')
    const mappingSizeDelimiter = useInputMappingSizeDelimiter('')


    const { configData } = useContext(Context)
    const { setClearConfig } = useContext(Context)
    const { clearConfig } = useContext(Context)


    useEffect(() => {
        configData.map((config, index) => {
            if (config.path === "mapping_delimiter") {
                mappingDelimiter.addValue(config.value)
            }
            if (config.path === "mapping_sku") {
                mappingSku.addValue(config.value)
            }
            if (config.path === "mapping_color") {
                mappingColor.addValue(config.value)
            }
            if (config.path === "mapping_size") {
                mappingSize.addValue(config.value)
            }
            if (config.path === "mapping_channel_id") {
                mappingChannelId.addValue(config.value)
            }
            if (config.path === "mapping_quantity") {
                mappingQuantity.addValue(config.value)
            }
            if (config.path === "mapping_pre_delimiter") {
                mappingPreDelimiter.addValue(config.value)
            }
            if (config.path === "mapping_color_delimiter") {
                mappingColorDelimiter.addValue(config.value)
            }
            if (config.path === "mapping_size_delimiter") {
                mappingSizeDelimiter.addValue(config.value)
            }

        })
    }, [configData])
    function submitHandler(event) {
        event.preventDefault()

        var config_tosend = [
            { path: "mapping_delimiter", value: mappingDelimiter.value(), store_id: localStorage.getItem('store_id') },
            { path: "mapping_sku", value: mappingSku.value(), store_id: localStorage.getItem('store_id') },
            { path: "mapping_color", value: mappingColor.value(), store_id: localStorage.getItem('store_id') },
            { path: "mapping_size", value: mappingSize.value(), store_id: localStorage.getItem('store_id') },
            { path: "mapping_channel_id", value: mappingChannelId.value(), store_id: localStorage.getItem('store_id') },
            { path: "mapping_quantity", value: mappingQuantity.value(), store_id: localStorage.getItem('store_id') },
            { path: "mapping_pre_delimiter", value: mappingPreDelimiter.value(), store_id: localStorage.getItem('store_id') },
            { path: "mapping_color_delimiter", value: mappingColorDelimiter.value(), store_id: localStorage.getItem('store_id') },
            { path: "mapping_size_delimiter", value: mappingSizeDelimiter.value(), store_id: localStorage.getItem('store_id') },
        ]
        setLoading(true)
        axios({
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Glamit-Env': localStorage.getItem('store_id')
            },
            url: localStorage.getItem('url') + '/config_data',
            data: config_tosend
        }).then(function (response) {
            if (response.status === 200) {
                setClearConfig(clearConfig => clearConfig + 1)
            }
            setLoading(false)
        }).catch(e => {
            if (e.response.status === 401 || e.response.status === 403) {
                window.location.href = window.location.origin + "/login"
            }
        });


    }
    return (

        <div className="main-container" id="main">
            <div className="title">Mapeo de datos</div>
            <div className="actions-container">
            </div>

            <form onSubmit={submitHandler}>


                <div className="grid-container">
                    <div className="display-table">
                        <div className="label-container">
                            <label className=""> Delimiter:          </label>
                        </div>
                    </div>
                    <div className="value-container">
                        <div className="display-table">
                            <input className="form-control form-control-sm" type="text"   {...mappingDelimiter.bind} />
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="display-table">
                        <div className="label-container">
                            <label className=""> Sku:          </label>
                        </div>
                    </div>
                    <div className="value-container">
                        <div className="display-table">
                            <input className="form-control form-control-sm" type="text"   {...mappingSku.bind} />
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="display-table">
                        <div className="label-container">
                            <label className=""> Color:          </label>
                        </div>
                    </div>
                    <div className="value-container">
                        <div className="display-table">
                            <input className="form-control form-control-sm" type="text"   {...mappingColor.bind} />
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="display-table">
                        <div className="label-container">
                            <label className=""> Talle:          </label>
                        </div>
                    </div>
                    <div className="value-container">
                        <div className="display-table">
                            <input className="form-control form-control-sm" type="text"   {...mappingSize.bind} />
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="display-table">
                        <div className="label-container">
                            <label className=""> Channel ID:          </label>
                        </div>
                    </div>
                    <div className="value-container">
                        <div className="display-table">
                            <input className="form-control form-control-sm" type="text"   {...mappingChannelId.bind} />
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="display-table">
                        <div className="label-container">
                            <label className=""> Quantity:          </label>
                        </div>
                    </div>
                    <div className="value-container">
                        <div className="display-table">
                            <input className="form-control form-control-sm" type="text"   {...mappingQuantity.bind} />
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="display-table">
                        <div className="label-container">
                            <label className=""> Delimiter Tienda:          </label>
                        </div>
                    </div>
                    <div className="value-container">
                        <div className="display-table">
                            <input className="form-control form-control-sm" type="text"   {...mappingPreDelimiter.bind} />
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="display-table">
                        <div className="label-container">
                            <label className=""> Delimiter Color:          </label>
                        </div>
                    </div>
                    <div className="value-container">
                        <div className="display-table">
                            <input className="form-control form-control-sm" type="text"   {...mappingColorDelimiter.bind} />
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="display-table">
                        <div className="label-container">
                            <label className=""> Delimiter Talle:          </label>
                        </div>
                    </div>
                    <div className="value-container">
                        <div className="display-table">
                            <input className="form-control form-control-sm" type="text"   {...mappingSizeDelimiter.bind} />
                        </div>
                    </div>
                </div>

                <div className="grid-container">
                    <div className="display-table">
                        <div className="label-container">
                            <label className="">        </label>
                        </div>
                    </div>
                    <div className="display-table">
                        <input type="submit" name="Edit" className="btn btn-primary" />
                    </div>
                </div>
            </form>
            <div>{loading && (<Loader />)}</div>


        </div>
    );
}

export default MappingGroup;