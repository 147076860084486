import {
    useState,
    useEffect
} from 'react';
import axios from 'axios';

export default function useGetUnasigmentReasonsStore(store) {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [unasigmentReasons, setUnasignmentReasons] = useState([])

  
   useEffect(() => {
    setUnasignmentReasons([])
   }, [store])

    useEffect(() => {
        setLoading(true)
        setError(false)
        let cancel
        axios({
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization' : 'Bearer '+ localStorage.getItem('token'),
                'Glamit-Env': store.store_id
            },
            url: localStorage.getItem('url')+'/unasignment_reasons_store',
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then(response => {
            
            if (response.data.length > 0) {
                response.data.map((item, index) => {
                    return setUnasignmentReasons(prevUnasignmentReasons => [...prevUnasignmentReasons, item])
                })
            }
            setLoading(false)
            
        }).catch(e => {
            if (e.response.status === 401||e.response.status===403) {
                window.location.href = window.location.origin+"/login"
            }
            if (axios.isCancel(e)) 
            setError(true)
            return
            
        })
        return () => cancel()
    }, [store])

    return {
        loading,
        error,
        unasigmentReasons,setUnasignmentReasons
    }
}