import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Loader from '../Loader';

function useGrtStores() {
  const [stores, setStores] = useState([])
  const [loading, setLoading] = useState(true)
  var idToken = localStorage.getItem('token');
  var decoded = jwt_decode(idToken);
  localStorage.setItem('PickupChannel', null);
  useEffect(() => {
    axios({
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Glamit-Env': localStorage.getItem('store_id')
      },
      url: localStorage.getItem('url') + '/store'
    }).then(response => {
      if (response.data.length > 0) {
        response.data.map((item, index) => {
          if (decoded['cognito:groups'].indexOf("ROLE_GLAMIT_ADMIN") !== -1) {
            return setStores(prevStores => [...prevStores, item])
          } else {
            if (decoded['cognito:groups'].indexOf("ROLE_" + item.store_id + "_OMNI_ADMIN") !== -1) {
              return setStores(prevStores => [...prevStores, item])
            }
          }
        })
      }
      setLoading(false)
    }).catch(e => {
      window.location.href = window.location.origin + "/home"
    })
  }, [])
  return { stores, loading }
}
function useSetupConfig() {
  useEffect(() => {
    axios({
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Glamit-Env': localStorage.getItem('store_id')
      },
      url: localStorage.getItem('url') + '/setupconfig'
    }).then(response => {
      
    }).catch(e => {
      
    })
  }, [])
}

function SwitchStore() {
  const { stores, loading } = useGrtStores()
  useSetupConfig()
  function setStoreToApp(store) {
    localStorage.setItem('store_id', store.store_id);
    localStorage.setItem('store_name', store.store_name);
    localStorage.setItem('plataforma', store.plataforma);
    localStorage.setItem('is_multiwarehouse', store.is_multiwarehouse);
                localStorage.setItem('front_url', store.front_url);
                localStorage.setItem('seller_id', store.seller_id);
    window.location.href = window.location.origin + "/home"
  }


  return (
    <div className="container-sm">
      <ul className="list-group">
        {stores.map((store, index) => {
          return (
            <li key={index} className="list-group-item"><a className="btn btn-primary" onClick={() => setStoreToApp(store)} role="button">{store.storecode}</a></li>
          )

        })}
      </ul>
      <div>{loading && (<Loader />)}</div>
    </div>

  )
}

export default SwitchStore;
