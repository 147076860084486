import {useEffect, useState} from 'react';
import axios from 'axios';

export default function useGetQuote(items, codigoVendedor, newParam) {
    const [urlQuote, setUrlQuote] = useState(null);


    useEffect(() => {
        setUrlQuote(null)
    }, [])

    useEffect(() => {
        let cancel
        if (localStorage.getItem('plataforma') !== "vtex") {
            var url = localStorage.getItem('front_url') + "/checkout/cart/add?";
            for (let index = 0; index < items.length; index++) {
                console.log(items[index])
                url += "&sku=" + items[index]['entity_id'] + "&qty=" + items[index]['qty'] + "&seller=" + localStorage.getItem('seller_id');
            }
            axios({
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Glamit-Env': localStorage.getItem('store_id')
                },
                url: localStorage.getItem('url') + "/quote",
                params: {
                    'codigoVendedor': codigoVendedor + "_omni",
                },
                data: JSON.stringify(items),
                cancelToken: new axios.CancelToken(c => cancel = c)
            }).then(function (response) {
                console.log(response);
                if (response.status === 200) {
                    return setUrlQuote(response.data)
                }
            }).catch(e => {
                alert("Su acción no funcionó como debía. Por favor, intente más tarde o contacte al administrador");
                if (e.response !== undefined) {
                    if (e.response.status === 401 || e.response.status === 403) {
                        window.location.href = window.location.origin + "/login"
                    }
                }
                if (axios.isCancel(e)) return
            })
            return () => cancel()
        }

    }, [items])

    return newParam
}
