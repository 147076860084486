import React, { useState, useEffect } from 'react';
import moment from 'moment'
import useGetChannels from '../../../tools/useGetChannels';

const UnassignmentReasons = (props) => {

    const [unassignedReasons, setUnassignedReasons] = useState([])
    const [reasonsCount, setReasonsCount] = useState([])
    const [store, setStore] = useState(localStorage.getItem('store_id'));
    const { channels } = useGetChannels(store, 1);
    const [channelId, setChannelId] = useState(null);
    const [channelName, setChannelName] = useState(null);
    const [clearChanel, setClearChannel] = useState(1)
    const [listAux, setlistAux] = useState([])

    useEffect(() => {
        if (props.data !== undefined) {
            detailsReasons(props.data);            
        }
    }, [props.data])

    function detailsReasons(array) {
        setUnassignedReasons(array);
        var reasons = new Array();
        array.map((item, index) => {
            if (reasons[item.reason] === undefined) {
                reasons[item.reason] = 1;
            } else {
                reasons[item.reason] = reasons[item.reason] + 1;
            }
        })

        var r = new Array();
        for (const [key, value] of Object.entries(reasons)) {
            r.push({ name: key, total: value })

        }
        setReasonsCount(r)        
    }

    function handleChannelChange(event) {
        event.preventDefault()
        if (event.target.value >= 0) {
            var id = event.target.value
            var canal = channels.find(channel => channel.channel_id === Number(id))
            setChannelId(id)
            setChannelName(canal.channel_name)
        }
        else {
            setChannelId(null);
        }
        setClearChannel(clearChannel => clearChannel + 1)
    }

    useEffect(() => {
        if (channelId !== null) {
            var items = [...props.data];    
            items = items.filter(u => u.channelId === Number.parseInt(channelId));
            detailsReasons(items);            
        }else{
            detailsReasons(props.data);
        }
    }, [channelId, props.data])


    return (
        <div className="card-container">
            <div className="card-border">
                <div className="card-title-text">
                    Motivos de desasignación
			  	</div>
                <div className="charts-daterange">{moment(props.dateFrom).format("DD/MM/YYYY")} - {moment(props.dateTo).format("DD/MM/YYYY")}</div>
                <div className="input-group input-group-sm mb-3 mt-1">
                    <div className="input-group-prepend">
                        <label className="input-group-text" >Local</label>
                    </div>
                    <select className="custom-select"
                        value={channelId}
                        id="channel"
                        onChange={handleChannelChange}>
                        <option key="" value="null">Todos</option>
                        {channels.map((c, index) => {
                            return <option key={c.channel_name} value={c.channel_id}
                            >{c.channel_name}</option>
                        })}
                    </select>
                </div>
                <div className="chart-container">
                    <div className="progressbar-wrapper progressbar-total">
                        <div className="display-table">
                            <div className="table-cell">
                                <div className="progressbar-label">Total</div>
                            </div>
                        </div>
                        <div className="display-table">
                            <div className="table-cell">
                                <div className="progress-container round-xlarge">
                                    <div className="progressbar round-xlarge" style={{ width: "100%" }}></div>
                                </div>
                            </div>
                        </div>
                        <div className="display-table">
                            <div className="table-cell">
                                <div className="progressbar-number">{unassignedReasons.length}</div>
                            </div>
                        </div>
                    </div>
                    {reasonsCount.map(function (value, key, reasonsCount) {

                        var total_channel = parseFloat(value.total * 100 / unassignedReasons.length).toFixed(2)
                        return (<div className="progressbar-wrapper" key={value.name}>
                            <div className="display-table">
                                <div className="table-cell">
                                    <div className="progressbar-label">{value.name}</div>
                                </div>
                            </div>
                            <div className="display-table">
                                <div className="table-cell">
                                    <div className="progress-container round-xlarge">
                                        <div className="progressbar round-xlarge" style={{ width: total_channel + "%" }}></div>
                                    </div>
                                </div>
                            </div>
                            <div className="display-table">
                                <div className="table-cell">
                                    <div className="progressbar-number">{value.total}</div>
                                </div>
                            </div>
                        </div>)

                    })}

                </div>
            </div>
        </div>
    );
}

export default UnassignmentReasons;