import {
    useState,
    useEffect
} from 'react';
import axios from 'axios';

export default function useGetPickup(pageNumber, defdateFrom, defdateTo, defperPage, clearOrder) {

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [hasMore, setHasMore] = useState(false)
    const [dateFrom, setDateFrom] = useState(defdateFrom)
    const [dateTo, setDateTo] = useState(defdateTo)
    const [perPage] = useState(defperPage)
    const [orders, setOrders] = useState([])


    useEffect(() => {
        setOrders([])
        setDateFrom(defdateFrom)
        setDateTo(defdateTo)
    }, [clearOrder])

    useEffect(() => {
        setLoading(true)
        setError(false)
        let cancel
        axios({
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Glamit-Env': localStorage.getItem('store_id')
            },
            url: localStorage.getItem('url') + '/orders/getpickuporderswochannel?dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&perPage=' + perPage + '&page=' + pageNumber,
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then(response => {
            if (response.data.paging.page_number === response.data.paging.pages_in_oms) {
                setHasMore(true)
            } else {
                setHasMore(response.data.paging.lastPage)
            }
            if (response.data.orders.length > 0) {
                response.data.orders.map((item, index) => {
                    return setOrders(prevOrders => [...prevOrders, item])
                })
            }
            
           // setHasMore(response.data.paging.lastPage)
            setLoading(false)
        }).catch(e => {
            if (e.response !== undefined) {
                if (e.response.status === 401||e.response.status===403) {
                    window.location.href = window.location.origin+"/login"
                }
            }

            if (axios.isCancel(e)) return
            setError(true)
        })
        return () => cancel()
    }, [clearOrder, pageNumber, dateFrom, dateTo])

    return {
        loading,
        error,
        orders,
        hasMore
    }
}