import Axios from 'axios';
import {getItem} from "./storageUtil";
import {notEmptyProps} from "./objectUtils";

const axiosUtils = Axios.create({
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
    },
    responseType: 'json',
});

axiosUtils.interceptors.request.use(
    function (config) {
        if (getItem('PickupChannel') !== "null" || getItem('PickupChannel') !== null) {
            config.headers['Authorization'] = getItem('token');
            config.headers['Glamit-Env'] = getItem('store_id');
        }
        return config;
    }, function (error) {
        return Promise.reject(error);
    }
)

axiosUtils.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        const resp = error.response;


        if (resp.status === 401 || resp.status === 403) {
            setTimeout(() => {
                sessionStorage.clear();
                window.location.href = window.location.origin + "/login"
                return Promise.reject(error);
            }, 500);
        }

        if (resp.status === 400 || resp.status === 404) {
            if (notEmptyProps(resp.data, 'message')) {

            } else if (notEmptyProps(resp.data, 'messages')) {
                if (Array.isArray(resp.data['messages'])) {
                    resp.data['messages'].forEach(message => {
                         console.log(message);
                    });
                }
            }
        }
        return Promise.reject(error);
    },
);

export default axiosUtils;
