import React from 'react';

const OrderStatus = ({label}) => {
  const isLabelEmpty = label.trim() === '';
  if (isLabelEmpty) {
    return <></>;
  }

  return <>{label}</>;
};

export default OrderStatus;