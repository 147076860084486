export const GlamitOmsStates = [
  {name: "A preparar", code: "ASSIGNED"},
  {name: "A recibir", code: "DISPATCHED"},
  {name: "Listo para envío", code: "PREPARED"},
  {name: "Listo para retiro", code: "READY_FOR_PICKUP"},
  {name: "Entregado", code: "DELIVERED"},
  {name: "A Devolver", code: "RMA_REQUEST_D"},
  {name: "A Cancelar", code: "RMA_REQUEST_C"},
  {name: "RMA iniciado", code: "RMA_REQUESTED"},
];

export const APPROVED = 'APPROVED';
export const ASSIGNED = 'ASSIGNED';
export const READY_FOR_PICKUP = 'READY_FOR_PICKUP';
export const PREPARED = 'PREPARED';
export const DELIVERED = 'DELIVERED';
export const EXTERNAL_RMA_REQUEST = 'EXTERNAL_RMA_REQUEST';
export const RMA_REQUESTED = 'RMA_REQUESTED';
export const REFUNDED = 'REFUNDED';
export const DISPATCHED = 'DISPATCHED';

export const CUSTOMER = "CUSTOMER";
export const CUSTOMER_CARE = "CUSTOMER_CARE";
export const SYSTEM = "SYSTEM";

export const getOmniOrderState = (order, omsState) => {
  if (omsState.state === EXTERNAL_RMA_REQUEST) {
    switch (order.rma_requested_by_user_type) {
      case CUSTOMER:
      case CUSTOMER_CARE:
        return "Petición de cancelación por cliente"
      case SYSTEM:
        return "Petición de cancelación automática"
      default:
        return "Petición de cancelación";
    }
  }

  if (omsState.state === RMA_REQUESTED) {
    switch (order.rma_requested_by_user_type) {
      case CUSTOMER:
      case CUSTOMER_CARE:
        return "Cancelado por cliente";
      case SYSTEM:
        return "Cancelado automáticamente";
      default:
        return "Cancelado";
    }
  }

  const stateName = GlamitOmsStates.find(s => s.code === omsState.state);
  return stateName ? stateName.name : ' ';
}

// ordena los estados de mayor a menos según la fecha y devuelve el mayor fecha
export const getCurrentOrderState = (order, status) => {
  let sorted = status.slice().sort((o1, o2) =>
    o1 ? -1 : o2 ? 1 : 0
  );

  const currentOrderState = sorted[0];
  return getOmniOrderState(order, currentOrderState);
}