import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Snackbar } from '@material-ui/core';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import React, { useContext, useEffect, useState } from 'react';
import Context from '../../../context';
import useGetChannels from '../../../tools/useGetChannels';
import Loader from '../../Loader';
import axios from 'axios';

function useInputStockOnline(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}

const Televenta = (props) => {

    const [loading, setLoading] = useState(false)
    const { configData } = useContext(Context)
    const { setClearConfig } = useContext(Context)
    const { clearConfig } = useContext(Context)
    const [openSnackbar, setopenSnackbar] = useState(false)
    const [openSnackbarError, setopenSnackbarError] = useState(false)
    const { channels
    } = useGetChannels(localStorage.getItem('store_id'), 1)
    const stockOnline = useInputStockOnline('')

    useEffect(() => {
        configData.map((config, index) => {
            if (config.path === "only_stock_online") {
                stockOnline.addValue(config.value);
            }
        })
    }, [configData])

    function submitHandler(event) {
        event.preventDefault()

        var config_tosend = [
            { path: "only_stock_online", value: stockOnline.value(), store_id: localStorage.getItem('store_id') }
        ]
        setLoading(true)
        axios({
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Glamit-Env': localStorage.getItem('store_id')
            },
            url: localStorage.getItem('url') + '/config_data',
            data: config_tosend
        }).then(function (response) {
            if (response.status === 200) {
                setClearConfig(clearConfig => clearConfig + 1)
                setopenSnackbar(true);
            }
            setLoading(false)
        }).catch(e => {
            if (e.response.status === 401 || e.response.status === 403) {
                setopenSnackbarError(true);
                window.location.href = window.location.origin + "/login"
            }
        });

    }

    return (

        <div className="main-container" id="main">
            <MuiThemeProvider>
                <Snackbar className="pt-3" bodyStyle={{ backgroundColor: '#ffffff' }}
                    style={{ height: "100%", left: "85%", paddingBottom: "75%" }}
                    anchororigin={{ vertical: 'top', horizontal: 'right' }}
                    message={<div  ><FontAwesomeIcon className="text-success mr-1" icon={faCheck} /> <span style={{ fontWeight: "500" }} className="text-black-50">Configuración guardada!</span></div>}
                    open={openSnackbar}
                    autoHideDuration={2000}>
                </Snackbar>
            </MuiThemeProvider>
            <MuiThemeProvider>
                <Snackbar className="pt-3" bodyStyle={{ backgroundColor: '#ffffff' }}
                    style={{ height: "100%", left: "85%", paddingBottom: "75%" }}
                    anchororigin={{ vertical: 'top', horizontal: 'right' }}
                    message={<div  ><FontAwesomeIcon className="text-danger mr-1" icon={faTimes} /> <span style={{ fontWeight: "500" }} className="text-black-50">Error al guardar!</span></div>}
                    open={openSnackbarError}
                    autoHideDuration={2000}>
                </Snackbar>
            </MuiThemeProvider>
            <div className="title">Televenta</div>

            <form onSubmit={submitHandler}>
                <div className="grid-container">
                    <div className="display-table"><div className="label-container"><label className="" >
                        ¿Mostrar sólo stock online? :</label></div> </div>
                    <div className="value-container">
                        <div className="display-table">
                            <select className="form-control" {...stockOnline.bind} >
                                <option selected value="0">No</option>
                                <option value="1">Si</option>

                            </select>
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="display-table">
                        <div className="label-container">
                            <label className="">        </label>
                        </div>
                    </div>
                    <div className="display-table">
                        <input type="submit" name="Edit" className="btn btn-primary" />
                    </div>
                </div>
            </form>
            <div>{loading && (<Loader />)}</div>

        </div>
    );

}
export default Televenta;