import React, { useState, useEffect } from 'react';
import moment from 'moment'

const OrdersUnassignment = (props) => {
    const [unassignedOrders, setUnassignedOrders] = useState([])
    const [unassignedOrdersTotal, setUnassignedOrdersTotal] = useState([])
    

    useEffect(() => {
        if (props.data !== undefined) {
            var reasons = new Array();
            setUnassignedOrdersTotal(props.data)
            props.data.map((item, index) => {
                if(reasons[item.channelName]==undefined){
                    reasons[item.channelName]=1;
                }else{
                    reasons[item.channelName]=reasons[item.channelName]+1;
                }
            })
           
            var r = new Array();
            for (const [key, value] of Object.entries(reasons)) {
                r.push({name:key,total:value})
                
            }
            setUnassignedOrders(r)
        }
    }, [props.data])

    return (
        <div className="card-container">
            <div className="card-border">
                <div className="card-title-text">
                    Pedidos desasignados
</div>
                <div className="charts-daterange">{moment(props.dateFrom).format("DD/MM/YYYY")} - {moment(props.dateTo).format("DD/MM/YYYY")}</div>
                <div className="chart-container">
                    <div className="progressbar-wrapper progressbar-total">
                        <div className="display-table">
                            <div className="table-cell">
                                <div className="progressbar-label">Total</div>
                            </div>
                        </div>
                        <div className="display-table">
                            <div className="table-cell">
                                <div className="progress-container round-xlarge">
                                    <div className="progressbar round-xlarge" style={{width:"100%"}}></div>
                                </div>
                            </div>
                        </div>
                        <div className="display-table">
                            <div className="table-cell">
                                <div className="progressbar-number">{unassignedOrdersTotal.length}</div>
                            </div>
                        </div>
                    </div>
                    {unassignedOrders.map(function(value, key, reasonsCount) {
                       
                       var total_channel = parseFloat(value.total*100/unassignedOrdersTotal.length).toFixed(2)
                       return (<div className="progressbar-wrapper" key={value.name}>
                           <div className="display-table">
                               <div className="table-cell">
                                   <div className="progressbar-label">{value.name}</div>
                               </div>
                           </div>
                           <div className="display-table">
                               <div className="table-cell">
                                   <div className="progress-container round-xlarge">
                                       <div className="progressbar round-xlarge" style={{ width: total_channel+"%" }}></div>
                                   </div>
                               </div>
                           </div>
                           <div className="display-table">
                               <div className="table-cell">
                                   <div className="progressbar-number">{value.total}</div>
                               </div>
                           </div>
                       </div>)

                   })}
                    
                </div>
            </div>
        </div>
    );
}

export default OrdersUnassignment;
