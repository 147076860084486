import {
    useState,
    useEffect
} from 'react';
import axios from 'axios';

export default function useGetPreparationtime(dateFrom, dateTo, clearMetrics) {

    const [loading1, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [preparationTime, setPreparationTime] = useState([])


    useEffect(() => {
        setPreparationTime([])
    }, [dateFrom, dateTo, clearMetrics])

    useEffect(() => {
        setLoading(false)
        setError(false)
        let cancel
        axios({
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Glamit-Env': localStorage.getItem('store_id')
            },
            url: localStorage.getItem('url') + '/metrics/preparationtime?dateFrom=' + dateFrom + '&dateTo=' + dateTo,
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then(response => {

            if (response.data.length > 0) {

                 setPreparationTime(response.data);
            }

            setLoading(true)
            return;
        }).catch(e => {
            if (axios.isCancel(e)) return
            setError(true)
        })
        return () => cancel()
    }, [dateFrom, dateTo, clearMetrics])

    return {
        loading1,
        error,
        preparationTime
    }
}