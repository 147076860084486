import React, { useEffect } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuStore from '@material-ui/core/Menu';
import MenuStoreList from './MenuStoreList';
import axios from 'axios';
import Cart from './../Cart/index';

const Nav = (props) => {
  if (localStorage.getItem('token') === null || localStorage.getItem('token') === 'null') {
    window.location.href = window.location.origin;
  }

  useEffect(() => {
    if (localStorage.getItem('store_name') === null || localStorage.getItem('store_name') === 'null') {
      axios({
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          'Glamit-Env': localStorage.getItem('store_id')
        },
        url: localStorage.getItem('url') + '/store/get'
      })
        .then((response) => {
          localStorage.setItem('store_name', response.data.store_name);
          localStorage.setItem('plataforma', response.data.plataforma);
          localStorage.setItem('is_multiwarehouse', response.data.is_multiwarehouse);
          localStorage.setItem('front_url', response.data.front_url);
          localStorage.setItem('seller_id', response.data.seller_id);
        })
        .catch((e) => {});
    }
  }, []);

  document.title = 'Dashboard - ' + localStorage.getItem('store_name');
  const homeClass = window.location.pathname.match(/^\/home/) ? 'nav-item active' : 'nav-item';
  let omnichennelClass = 'nav-item';
  if (
    window.location.pathname.match(/^\/store/) ||
    window.location.pathname.match(/^\/metrics/) ||
    window.location.pathname.match(/^\/users/) ||
    window.location.pathname.match(/^\/pickup/) ||
    window.location.pathname.match(/^\/alerts/)
  ) {
    omnichennelClass = 'nav-item active';
  }

  const inboundClass = window.location.pathname.match(/^\/inbound/) ? 'nav-item active' : 'nav-item';
  if (inboundClass === 'nav-item active') {
    document.title = 'Recepción de Pedidos - ' + localStorage.getItem('store_name');
  }

  const storesClass = window.location.pathname.match(/^\/stores/) ? 'nav-item active' : 'nav-item';
  const usersClass = window.location.pathname.match(/^\/users/) ? 'nav-item active' : 'nav-item';
  const metricsClass = window.location.pathname.match(/^\/metrics/) ? 'nav-item active' : 'nav-item';
  const configurationClass = window.location.pathname.match(/^\/configuration/) ? 'nav-item active' : 'nav-item';
  const unasignmentReasonsClass = window.location.pathname.match(/^\/unasignmentreasons/)
    ? 'nav-item active'
    : 'nav-item';
  const surrendersClass = window.location.pathname.match(/^\/reportes/) ? 'nav-item active' : 'nav-item';
  const localesClass = window.location.pathname.match(/^\/locales/) ? 'nav-item active' : 'nav-item';

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [storeMenuShow, setStoreMenuShow] = React.useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [anchorElStore, setAnchorElStore] = React.useState(null);

  const handleClickStore = (event) => {
    setAnchorElStore(event.currentTarget);
  };

  const handleCloseStore = () => {
    setAnchorElStore(null);
  };

  function logout() {
    if (window.location.href.indexOf('.uat') > 0 || window.location.href.indexOf('localhost') > 0) {
      window.location.href =
        'https://api.auth.uat.glamit.com.ar/auth/logout?state=' + btoa(window.location.origin + '/auth');
    } else {
      window.location.href =
        'https://api.auth.glamit.com.ar/auth/logout?state=' + btoa(window.location.origin + '/auth');
    }
  }

  const role = localStorage.getItem('role').split(',');

  useEffect(() => {
    if (
      role.filter(function (value) {
        return value === 'PICKUP';
      }).length > 1
    ) {
      setStoreMenuShow(true);
    }
  }, []);

  return (
    <nav className='navbar navbar-expand-lg navbar-dark bg-dark'>
      <a className='navbar-brand' href='/' style={{ marginLeft: '16px' }}>
        <img src='assets/accenturesong-white.png' width='180px' />
      </a>
      <button
        className='navbar-toggler'
        type='button'
        data-toggle='collapse'
        data-target='#navbarSupportedContent'
        aria-controls='navbarSupportedContent'
        aria-expanded='false'
        aria-label='Toggle navigation'
      >
        <span className='navbar-toggler-icon'></span>
      </button>

      <div className='collapse navbar-collapse' id='navbarSupportedContent'>
        <ul className='navbar-nav mr-auto'>
          {(role.indexOf('ADMIN') !== -1 || role.indexOf('ADMINMARCA') !== -1 || role.indexOf('PICKUP') !== -1) && (
            <>
              <li className={homeClass}>
                <a className='nav-link' href='/home'>
                  Preparación
                </a>
              </li>
              <li className={inboundClass}>
                <a className='nav-link' href='/inbound'>
                  Recepción de pedidos
                </a>
              </li>
            </>
          )}

          {(role.indexOf('ADMIN') !== -1 || role.indexOf('ADMINMARCA') !== -1 || role.indexOf('PICKUP') !== -1) && (
            <li className={omnichennelClass}>
              <a className='nav-link dropdown' aria-controls='simple-menu' aria-haspopup='true' onClick={handleClick}>
                Omnichannel{' '}
                <svg
                  width='1em'
                  height='1em'
                  viewBox='0 0 16 16'
                  className='bi bi-chevron-down'
                  fill='currentColor'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fillRule='evenodd'
                    d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'
                  />
                </svg>
              </a>

              <Menu id='simple-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <ul className='MuiList-root MuiMenu-list MuiList-padding'>
                  {role.indexOf('ADMIN') !== -1 && (
                    <li className={storesClass}>
                      <a className='nav-link' href='/stores'>
                        Marcas
                      </a>
                    </li>
                  )}
                  {(role.indexOf('ADMIN') !== -1 || role.indexOf('ADMINMARCA') !== -1) && (
                    <li className={localesClass}>
                      <a className='nav-link' href='/locales'>
                        Locales
                      </a>
                    </li>
                  )}
                  {role.indexOf('ADMIN') !== -1 && (
                    <li className={usersClass}>
                      <a className='nav-link' href='/users'>
                        Usuarios
                      </a>
                    </li>
                  )}
                  {(role.indexOf('ADMIN') !== -1 || role.indexOf('ADMINMARCA') !== -1) && (
                    <li className={metricsClass}>
                      <a className='nav-link' href='/metrics'>
                        Métricas
                      </a>
                    </li>
                  )}
                  {role.indexOf('ADMIN') !== -1 && (
                    <li className={configurationClass}>
                      <a className='nav-link' href='/configuration'>
                        Configuraciones
                      </a>
                    </li>
                  )}
                  {role.indexOf('ADMIN') !== -1 && (
                    <li className={unasignmentReasonsClass}>
                      <a className='nav-link' href='/unasignmentreasons'>
                        Razones de desasignación
                      </a>
                    </li>
                  )}
                  {(role.indexOf('ADMIN') !== -1 || role.indexOf('ADMINMARCA') !== -1) && (
                    <li className={surrendersClass}>
                      <a className='nav-link' href='/reportes'>
                        Rendiciones
                      </a>
                    </li>
                  )}
                </ul>
              </Menu>
            </li>
          )}
        </ul>

        <span className='navbar-dark navbar-nav nav-link'>
          {storeMenuShow && (
            <div>
              <a
                className='nav-link dropdown'
                aria-controls='simple-menu-store'
                aria-haspopup='true'
                onClick={handleClickStore}
              >
                Marcas{' '}
                <svg
                  width='1em'
                  height='1em'
                  viewBox='0 0 16 16'
                  className='bi bi-chevron-down'
                  fill='currentColor'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fillRule='evenodd'
                    d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'
                  />
                </svg>
              </a>
              <MenuStore
                id='simple-menu-store'
                anchorEl={anchorElStore}
                keepMounted
                open={Boolean(anchorElStore)}
                onClose={handleCloseStore}
              >
                <MenuStoreList />
              </MenuStore>
            </div>
          )}
          {window.location.pathname.match(/^\/home/) && (
            <a
              className='nav-link'
              target='_blank'
              href='https://omnichanneldocs.glamit.com.ar/implementaciones/manual-de-operaciones-de-locales'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='1.5em'
                height='1.5em'
                fill='currentColor'
                className='bi bi-question-circle'
                viewBox='0 0 16 16'
              >
                <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z' />
                <path d='M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z' />
              </svg>
            </a>
          )}
          {window.location.pathname.match(/^\/stocks/) && <Cart />}
          {window.location.pathname.match(/^\/televenta/) && <Cart />}
          <a className='nav-link' href='#' onClick={() => logout()}>
            <svg
              width='1.5em'
              height='1.5em'
              viewBox='0 0 16 16'
              className='bi bi-power'
              fill='currentColor'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                d='M5.578 4.437a5 5 0 1 0 4.922.044l.5-.866a6 6 0 1 1-5.908-.053l.486.875z'
              ></path>
              <path fillRule='evenodd' d='M7.5 8V1h1v7h-1z'></path>
            </svg>
          </a>
        </span>
      </div>
    </nav>
  );
};

export default Nav;
