import React, { useState } from 'react';
import Context from '../../context'
import StoresTableRow from '../Rows/StoresTableRow';
import Loader from '../Loader';
import Nav from '../Nav';
import useGetStores from '../../tools/useGetStores';
import axios from 'axios';
import Role from '../../tools/role';
import UnassignmentReasonsStoreModal from '../Modals/UnassignmentReasonsStore';


function useInputStoreId(defaultValue = '') {
  const [value, setValue] = useState(defaultValue)
  const [disabled, setDisabled] = useState(false)
  return {
    bind: {
      value,
      onChange: event => setValue(event.target.value),
      disabled: disabled
    },
    clear: () => setValue(''),
    value: () => value,
    addValue: (val) => setValue(val),
    disabled: (arg) => setDisabled(arg)
  }
}
function useInputStorecode(defaultValue = '') {
  const [value, setValue] = useState(defaultValue)
  return {
    bind: {
      value,
      onChange: event => setValue(event.target.value)
    },
    clear: () => setValue(''),
    value: () => value,
    addValue: (val) => setValue(val),
  }
}
function useInputWmsEnabled(defaultValue = '') {
  const [value, setValue] = useState(defaultValue)
  const [checked, setChecked] = useState(false)
  return {
    bind: {
      value,
      onChange: event => setChecked(event.target.checked),
      checked: checked
    },
    clear: () => setValue(''),
    value: () => checked,
    addValue: (val) => setValue(val),
    addChecked: (arg) => setChecked(arg)
  }
}
function useInputStoreName(defaultValue = '') {
  const [value, setValue] = useState(defaultValue)
  return {
    bind: {
      value,
      onChange: event => setValue(event.target.value)
    },
    clear: () => setValue(''),
    value: () => value,
    addValue: (val) => setValue(val),
  }
}

function useInputPlateforma(defaultValue = '') {
  const [value, setValue] = useState(defaultValue)
  return {
    bind: {
      value,
      onChange: event => setValue(event.target.value)
    },
    clear: () => setValue(''),
    value: () => value,
    addValue: (val) => setValue(val),
  }
}
function useInputFrontUrl(defaultValue = '') {
  const [value, setValue] = useState(defaultValue)
  return {
    bind: {
      value,
      onChange: event => setValue(event.target.value)
    },
    clear: () => setValue(''),
    value: () => value,
    addValue: (val) => setValue(val),
  }
}
function useInputUrlProd(defaultValue = '') {
  const [value, setValue] = useState(defaultValue)
  return {
    bind: {
      value,
      onChange: event => setValue(event.target.value)
    },
    clear: () => setValue(''),
    value: () => value,
    addValue: (val) => setValue(val),
  }
}

function useInputSellerId(defaultValue = '') {
  const [value, setValue] = useState(defaultValue)
  return {
    bind: {
      value,
      onChange: event => setValue(event.target.value)
    },
    clear: () => setValue(''),
    value: () => value,
    addValue: (val) => setValue(val),
  }
}
function useInputIsMultiwarehouse(defaultValue = '') {
  const [value, setValue] = useState(defaultValue)
  const [checked, setChecked] = useState(false)
  return {
    bind: {
      value,
      onChange: event => setChecked(event.target.checked),
      checked: checked
    },
    clear: () => setValue(''),
    value: () => checked,
    addValue: (val) => setValue(val),
    addChecked: (arg) => setChecked(arg)
  }
}

function Stores() {

  if (localStorage.getItem('token') === null || localStorage.getItem('token') === 'null') {
    window.location.href = window.location.origin
  }
  Role(["ADMIN"])

  const [currentStore, setCurrentStore] = useState(localStorage.getItem('store_id'))
  const [clearStores, setClearStores] = useState(1)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isOpenModalUnasigmentReasons, setIsOpenModalUnasigmentReasons] = useState(false)
  const [textModal, setTextModal] = useState('')
  const [isOpenModalForm, setIsOpenModalForm] = useState(false)
  const [textModalForm, setTextModalForm] = useState('')
  const [storeToEdit, setStoreToEdit] = useState([]);
  const storeId = useInputStoreId('')
  const storecode = useInputStorecode('')
  const wmsEnabled = useInputWmsEnabled(false)
  const storeName = useInputStoreName('')
  const urlProd = useInputUrlProd('')
  const plataforma = useInputPlateforma('')
  const frontUrl = useInputFrontUrl('')
  const sellerId = useInputSellerId('')
  const isMultiwarehouse = useInputIsMultiwarehouse(false)

  const [plataformas, setPlataformas] = useState([
    { name: "Magento 1", code: "m1" },
    { name: "Magento 2", code: "m2" },
    { name: "Vtex", code: "vtex" },
  ]);

  function clearToStart() {
    setClearStores(clearStores => clearStores + 1)
  }
  const {
    stores,
    loading,
    error
  } = useGetStores(clearStores)


  function submitHandler(event) {
    event.preventDefault()
    var action = true;

    stores.map((store, index) => {
      if (store.store_id === +storeId.value() && textModal === "New Store") {
        alert('Store id exist')
        action = false;
      }
    })


    var store_tosend = {
      store_id: storeId.value(),
      storecode: storecode.value(),
      wms_enabled: wmsEnabled.value(),
      store_name: storeName.value(),
      url_prod: urlProd.value(),
      plataforma: plataforma.value(),
      front_url: frontUrl.value(),
      seller_id: sellerId.value(),
      is_multiwarehouse: isMultiwarehouse.value(),
      enabled: true,
      // store_id: localStorage.getItem('store_id')
    }
    if (action === true) {
      axios({
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Glamit-Env': localStorage.getItem('store_id')
        },
        url: localStorage.getItem('url') + '/store',
        data: store_tosend
      }).then(function (response) {
        if (response.status === 200) {
          closePopup()
          clearToStart();
        }
      }).catch(e => {
        if (e.response.status === 401 || e.response.status === 403) {
          window.location.href = window.location.origin + "/login"
        } else {
          if (axios.isCancel(e)) return
          setTextModalForm(e.name + ': ' + e.response.data.message)
          setIsOpenModalForm(true);
        }
      });
    }
  }

  function closePopup() {
    setIsOpenModal(false);
  }
  function closeModalForm() {
    setIsOpenModalForm(false);
  }
  function showModal(status, store) {


    setIsOpenModalForm(false);
    if (status === 'new') {
      setTextModal('New store')
      storeId.clear()
      storecode.clear()
      wmsEnabled.addChecked(false)
      storeName.clear()
      urlProd.clear()
      plataforma.clear()
      frontUrl.clear()
      sellerId.clear()
      isMultiwarehouse.addChecked(false)
      storeId.disabled(false)
    }
    if (status === 'edit') {

      setTextModal('Edit store')
      storeId.addValue(store.store_id)
      storecode.addValue(store.storecode)
      storeName.addValue(store.store_name)
      urlProd.addValue(store.url_prod)
      plataforma.addValue(store.plataforma)
      frontUrl.addValue(store.front_url)
      sellerId.addValue(store.seller_id)
      if (store.wms_enabled === true) {
        wmsEnabled.addChecked(true)
      } else {
        wmsEnabled.addChecked(false)
      }
      if (store.is_multiwarehouse === true) {
        isMultiwarehouse.addChecked(true)
      } else {
        isMultiwarehouse.addChecked(false)
      }
      storeId.disabled(true)
    }
    setIsOpenModal(true);
  }
  function showModalUnasignmentReasons(store) {
    setIsOpenModalForm(false);
    setIsOpenModal(false);
    setIsOpenModalUnasigmentReasons(true)
    setStoreToEdit(store);
  }

  return (

    <Context.Provider value={{ clearToStart, showModal, showModalUnasignmentReasons, setIsOpenModalUnasigmentReasons }}>
      {isOpenModalUnasigmentReasons === true &&
        <UnassignmentReasonsStoreModal store={storeToEdit} />
      }
      {isOpenModal === true &&
        <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
          <div className="modal-dialog modal-lg" role="document">
            <form onSubmit={submitHandler}>
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">{textModal}</h5>
                  <button className="close" type="button" data-dismiss="modal" aria-label="Close" onClick={() => closePopup()} >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body modal-channel">
                  {isOpenModalForm === true &&
                    <div className="alert alert-warning alert-dismissible fade show" role="alert">
                      {textModalForm}
                      <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => closeModalForm()}>
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  }
                  <div className="form-row">
                    <div className="form-group col-md-4">
                      <label className="small control-label" >Store id:</label>
                      <input className="form-control" type="number" required  {...storeId.bind} />
                    </div>
                    <div className="form-group col-md-4">
                      <label className="small control-label" >Storecode:</label>
                      <input className="form-control" type="text" required {...storecode.bind} />
                    </div>
                    <div className="form-group col-md-4">
                      <label className="col-md-10 control-label" >Wms habilitado</label>
                      <div className="col-md-2 ">
                        <input className="form-control" type="checkbox" {...wmsEnabled.bind} />
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-4">
                      <label className="small control-label" >Store Name:</label>
                      <input className="form-control" type="text" required {...storeName.bind} />
                    </div>
                    <div className="form-group col-md-4">
                      <label className="small control-label" >Plataforma:</label>
                      <select className="form-control" {...plataforma.bind} >
                        {plataformas.map((p, index) => {
                          return <option key={index} value={p.code}>{p.name}</option>
                        })}
                      </select>
                    </div>
                    <div className="form-group col-md-4">
                      <label className="small control-label" >Front url:</label>
                      <input className="form-control" type="text" required {...frontUrl.bind} />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-4">
                      <label className="small control-label" >Url Prod:</label>
                      <input className="form-control" type="text" required {...urlProd.bind} />
                    </div>
                    <div className="form-group col-md-4">
                      <label className="small control-label" >Seller id:</label>
                      <input className="form-control" type="text" required {...sellerId.bind} />
                    </div>
                    <div className="form-group col-md-4">
                      <label className="col-md-10 control-label" >¿Usa multiwarehouse?</label>
                      <div className="col-md-2 ">
                        <input className="form-control" type="checkbox" {...isMultiwarehouse.bind} />
                      </div>
                    </div>
                  </div>

                </div>
                <div className="modal-footer">
                  <button className="btn btn-secondary" type="button" data-dismiss="modal" onClick={() => closePopup()}>Cancelar</button>
                  <input type="submit" name="Edit" className="btn btn-primary" />
                </div>
              </div>
            </form>
          </div>
        </div>
      }
      <div className="page-header">
        <Nav />
      </div>
      <div className="container">
        <div className="card mt-3">
          <div className="card-header">
            <div className="row float-left col-12">
              <h1 style={{ width: '100%' }}>Stores</h1>
              <button type='button' className="btn btn-primary float-left" onClick={() => showModal('new')} >Crear</button>
            </div>
          </div>
          <div className="card-body table-responsive">
            <table className="table mt-2 table-sm table-responsive-lg">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Store id</th>
                  <th scope="col">Storecode</th>
                  <th scope="col">Habilitado wms</th>
                  <th scope="col">Store Name</th>
                  <th scope="col">Prod url</th>
                  <th scope="col">Plataforma</th>
                  <th scope="col">Front url</th>
                  <th scope="col">Seller id</th>
                  <th scope="col">¿Usa multiwarehouse?</th>
                  <th scope="col">Edit</th>
                </tr>
              </thead>
              <tbody>
                {stores.map((store, index) => {
                  return <StoresTableRow store={store} key={index} index={index} plataformas={plataformas} />
                })}
              </tbody>
            </table>
            <div>{loading && (<Loader />)}</div>
            <div>{error && 'Error'}</div>
          </div>  </div>
      </div>
    </Context.Provider>
  );
}

export default Stores;
