import {useEffect, useState} from 'react';
import {getItem} from "./utils/storageUtil";
import {_get} from "./utils/apiRequester";

export default function useGetUnassignedOrders(dateFrom, dateTo, clearMetrics) {

    const [loading3, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [unassignedOrders, setUnassignedOrders] = useState([])

    useEffect(() => {
        setUnassignedOrders([])
    }, [dateFrom, dateTo, clearMetrics])

    const unassignedorders = async (params) => {
        const url = getItem('url')
        return await _get(`${url}/metrics/unassignedorders`, params);
    }

    useEffect(() => {
        setLoading(true)
        setError(false)
        const params = {
            dateFrom,
            dateTo
        }
        unassignedorders(params).then(response => {
            const {data} = response;
            data.map((item, index) => {
                return setUnassignedOrders(prevUnassignedOrders => [...prevUnassignedOrders, item]);
            });
        }).catch(() => {
            setError(true);
            setLoading(false);
        }).finally(() => setLoading(false));
    }, [dateFrom, dateTo, clearMetrics])

    return {
        loading3,
        error,
        unassignedOrders
    }
}
