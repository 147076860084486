import React, { useState, useEffect } from 'react';
import moment from 'moment'

const DeliveredByChannel = (props) => {

    const [orders, setOrders] = useState([])

    useEffect(() => {
        if (props.data.orders !== undefined) {
            setOrders(props.data.orders)
        }
    }, [props.data.orders])

   

    return (
        <div className="card-container">
            <div className="card-border">
                <div className="card-title-text">
                    Pedidos Omnichannel (Asignados)
</div>
                <div className="charts-daterange">{moment(props.dateFrom).format("DD/MM/YYYY")} - {moment(props.dateTo).format("DD/MM/YYYY")}</div>
                <div className="chart-container" >
                    <div className="progressbar-wrapper progressbar-total">
                        <div className="display-table">
                            <div className="table-cell">
                                <div className="progressbar-label">Total</div>
                            </div>
                        </div>
                        <div className="display-table">
                            <div className="table-cell">
                                <div className="progress-container round-xlarge">
                                    <div className="progressbar round-xlarge" style={{ width: "100%" }}></div>
                                </div>
                            </div>
                        </div>
                        <div className="display-table">
                            <div className="table-cell">
                                <div className="progressbar-number">{props.data.pedidos_omni}</div>
                            </div>
                        </div>
                    </div>
                    {orders.map((order, index) => {
                        var total_channel = parseFloat(order.total_channel*100/props.data.pedidos_omni).toFixed(2)
                        return (<div className="progressbar-wrapper" key={index}>
                            <div className="display-table">
                                <div className="table-cell">
                                    <div className="progressbar-label">{order.channel_name}</div>
                                </div>
                            </div>
                            <div className="display-table">
                                <div className="table-cell">
                                    <div className="progress-container round-xlarge">
                                        <div className="progressbar round-xlarge" style={{ width: total_channel+"%" }}></div>
                                    </div>
                                </div>
                            </div>
                            <div className="display-table">
                                <div className="table-cell">
                                    <div className="progressbar-number">{order.total_channel}</div>
                                </div>
                            </div>
                        </div>)

                    })}
                    
                </div>
            </div>
        </div>


    );
}

export default DeliveredByChannel;
