import React, { useState, useContext, useEffect } from 'react';
import Context from '../../../context';
import Loader from '../../Loader';


const Sidebar = (props) => {

    const [loading, setLoading] = useState(false)
    const { setStockGroup } = useContext(Context)
    const { setFtpGroup } = useContext(Context)
    const { setApiGroup } = useContext(Context)
    const { setMappingGroup } = useContext(Context)
    const { setGeneral } = useContext(Context)
    const { setSourcingEngine } = useContext(Context)
    const { setNotification } = useContext(Context)
    const { setSincronizaciones } = useContext(Context)
    const { setAlertas } = useContext(Context)
    const { setTeleventa } = useContext(Context)

    useEffect(() => {
        setStockGroup(true)
    }, [])
    function loadPage(page) {
        setLoading(true)
        switch (page) {
            case "StockGroup":
                allPageToFalse();
                setStockGroup(true)
                setLoading(false)
                break;
            case "FtpGroup":
                allPageToFalse();
                setFtpGroup(true)
                setLoading(false)
                break;
            case "ApiGroup":
                allPageToFalse();
                setApiGroup(true)
                setLoading(false)
                break;
            case "MappingGroup":
                allPageToFalse();
                setMappingGroup(true)
                setLoading(false)
                break;
            case "General":
                allPageToFalse();
                setGeneral(true)
                setLoading(false)
                break;
            case "SourcingEngine":
                allPageToFalse();
                setSourcingEngine(true)
                setLoading(false)
                break;
            case "Notification":
                allPageToFalse();
                setNotification(true)
                setLoading(false)
                break;
            case "Sincronizaciones":
                allPageToFalse();
                setSincronizaciones(true)
                setLoading(false)
                break;
            case "Alertas":
                allPageToFalse();
                setAlertas(true)
                setLoading(false)
                break;
            case "Televenta":
                allPageToFalse();
                setTeleventa(true)
                setLoading(false)
                break;
        }

    }
    function allPageToFalse() {
        setStockGroup(false)
        setFtpGroup(false)
        setApiGroup(false)
        setMappingGroup(false)
        setGeneral(false)
        setSourcingEngine(false)
        setNotification(false)
        setSincronizaciones(false)
        setAlertas(false)
        setTeleventa(false)
    }
    return (

        <div className="menu-container" id="mySidebar">
            <div className="menu-scroller" id="scroller">

                <div className="filters-container">
                    <div className="filter-container">
                        <div className="filter-title-grid">
                            <div className="filter-title-text">
                                Stock
                            </div>
                        </div>
                        <div className="filter-options-container">
                            <div className="filter-option">
                                <div>
                                    <span className="filter-option-text" onClick={() => loadPage("StockGroup")}>Orígen</span>
                                </div>
                            </div>
                            <div className="filter-option">
                                <div>
                                    <span className="filter-option-text" onClick={() => loadPage("FtpGroup")}>FTP</span>
                                </div>
                            </div>
                            <div className="filter-option">
                                <div>
                                    <span className="filter-option-text" onClick={() => loadPage("ApiGroup")}>API</span>
                                </div>
                            </div>
                            <div className="filter-option">
                                <div>
                                    <span className="filter-option-text" onClick={() => loadPage("MappingGroup")}>Mapeo de datos</span>
                                </div>
                            </div>
                            <div className="filter-option">
                                <div>
                                    <span className="filter-option-text" onClick={() => loadPage("Sincronizaciones")}>Sincronizaciones</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="filter-container">
                        <div className="filter-title-grid">
                            <div className="filter-title-text">
                                Reglas
                            </div>
                        </div>
                        <div className="filter-options-container">
                            <div className="filter-option">
                                <div>
                                    <span className="filter-option-text" onClick={() => loadPage("General")}>Generales</span>
                                </div>
                            </div>
                            <div className="filter-option">
                                <div>
                                    <span className="filter-option-text" onClick={() => loadPage("SourcingEngine")}>Sourcing Engine</span>
                                </div>
                            </div>
                            <div className="filter-option">
                                <div>
                                    <span className="filter-option-text" onClick={() => loadPage("Notification")}>Notificaciones</span>
                                </div>
                            </div>
                            <div className="filter-option">
                                <div>
                                    <span className="filter-option-text" onClick={() => loadPage("Alertas")}>Alertas</span>
                                </div>
                            </div>
                            <div className="filter-option">
                                <div>
                                    <span className="filter-option-text" onClick={() => loadPage("Televenta")}>Televenta</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>{loading && (<Loader />)}</div>
        </div>

    );
}

export default Sidebar;