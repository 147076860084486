import React, { useEffect, useRef, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import jwt_decode from 'jwt-decode';

import Context from '../../context';
import OrderTableRow from '../Rows/OrderTableRow';
import Loader from '../Loader';
import Nav from '../Nav';
import UploadStockModal from '../Modals/UploadStockModal';
import Role from '../../tools/role';

import useSearchPreparationOrder from '../../tools/useSearchPreparationOrder';
import useGetUnassignmentReasons from '../../tools/useGetUnassignmentReasons';
import useGetChannels from '../../tools/useGetChannels';
import useGetConfigData from '../../tools/useGetConfigData';
import { GlamitOmsStates } from '../../tools/utils/omsStateUtils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

function useInputDateFrom(defaultValue = '') {
  const [value, setValue] = useState(defaultValue);
  return {
    bind: {
      value,
      onChange: (event) => setValue(event.target.value),
    },
    clear: () => setValue(''),
    value: () => value,
    setDefValue: () => setValue(defaultValue),
  };
}

function useInputDateTo(defaultValue = '') {
  const [value, setValue] = useState(defaultValue);
  return {
    bind: {
      value,
      onChange: (event) => setValue(event.target.value),
    },
    clear: () => setValue(''),
    value: () => value,
    setDefValue: () => setValue(defaultValue),
  };
}

function useInputSearch(defaultValue = '') {
  const [value, setValue] = useState(defaultValue);

  return {
    bind: {
      value,
      onChange: (event) => setValue(event.target.value),
    },
    clear: () => setValue(''),
    value: () => value,
    setDefValue: () => setValue(defaultValue),
  };
}

function Preparation() {
  if (
    localStorage.getItem('token') === null ||
    localStorage.getItem('token') === 'null'
  ) {
    window.location.href = `${window.location.origin}/login`;
  }

  const [getPickupChannel, setGetPickupChannel] = useState(false);

  Role(['PICKUP', 'ADMIN', 'ADMINMARCA']);
  const style = window.document.createElement('link');
  style.href = `${process.env.PUBLIC_URL}/css/Preparation.css`;
  style.rel = 'stylesheet';
  style.async = true;

  window.document.head.appendChild(style);

  const [store, setStore] = useState(localStorage.getItem('store_id'));
  const [role, setRole] = useState('PICKUP');

  const [clearChanel, setClearChanel] = useState(1);
  const [clearOrder, setClearOrder] = useState(1);
  const [clearRowOrder, setClearRowOrder] = useState(1);

  const date = new Date();
  date.setDate(date.getDate() - 45);

  const date_to = new Date();
  date_to.setDate(date_to.getDate() + 1);

  const [defDateFrom, setDefDateFrom] = useState(
    date.toISOString().slice(0, 10),
  );

  const [defDateTo, setDefDateTo] = useState(
    date_to.toISOString().slice(0, 10),
  );
  const DateFrom = useInputDateFrom(defDateFrom);
  const DateTo = useInputDateTo(defDateTo);

  const search = useInputSearch('');
  const [action, setAction] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [mySidebar, setMySidebar] = useState(false);
  const [searchDateState, setSearchDateState] = useState(false);
  const [searchStateState, setSearchStateState] = useState(false);
  const [searchChannelState, setSearchChannelState] = useState(false);

  const { ShippingMethods } = useState([]);
  const [textAlert, setTextAlert] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [incrementId, setIncrementId] = useState('');
  const [externalId, setExternalId] = useState('');
  const [isShowReload, setIsShowReload] = useState(false);
  const [shippingFilter, setShippingFilter] = useState([]);
  const [stateFilter, setStateFilter] = useState([]);
  const [channelsFilter, setChannelsFilter] = useState([]);
  const [stateOrders, setStateOrders] = useState(GlamitOmsStates);
  const [textModal, setTextModal] = useState('');

  const csvLink = useRef();
  const [transactionData, setTransactionData] = useState([]);
  const { unassignmentReasons } = useGetUnassignmentReasons();
  const [orderList, setOrderList] = useState([]);

  const { channels, loadingChannel, errorChannel } = useGetChannels(store, 1);
  const { loadingConfig, errorconfig, configData } = useGetConfigData(1);
  const [useOperator, setUseOperator] = useState(false);
  const [validatorNumbers, setValidatorNumbers] = useState(false);
  const [dni, setDNI] = useState('');
  const [loadingFilter, setloadingFilter] = useState(false);

  const [localPageNumber, setLocalPageNumber] = useState(1);
  const [isValidRole, setIsValidRole] = useState(false);
  const [isOpenUploadStockModal, setIsOpenUploadStockModal] = useState(false);

  const orderSearchInputRef = useRef(null);

  const filters = {
    SEARCH_BY_INCREMENT_ID: {
      key: 'SEARCH_BY_INCREMENT_ID',
      label: 'Nro. Pedido',
    },
    SEARCH_BY_EXTERNAL_ID: {
      key: 'SEARCH_BY_EXTERNAL_ID',
      label: 'Nro. Plataforma',
    },
    SEARCH_BY_DNI: {
      key: 'SEARCH_BY_DNI',
      label: 'Documento',
    },
  };
  const [activeFilter, setActiveFilter] = useState(
    filters.SEARCH_BY_INCREMENT_ID,
  );

  const reFocusInput = (ms = 500) => {
    setTimeout(() => {
      if (orderSearchInputRef.current) {
        orderSearchInputRef.current.focus();
      }
    }, ms);
  };

  useEffect(() => {
    reFocusInput();
    document.addEventListener('mouseup', () => reFocusInput(5000));
    return () => document.removeEventListener('mouseup', reFocusInput);
  }, []);

  useEffect(() => {
    if (localStorage.getItem('role').indexOf('ADMINMARCA') !== -1) {
      setRole('ADMINMARCA');
      localStorage.setItem('PickupChannel', '');
      return;
    }
    if (localStorage.getItem('role').indexOf('ADMIN') !== -1) {
      setRole('ADMIN');
      localStorage.setItem('PickupChannel', '');
    }
  }, []);

  useEffect(() => {
    const popupOperator = configData.find(
      (config) => config.path === 'popup_operator',
    );
    popupOperator !== undefined
      ? setUseOperator(popupOperator.value)
      : setUseOperator(false);
  }, [configData]);

  useEffect(() => {
    if (mySidebar) {
      window.document.getElementById('mySidebar').style.width = '250px';
      window.document.getElementById('main').style.marginLeft = '270px';
    } else {
      window.document.getElementById('mySidebar').style.width = '0';
      window.document.getElementById('main').style.marginLeft = '10px';
    }
  }, [mySidebar]);

  function openNav() {
    setMySidebar(true);
  }

  function closeNav() {
    setMySidebar(false);
  }

  function clearToStart() {
    setClearOrder((clearOrder) => clearOrder + 1);
  }

  function clearToStartRow() {
    setClearRowOrder((clearRowOrder) => clearRowOrder + 1);
  }

  const { orders, loading, error, totalPages, totalOrders } =
    useSearchPreparationOrder(
      defDateFrom,
      defDateTo,
      clearOrder,
      clearRowOrder,
      externalId,
      incrementId,
      dni,
      getPickupChannel,
      localPageNumber,
      stateFilter,
      channelsFilter,
    );

  function onNext({ selected }) {
    setLocalPageNumber(selected + 1);
    clearToStart();
  }

  useEffect(() => {
    if (isShowReload) {
      setOrderList(orders);
      return;
    }
    setOrderList(orders);
  }, [stateFilter, shippingFilter, channelsFilter, orders, clearOrder]);

  function submitHandler(event) {
    event.preventDefault();
    const msDiff =
      new Date(DateTo.value()).getTime() - new Date(DateFrom.value()).getTime(); // Future date - current date
    const diff = Math.floor(msDiff / (1000 * 60 * 60 * 24));
    if (diff > 365) {
      DateFrom.setDefValue();
      DateTo.setDefValue();
      alert(
        'Por favor ingresar un rango de fechas que contenga menos de 365 días',
      );
    } else {
      setDefDateFrom((defDateFrom) => DateFrom.value());
      setDefDateTo((defDateTo) => DateTo.value());
      clearToStart();
    }
  }

  function actionSelectChange(event) {
    setAction(event.target.value);
  }

  function dynamicSort(property) {
    let sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }

    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      const result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  function getAllChecked() {
    if (action === 'pickuplist') {
      document.getElementById('handleAllChecked').checked = false;
      setTextAlert('');
      showModal(<Loader></Loader>);

      const checkedArr = [];
      const checkeds = document.getElementsByClassName('checkboxorder');
      for (let i = 0; i < checkeds.length; i++) {
        if (checkeds[i].checked) {
          checkedArr.push(checkeds[i].value);
        }
      }
      if (checkedArr.length === 0) {
        alert('Debe seleccionar al menos una orden');
        setIsOpenModal(false);
      } else {
        orders.map((order, index) => {
          if (checkedArr.indexOf(order.order_id) !== -1) {
            order.skus.map((sku, i) => {
              setTransactionData((prevTransactionData) => [
                ...prevTransactionData,
                {
                  nro_pedido: order.increment_id,
                  sku: sku.sku,
                  color: sku.sku_color,
                  talle: sku.sku_size,
                  cantidad: sku.qty,
                  'nombre SKU': sku.sku_name,
                },
              ]);
            });
          }
        });
        setIsOpenModal(false);

        setTimeout(function () {
          console.log(transactionData);
          csvLink.current.link.click();
        }, 1000);
      }
    } else if (action == 'uploadStock') {
      setIsOpenUploadStockModal(true);
    }

    if (action === null || action === 'null') {
      alert('Debe seleccionar una Accion');
    }
  }

  function handleAllChecked() {
    if (isChecked) {
      setIsChecked(false);
    } else {
      setIsChecked(true);
    }
  }

  function showModal(text) {
    setIsOpenModal(true);
    setTextModal(text);
  }

  function closePopup() {
    setIsOpenModal(false);
  }

  useEffect(() => {
    if (searchDateState) {
      window.document.getElementById('searchDate').style.maxHeight = '1800px';
      window.document
        .getElementById('searchDateChevron')
        .classList.add('filter-selected');
    } else {
      window.document.getElementById('searchDate').style.maxHeight = '0';
      window.document
        .getElementById('searchDateChevron')
        .classList.remove('filter-selected');
    }
  }, [searchDateState]);

  function searchDate() {
    if (searchDateState) {
      setSearchDateState(false);
    } else {
      setSearchDateState(true);
      setSearchStateState(false);
      setSearchChannelState(false);
    }
  }

  useEffect(() => {
    if (searchStateState) {
      window.document.getElementById('searchState').style.maxHeight = '1800px';
      window.document
        .getElementById('searchStateChevron')
        .classList.add('filter-selected');
    } else {
      window.document.getElementById('searchState').style.maxHeight = '0';
      window.document
        .getElementById('searchStateChevron')
        .classList.remove('filter-selected');
    }
  }, [searchStateState]);

  function searchState() {
    if (searchStateState) {
      setSearchStateState(false);
    } else {
      setSearchStateState(true);
      setSearchDateState(false);
      setSearchChannelState(false);
    }
  }

  useEffect(() => {
    if (role === 'ADMINMARCA' || role === 'ADMIN') {
      if (searchChannelState) {
        window.document.getElementById('searchChannel').style.maxHeight =
          '1800px';
        window.document
          .getElementById('searchChannelsChevron')
          .classList.add('filter-selected');
      } else {
        window.document.getElementById('searchChannel').style.maxHeight = '0';
        window.document
          .getElementById('searchChannelsChevron')
          .classList.remove('filter-selected');
      }
    }
  }, [searchChannelState]);

  function searchChannels() {
    if (searchChannelState) {
      setSearchChannelState(false);
    } else {
      setSearchChannelState(true);
      setSearchStateState(false);
      setSearchDateState(false);
    }
  }

  function addChannelsFilter(channelId) {
    if (channelId === null || channelId === 'null') {
      return;
    }
    if (channelsFilter.indexOf(channelId) === -1) {
      setChannelsFilter((prevChannelsFilter) => [
        ...prevChannelsFilter,
        channelId,
      ]);
      clearToStart();
    }
  }

  function showShippingFilter(filter) {
    for (const method of ShippingMethods) {
      if (method['code'] === filter) {
        return method['name'];
      }
    }
  }

  function removeShippingFilter(event) {
    const filter = event.target.attributes.getNamedItem('data-id').value;
    setShippingFilter(shippingFilter.filter((item) => item !== filter));
  }

  function removeChannelsFilter(filter) {
    setChannelsFilter(channelsFilter.filter((item) => item !== filter));
    clearToStart();
  }

  function addStateFilter(code) {
    if (code === 'RMA_REQUEST_D') {
      setStateFilter([
        ...stateFilter.filter((s) => s !== 'RMA_REQUEST_C'),
        code,
      ]);
      clearToStart();
      return;
    }
    if (code === 'RMA_REQUEST_C') {
      setStateFilter([
        ...stateFilter.filter((s) => s !== 'RMA_REQUEST_D'),
        code,
      ]);
      clearToStart();
      return;
    }
    if (stateFilter.indexOf(code) === -1) {
      setStateFilter((prevStateFilter) => [...prevStateFilter, code]);
      clearToStart();
    }
  }

  function showStateFilter(filter) {
    for (const stateOrder of stateOrders) {
      if (stateOrder['code'] === filter) {
        return stateOrder['name'];
      }
    }
  }

  function showChannelFilter(filter) {
    for (const channel of channels) {
      if (channel['channel_id'] === filter) {
        return channel['channel_name'];
      }
    }
  }

  function removeStateFilter(filter) {
    setStateFilter(stateFilter.filter((item) => item !== filter));
    clearToStart();
  }

  function clearAllFilters() {
    setStateFilter([]);
    setShippingFilter([]);
    setChannelsFilter([]);
    reloadSearch();
    clearToStart();
  }

  function reloadSearch() {
    setIsShowReload(false);
    setIncrementId('');
    setExternalId('');
    setDNI('');
    search.clear();
    clearToStart();
  }

  function hasSearchValue() {
    return search.value().length > 0 && !validatorNumbers;
  }

  const handleSearchOrder = () => {
    if (hasSearchValue()) {
      runOrderSearch();
    }
  };

  const runOrderSearch = () => {
    setDNI('');
    setExternalId('');
    setIncrementId('');

    if (activeFilter.key === 'SEARCH_BY_INCREMENT_ID') {
      setIncrementId(search.value());
    } else if (activeFilter.key === 'SEARCH_BY_EXTERNAL_ID') {
      setExternalId(search.value());
    } else if (activeFilter.key === 'SEARCH_BY_DNI') {
      setDNI(search.value());
    }

    setLocalPageNumber(1);
    setIsShowReload(true);
  };

  useEffect(() => handleSearchOrder(), [activeFilter]);

  useEffect(() => {
    setValidatorNumbers(false);
    if (search.value().length !== 0) {
      const re = /^[1-9]\d*(-\d+)?$/;
      if (!re.test(search.value())) {
        setValidatorNumbers(true);
      }
    }
  }, [search]);

  useEffect(() => {
    let decoded = jwt_decode(localStorage.getItem('token'));
    let i;
    for (i = 0; i < decoded['cognito:groups'].length; i++) {
      if (
        decoded['cognito:groups'][i] === 'ROLE_' + store + '_OMNI_ADMIN' ||
        decoded['cognito:groups'][i] === 'ROLE_GLAMIT_ADMIN'
      ) {
        setIsValidRole(true);
      }
    }
  }, [isValidRole]);

  return (
    <Context.Provider
      value={{
        clearToStart,
        clearToStartRow,
        showModal,
        setIsOpenUploadStockModal,
        isChecked,
        unassignmentReasons,
        channels,
        configData,
      }}
    >
      {isOpenModal === true && (
        <div
          className='modal'
          tabIndex='-1'
          role='dialog'
          style={{ display: 'block' }}
        >
          <div className='modal-dialog' role='document'>
            <div className='modal-content'>
              <div className='modal-body'>
                <p>{textModal}</p>
              </div>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-secondary'
                  data-dismiss='modal'
                  onClick={() => closePopup()}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isOpenUploadStockModal === true && <UploadStockModal />}
      <div className='page-header'>
        <Nav />
      </div>

      <div className='page-container'>
        <div className='menu-container' id='mySidebar'>
          <a className='closebtn' onClick={closeNav}>
            {' '}
            <svg
              width='1em'
              height='1em'
              viewBox='0 0 16 16'
              className='bi bi-x'
              fill='currentColor'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z'
              />
            </svg>
          </a>
          <div className='menu-scroller' id='scroller'>
            <div className='filters-title'>Filtros</div>
            <div className='active-filters-container'>
              {shippingFilter.map((filter, index) => {
                return (
                  <div
                    key={index}
                    className='active-filter-box'
                    data-id={filter}
                  >
                    <div className='display-table'>
                      <div className='active-filter-text'>
                        {showShippingFilter(filter)}
                      </div>
                    </div>
                    <div className='display-table' data-id={filter}>
                      <div
                        className='active-filter-close'
                        data-id={filter}
                        onClick={removeShippingFilter}
                      >
                        <svg
                          data-id={filter}
                          width='1em'
                          height='1em'
                          viewBox='0 0 16 16'
                          className='bi bi-x'
                          fill='currentColor'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            fillRule='evenodd'
                            d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z'
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                );
              })}

              {stateFilter.map((filter, index) => {
                return (
                  <div key={index} className='active-filter-box'>
                    <div className='display-table'>
                      <div className='active-filter-text'>
                        {showStateFilter(filter)}
                      </div>
                    </div>
                    <div className='display-table' data-id={filter}>
                      <div
                        className='active-filter-close'
                        data-id={filter}
                        onClick={() => removeStateFilter(filter)}
                      >
                        <svg
                          data-id={filter}
                          width='1em'
                          height='1em'
                          viewBox='0 0 16 16'
                          className='bi bi-x'
                          fill='currentColor'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            fillRule='evenodd'
                            d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z'
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                );
              })}

              {channelsFilter.map((filter, index) => {
                return (
                  <div key={index} className='active-filter-box'>
                    <div className='display-table' data-id={filter}>
                      <div className='active-filter-text'>
                        {showChannelFilter(filter)}
                      </div>
                    </div>
                    <div className='display-table' data-id={filter}>
                      <div
                        className='active-filter-close'
                        data-id={filter}
                        onClick={() => removeChannelsFilter(filter)}
                      >
                        <svg
                          data-id={filter}
                          width='1em'
                          height='1em'
                          viewBox='0 0 16 16'
                          className='bi bi-x'
                          fill='currentColor'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            fillRule='evenodd'
                            d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z'
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                );
              })}

              <div className='active-filter-box'>
                <div className='display-table'>
                  <div className='active-filter-text'>
                    Desde {moment(defDateFrom).format('DD/MM/YYYY')}
                  </div>
                </div>
              </div>
              <div className='active-filter-box'>
                <div className='display-table'>
                  <div className='active-filter-text'>
                    Hasta {moment(defDateTo).format('DD/MM/YYYY')}
                  </div>
                </div>
              </div>
            </div>
            <div className='clear-filters' onClick={clearAllFilters}>
              Limpiar filtros
            </div>
            <div className='filters-container'>
              <div className='filter-container'>
                <div
                  className='filter-title-grid'
                  id='searchDateChevron'
                  onClick={searchDate}
                >
                  <div className='filter-title-chevron'>
                    <svg
                      width='1em'
                      height='1em'
                      viewBox='0 0 16 16'
                      className='bi bi-chevron-right'
                      fill='currentColor'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        fillRule='evenodd'
                        d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'
                      />
                    </svg>
                  </div>
                  <div className='filter-title-text'>Fecha de creación</div>
                </div>
                <div className='filter-options-container' id='searchDate'>
                  <form onSubmit={submitHandler}>
                    <div className='filter-option'>
                      <div>
                        <span className='filter-option-text-date filter-option-selected'>
                          Desde
                        </span>
                        <input
                          type='text'
                          className='date-filter-input'
                          placeholder={DateFrom}
                          {...DateFrom.bind}
                        />
                        <span className='date-fiter-go'>
                          <svg
                            onClick={submitHandler}
                            width='1em'
                            height='1em'
                            viewBox='0 0 16 16'
                            className='bi bi-arrow-right-short'
                            fill='currentColor'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              fillRule='evenodd'
                              d='M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z'
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                    <div className='filter-option'>
                      <div>
                        <span className='filter-option-text-date filter-option-selected'>
                          Hasta
                        </span>
                        <input
                          type='text'
                          className='date-filter-input'
                          placeholder={DateTo}
                          {...DateTo.bind}
                        />
                        <span className='date-fiter-go'>
                          <svg
                            onClick={submitHandler}
                            width='1em'
                            height='1em'
                            viewBox='0 0 16 16'
                            className='bi bi-arrow-right-short'
                            fill='currentColor'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              fillRule='evenodd'
                              d='M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z'
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className='filter-container'>
                <div
                  className='filter-title-grid'
                  id='searchStateChevron'
                  onClick={searchState}
                >
                  <div className='filter-title-chevron'>
                    <svg
                      width='1em'
                      height='1em'
                      viewBox='0 0 16 16'
                      className='bi bi-chevron-right'
                      fill='currentColor'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        fillRule='evenodd'
                        d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'
                      />
                    </svg>
                  </div>
                  <div className='filter-title-text'>Estados</div>
                </div>
                <div className='filter-options-container' id='searchState'>
                  {stateOrders.map((stateOrder, index) => {
                    return (
                      <div
                        key={index}
                        className='filter-option'
                        onClick={() => addStateFilter(stateOrder.code)}
                      >
                        <div>
                          <span
                            data-tag={stateOrder.code}
                            className={
                              stateFilter.length > 0 &&
                              stateFilter.indexOf(stateOrder.code) !== -1
                                ? 'filter-option-text filter-option-selected'
                                : 'filter-option-text'
                            }
                          >
                            {stateOrder.name !== 'Aprobado'
                              ? stateOrder.name
                              : null}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              {(role === 'ADMINMARCA' || role === 'ADMIN') && (
                <div className='filter-container'>
                  <div
                    className='filter-title-grid'
                    id='searchChannelsChevron'
                    onClick={searchChannels}
                  >
                    <div className='filter-title-chevron'>
                      <svg
                        width='1em'
                        height='1em'
                        viewBox='0 0 16 16'
                        className='bi bi-chevron-right'
                        fill='currentColor'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fillRule='evenodd'
                          d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'
                        />
                      </svg>
                    </div>
                    <div className='filter-title-text'>Locales</div>
                  </div>
                  <div className='filter-options-container' id='searchChannel'>
                    {channels.map((channel, index) => {
                      return (
                        <div
                          key={index}
                          className='filter-option'
                          onClick={() => addChannelsFilter(channel.channel_id)}
                        >
                          <div>
                            <span
                              data-tag={channel.channel_id}
                              className={
                                channelsFilter.length > 0 &&
                                channelsFilter.indexOf(channel.channel_id) !==
                                  -1
                                  ? 'filter-option-text filter-option-selected'
                                  : 'filter-option-text'
                              }
                            >
                              {channel.channel_name}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='main-container' id='main'>
          <div className='title'>Listado de pedidos</div>
          <div className='actions-container'>
            <div className='search-container'>
              <div className='row'>
                <div className='col-10'>
                  <a className='openbtn' onClick={openNav}>
                    ☰ Ver filtros
                  </a>
                </div>
              </div>
              <div className='actions'>&nbsp;</div>
              <div className='row'>
                <div className='col-md-8 col-12'>
                  <div className='input-group input-group-sm pl-0'>
                    <input
                      ref={orderSearchInputRef}
                      id='searchInput'
                      type='text'
                      className='form-control'
                      aria-label='...'
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                          runOrderSearch();
                        }
                      }}
                      {...search.bind}
                    />
                    <div className='input-group-btn'>
                      <button
                        type='button'
                        className='btn btn-secondary dropdown-toggle btn-sm pb-0'
                        data-toggle='dropdown'
                        aria-haspopup='true'
                        aria-expanded='false'
                        style={{ margin: '0 5px' }}
                      >
                        {activeFilter.label}
                      </button>
                      <button
                        type='button'
                        className='btn btn-primary btn-sm pb-0'
                        onClick={() => handleSearchOrder()}
                        disabled={!hasSearchValue()}
                        style={{
                          cursor: hasSearchValue() ? 'pointer' : 'not-allowed',
                        }}
                      >
                        <FontAwesomeIcon className='mr-1' icon={faSearch} />
                      </button>
                      <div className='dropdown-menu dropdown-menu-right'>
                        <button
                          className='dropdown-item'
                          type='button'
                          onClick={() =>
                            setActiveFilter(filters.SEARCH_BY_INCREMENT_ID)
                          }
                        >
                          Nro. Pedido
                        </button>
                        <button
                          className='dropdown-item'
                          type='button'
                          onClick={() =>
                            setActiveFilter(filters.SEARCH_BY_EXTERNAL_ID)
                          }
                        >
                          Nro. Plataforma
                        </button>
                        <button
                          className='dropdown-item'
                          type='button'
                          onClick={() => setActiveFilter(filters.SEARCH_BY_DNI)}
                        >
                          Documento
                        </button>
                      </div>
                    </div>
                    {isShowReload === true && (
                      <div className='mt-1'>
                        <svg
                          onClick={reloadSearch}
                          xmlns='http://www.w3.org/2000/svg'
                          width='1em'
                          height='1em'
                          fill='currentColor'
                          className='bi bi-arrow-repeat'
                          viewBox='0 0 16 16'
                        >
                          <path d='M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z' />
                          <path
                            fillRule='evenodd'
                            d='M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z'
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {validatorNumbers === true && search.value().length !== 0 && (
                <div className='row'>
                  <div className='col-12 mt-0 pt-0 pb-1'>
                    <strong
                      className='text-white p-1 mb-4 rounded bg-danger'
                      style={{ fontWeight: '500', fontSize: '0.8rem' }}
                    >
                      Sólo se permiten números
                    </strong>
                  </div>
                </div>
              )}
              <div className='row d-block'>
                {isShowReload &&
                  search.value().length !== 0 &&
                  orderList.length === 0 &&
                  !validatorNumbers &&
                  !loading && (
                    <div className='col-12 mt-1'>
                      <div
                        className='p-1 alert alert-warning alert-warning-custom'
                        style={{ fontSize: '0.9rem' }}
                        role='alert'
                      >
                        No se encontraron pedidos con los datos ingresados!
                      </div>
                    </div>
                  )}
              </div>
              <div className='row'>
                <div className='col-10'>
                  <div className='mb-2' style={{ fontSize: '0.8rem' }}>
                    <span>Total de registros encontrados: {totalOrders}</span>
                  </div>
                </div>
              </div>
            </div>
            {orders.length !== 0 && (
              <div className='action-select-container'>
                <div className='acdb'>
                  <div className='actions'>Acciones</div>
                  <select
                    className='actions-select'
                    onChange={actionSelectChange}
                  >
                    <option value='null'>Seleccione una opción</option>
                    <option value='pickuplist'>
                      Generar listado de pickeo
                    </option>
                    {isValidRole && (
                      <option value='uploadStock'>
                        Importar stocks desde CSV
                      </option>
                    )}
                  </select>
                  <button className='blue font-18' onClick={getAllChecked}>
                    Ir
                  </button>
                </div>
              </div>
            )}
          </div>

          <div className='seleccionar-todo-container mt-3'>
            {orders.length !== 0 && (
              <div>
                <div className='float-right revert'>
                  <div className='container-check'>
                    Orden: Fecha pedido ascendente
                  </div>
                </div>
                <div className='float-left col-8 d-block'>
                  <label className='container-check'>
                    Seleccionar todos los pedidos
                    <input
                      type='checkbox'
                      id='handleAllChecked'
                      onClick={handleAllChecked}
                    />
                    <span className='checkmark'></span>
                  </label>
                </div>
              </div>
            )}
          </div>

          {orderList.map((order, index) => {
            return (
              <OrderTableRow
                role={role}
                order={order}
                key={order.order_id}
                index={index}
                useOperator={useOperator}
              />
            );
          })}
          <ReactPaginate
            initialPage={0}
            nextLabel='>'
            onPageChange={onNext}
            pageRangeDisplayed={5}
            marginPagesDisplayed={1}
            pageCount={totalPages}
            previousLabel='<'
            pageClassName='page-item'
            pageLinkClassName='page-link'
            previousClassName='page-item'
            previousLinkClassName='page-link'
            nextClassName='page-item'
            nextLinkClassName='page-link'
            breakLabel='...'
            breakClassName='page-item'
            breakLinkClassName='page-link'
            containerClassName='pagination'
            activeClassName='active'
            renderOnZeroPageCount={null}
          />

          <div>{loading && <Loader />}</div>
          <div>{error && 'Error'}</div>
        </div>
      </div>

      <CSVLink
        data={transactionData.sort(dynamicSort('sku'))}
        separator={';'}
        filename={'export.csv'}
        target='_blank'
        ref={csvLink}
      />
    </Context.Provider>
  );
}

export default Preparation;
