import React, { useState, useEffect } from 'react';
import moment from 'moment';

const PreparationTime = (props) => {
  const [timeTotal, setTimeTotal] = useState(0);
  const [cantPedidosTotal, setCantPedidosTotal] = useState(0);
  const [preparationTimeCount, setPreparetionTimeCount] = useState([]);
  const [maxTime, setmaxTime] = useState(0);
  const [tiempoPromedioTotal, setTiempoPromedioTotal] = useState(0);

  useEffect(() => {
    if (props.data !== undefined) {
      var reasons = new Array();
      var cantPedidos = 0;
      var tiempoTotal = 0;
      props.data.map((item, index) => {
        if (reasons[item.channel_name] == undefined) {
          reasons[item.channel_name] = { segundos: parseInt(item.segundos) };
          reasons[item.channel_name]['cant_pedidos'] = parseInt(
            item.cant_pedidos,
          );
          //calculo el tiempo promedio del channel
          //reasons[item.channel_name]["tiempo_promedio"] = parseInt(item.segundos) / parseInt(item.cant_pedidos);
        } else {
          reasons[item.channel_name]['segundos'] =
            parseInt(reasons[item.channel_name]['segundos']) +
            parseInt(item.segundos);
          reasons[item.channel_name]['cant_pedidos'] =
            parseInt(reasons[item.channel_name]['cant_pedidos']) +
            parseInt(item.cant_pedidos);
          //calculo el tiempo promedio del channel
          //reasons[item.channel_name]["tiempo_promedio"] = (parseInt(item.segundos) / parseInt(item.cant_pedidos));
        }
      });

      var r = new Array();
      for (const [key, value] of Object.entries(reasons)) {
        r.push({ name: key, total: value });
      }
      const mapSort1 = new Map(
        [...r.entries()].sort(
          (a, b) =>
            b[1].total.segundos / b[1].total.cant_pedidos -
            a[1].total.segundos / a[1].total.cant_pedidos,
        ),
      );

      let array = Array.from(
        mapSort1,
        ([name, value]) => ({ name, value }.value),
      );
      setPreparetionTimeCount(array);

      console.log(r);

      var values = r.map((val) =>
        parseInt(val.total.segundos / val.total.cant_pedidos),
      );
      var max = Math.max.apply(null, values);
      setmaxTime(max);

      var promedioChannel = 0;
      r.map((v) => {
        tiempoTotal =
          parseInt(tiempoTotal) +
          parseFloat(v.total.segundos / v.total.cant_pedidos);
        cantPedidos = parseInt(cantPedidos) + parseInt(v.total.cant_pedidos);
        promedioChannel = v.total.segundos / v.total.cant_pedidos;
      });
      setTimeTotal(tiempoTotal);
      setCantPedidosTotal(cantPedidos);
    }
  }, [props.data]);

  function secondsToString(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    return numhours + ' h ' + numminutes + ' m ';
  }

  useEffect(() => {
    if (preparationTimeCount.length !== 0) {
      const promedioTotal = timeTotal / preparationTimeCount.length;
      setTiempoPromedioTotal((promedioTotal * 100) / maxTime);
      console.log(
        'Tiempo total (suma de promedios de cada channel) -> ' + timeTotal,
      );
      console.log(
        'Tiempo promedio total -> ' + timeTotal / preparationTimeCount.length,
      );
      console.log('Tiempo maximo (promedio de un channel) -> ' + maxTime);
      console.log('Tiempo promedio total -> ' + promedioTotal / maxTime);
    }
  }, [preparationTimeCount]);

  return (
    <div className='card-container'>
      <div className='card-border'>
        <div className='card-title-text'>Tiempo de preparación</div>
        <div className='charts-daterange'>
          {moment(props.dateFrom).format('DD/MM/YYYY')} -{' '}
          {moment(props.dateTo).format('DD/MM/YYYY')}
        </div>
        <div className='chart-container'>
          <div className='progressbar-wrapper progressbar-total'>
            <div className='display-table'>
              <div className='table-cell'>
                <div className='progressbar-label'>Total</div>
              </div>
            </div>
            <div className='display-table'>
              <div className='table-cell'>
                <div className='progress-container round-xlarge'>
                  <div
                    className='progressbar round-xlarge'
                    style={{ width: tiempoPromedioTotal + '%' }}
                  ></div>
                </div>
              </div>
            </div>
            <div className='display-table'>
              <div className='table-cell'>
                <div className='progressbar-number'>
                  {secondsToString(timeTotal / preparationTimeCount.length)}
                </div>
              </div>
            </div>
          </div>
          {preparationTimeCount.map(function (value, key, reasonsCount) {
            //var total_channel = parseFloat(value.total.tiempo_promedio).toFixed(2)
            var promedioChannel = 0;
            promedioChannel = parseFloat(
              value.total.segundos / value.total.cant_pedidos,
            );
            var total_channel = promedioChannel.toFixed(2);
            var total_channel_procent = (
              (total_channel * 100) /
              maxTime
            ).toFixed(2);
            return (
              <div className='progressbar-wrapper' key={value.name}>
                <div className='display-table'>
                  <div className='table-cell'>
                    <div className='progressbar-label'>{value.name}</div>
                  </div>
                </div>
                <div className='display-table'>
                  <div className='table-cell'>
                    <div className='progress-container round-xlarge'>
                      <div
                        className='progressbar round-xlarge'
                        style={{ width: total_channel_procent + '%' }}
                      ></div>
                    </div>
                  </div>
                </div>
                <div className='display-table'>
                  <div className='table-cell'>
                    <div className='progressbar-number'>
                      {secondsToString(total_channel)}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PreparationTime;
