import React from 'react';

import {printTicket} from './index';
import getOmsOrderExchangeTicket from '../../../tools/useGetOmsOrderExchangeTicket';
import getBillinatorOrderInvoice from '../../../tools/useGetBillinatorOrderInvoice';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCommentDollar, faExchangeAlt, faPrint,} from '@fortawesome/free-solid-svg-icons';

const OrderPrintingOptions = ({order}) => {
    return (
        <div
            className='ml-1'
            style={{
                display: 'inline-flex',
                justifyContent: 'end',
                alignItems: 'flex-end',
                gap: 10,
                fontSize: 16,
            }}
        >
            <FontAwesomeIcon
                icon={faPrint}
                title='Imprimir etiqueta'
                style={{color: 'rgb(90, 90, 90)'}}
                onClick={() => printTicket(order)}
            />
            <FontAwesomeIcon
                icon={faExchangeAlt}
                title='Imprimir ticket de cambio'
                style={{color: 'rgb(90, 90, 90)'}}
                onClick={() => getOmsOrderExchangeTicket(order)}
            />
            <FontAwesomeIcon
                icon={faCommentDollar}
                title='Imprimir factura'
                style={{color: 'rgb(90, 90, 90)'}}
                onClick={() => getBillinatorOrderInvoice(order)}
            />
        </div>
    );
};

export default OrderPrintingOptions;