import {useEffect, useState} from 'react';
import {getItem} from "./utils/storageUtil";
import {_get} from "./utils/apiRequester";

export default function useGetChannels(store, clearChanel) {

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [channels, setChannels] = useState([])


    useEffect(() => {
        setChannels([])
    }, [clearChanel])

    const getChannels = async () => {
        const url = getItem('url')
        return await _get(`${url}/channels/getchannels`);
    }

    useEffect(() => {
        setLoading(true)
        setError(false)
        let cancel
        getChannels()
            .then(response => {
                const {data} = response;
                data.map((item, index) => {
                    return setChannels(prevChannels => [...prevChannels, item])
                })
            })
            .catch(() => setError(true))
            .finally(() => setLoading(false));
    }, [clearChanel])

    return {
        loading,
        error,
        channels
    }
}
