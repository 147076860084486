import {useEffect, useState} from 'react';
import {getItem} from "./utils/storageUtil";
import {_get} from "./utils/apiRequester";

export default function useGetUnasigmentReasons(clearUnasignmentReason) {

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [unasignmentReasons, setUnasignmentReasons] = useState([])

    useEffect(() => {
        setUnasignmentReasons([])
    }, [clearUnasignmentReason])

    const getUnassigmentReasons = async () => {
        const url = getItem('url')
        return await _get(`${url}/unasignment_reasons_list`);
    }

    useEffect(() => {
        setLoading(true)
        setError(false)
        getUnassigmentReasons().then(response => {
            const {data} = response;
            data.map((item) => {
                return setUnasignmentReasons(prevUnasignmentReasons => [...prevUnasignmentReasons, item])
            });
        }).finally(() => setLoading(false))
    }, [clearUnasignmentReason])

    return {
        loading,
        error,
        unasignmentReasons
    }
}
