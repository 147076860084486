import React from "react";

import Login from "./Common/Login";
import Auth from "./Common/Auth";
import Inbound from "./Common/Inbound";
import Pickup from "./Common/Pickup";
import SwitchStore from "./Common/SwitchStore";
import Stores from "./Common/Stores";
import Preparation from "./Common/Preparation";
import Users from "./Common/Users";
import Metrics from "./Common/Metrics";
import Configuration from "./Common/Configuration";
import UnasignmentReasons from "./Common/UnasignmentReasons";
import Locales from "./Common/Locales";
import Reportes from "./Common/Reportes";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";

class App extends React.Component {
  constructor(props) {
    super(props);
   }
   render() {
   return (
      <Router>
        <Switch>
          <Route exact path="/Auth" component={Auth} />
          <Route exact path="/Login" component={Login} />
          <Route exact path="/" component={Login} />
          <Route exact path="/inbound" component={Inbound} />
          <Route exact path="/Home" component={Preparation} />
          <Route exact path="/Pickup" component={Pickup} />
          <Route exact path="/switchStore" component={SwitchStore} />
          <Route exact path="/Stores" component={Stores} />
          <Route exact path="/Users" component={Users} />   
          <Route exact path="/Metrics" component={Metrics} />   
          <Route exact path="/Configuration" component={Configuration} />   
          <Route exact path="/UnasignmentReasons" component={UnasignmentReasons} />
          <Route exact path="/Reportes" component={Reportes} />   
          <Route exact path="/Locales" component={Locales} />
        </Switch>
      </Router>
    );
  }
}

export default App;
