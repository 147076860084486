import React, { useState, useContext, useEffect } from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';

import FormControl from '@material-ui/core/FormControl';
import Context from '../../../context';
import Loader from '../../Loader';
function useInputDescription(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}

const UnassignmentReasonsModal = (props) => {
    const { setIsOpenUnassignmentReasons } = useContext(Context)
    const { setUnassignmentReasonId } = useContext(Context)
    const description = useInputDescription('')
    const [value, setValue] = React.useState('');
    const [isOpenDescription, setIsOpenDescription] = useState(false);
    const { unAssignOrder } = useContext(Context)

    const handleChange = (event) => {
        setValue(event.target.value);
        setIsOpenDescription(false)
        props.unassignmentReasons.map((unassignmentReason, index) => {
            if (unassignmentReason.id == event.target.value) {
                if (unassignmentReason.reason == "Otro:") {
                    setIsOpenDescription(true)
                }
            }
        })
    };
    function closeModal() {
        document.getElementsByClassName("modal-backdrop")[0].remove();

        setIsOpenUnassignmentReasons(false);
    }
    useEffect(() => {
        const div = document.createElement("div");
        div.classList.add('modal-backdrop');
        document.body.appendChild(div);
    }, [])
    function submitHandler(event) {
        event.preventDefault()
        if (value === "") {
            alert("Elegi un motivo de desasignación")
            return false;
        }
        props.unassignmentReasons.map((unassignmentReason, index) => {
            if (unassignmentReason.id == event.target.value) {
                if (unassignmentReason.reason == "Otro:") {
                    if(description.value()==="" || description.value().trim().length === 0){
                        alert("Campo descriptio es obligatorio")
                        return false;
                    }
                }
            }
        })

        setUnassignmentReasonId(value)
        unAssignOrder(true, value, description.value())
        closeModal()
    }

    return (
        <div className="modal show" tabIndex="-1" role="dialog" style={{ display: 'block' }} >
            <div className="modal-dialog modal-md" role="document">
                <div className="modal-content">
                    <form onSubmit={submitHandler}>
                        <div className="modal-header">
                            <h5 className="modal-title">
                                Pedido # {props.order.increment_id} motivos de desasignación</h5>
                            <button className="btn btn-light btn-sm" type="button" data-dismiss="modal" aria-label="Close" onClick={() => closeModal()} >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>

                        <div className="modal-body table-responsive modal-unassignmentReason">
                            <FormControl component="fieldset">
                                <RadioGroup aria-label="unassignmentReason" name="unassignmentReason" value={value} onChange={handleChange}>
                                    {props.unassignmentReasons.map((unassignmentReason, index) => {
                                        return (
                                            <div key={index} className="form-check float-left form-check-inline">
                                                <input className="form-check-input" type="radio" name="unassignmentReason" value={unassignmentReason.id} onChange={handleChange} />
                                                <label className="form-check-label" htmlFor="contactChoice3">{unassignmentReason.reason}</label>
                                            </div>
                                        )
                                    })}
                                </RadioGroup>

                            </FormControl>
                            {isOpenDescription === true &&
                                <div className="form-group  ">
                                    <label className="float-left control-label" >Descripción:</label>
                                    <textarea className="form-control" type="text" required {...description.bind} rows="4" ></textarea>
                                </div>
                            }
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-secondary" type="button" data-dismiss="modal" onClick={() => closeModal()}>Cancelar</button>
                            <input type="submit" name="Enviar" className="btn btn-primary" />
                        </div>
                    </form>
                </div>
            </div>
            <div>{props.loadingModal && (<Loader />)}</div>
            <div>{props.errorModal && 'Error'}</div>
        </div>
    );
}

export default UnassignmentReasonsModal;
