import React, { useState, useContext,useEffect } from 'react';
import Context from '../../../context';
import Loader from '../../Loader';
import axios from 'axios';

function useInputComment(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}

const CommentModal = (props) => {
    const { setIsOpenCommentModal } = useContext(Context)
    const [loading, setLoading] = useState(false)
   
    const comment = useInputComment('')
    function closeModal() {
        document.getElementsByClassName("modal-backdrop")[0].remove();
        setIsOpenCommentModal(false);
    }
    useEffect(() => {
        const div = document.createElement("div");
        div.classList.add('modal-backdrop');
        document.body.appendChild(div);
    }, [])

    function submitHandler(event) {
        setLoading(true)
        event.preventDefault()
        var order_tosend = {
            order_id: parseInt(props.order.order_id),
            comment: comment.value()
          }

        axios({
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + localStorage.getItem('token'),
              'Glamit-Env': localStorage.getItem('store_id')
            },
            url: localStorage.getItem('url') + '/order/comment',
            data: JSON.stringify(order_tosend)
          }).then(function (response) {
              setLoading(false)
              closeModal()
            if (response.status === 200) {
                setTimeout(function () {
                    alert("El comentario fue guardado con éxito")
                  }, 500)
               
            }
          }).catch(e => {
              setLoading(false)
              alert(e)
          });
        

    }

    return (

        <div className="modal show" tabIndex="-1" role="dialog" style={{ display: 'block' }} >
            <div className="modal-dialog modal-sm" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5>Pedido #{props.order.increment_id}</h5>
                        <button className="btn btn-light btn-sm" type="button" data-dismiss="modal" aria-label="Close" onClick={() => closeModal()} >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <form onSubmit={submitHandler}>
                        <div className="modal-body table-responsive">

                            <div className="form-group  ">
                                <label className="float-left control-label commentColor" >Comentario:</label>
                                <textarea className="form-control" type="text" required {...comment.bind} rows="4" ></textarea>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-secondary" type="button" data-dismiss="modal" onClick={() => closeModal()}>Cancelar</button>
                            <input type="submit" name="Enviar" className="btn btn-primary" />
                        </div>
                    </form>
                </div>
            </div>
            <div>{loading && (<Loader />)}</div>
            <div>{props.errorModal && 'Error'}</div>

        </div>
    );
}

export default CommentModal;