import {useEffect} from 'react';
import axios from 'axios';

export default function useSaveQuote(sendQuoteData, data) {
    
    
    useEffect(() => {
        if (sendQuoteData) {
            const url = localStorage.getItem('url') + '/savequote';
            console.log(url);
            let cancel
            axios({
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Glamit-Env': localStorage.getItem('store_id'),
                },
                data:JSON.stringify(data),
                url: url,
                cancelToken: new axios.CancelToken(c => cancel = c)
            }).then(function (response) {
            }).catch(e => {

            })
            return () => cancel()
        }
    }, [sendQuoteData])


}
