import React, { useContext } from 'react';
import Context from '../../../context'

const TableRowPickup = React.forwardRef((props, ref) => {

    const { clearToStart } = useContext(Context)
    const { showModal } = useContext(Context)
    return (
        <tr ref={ref}>
            <td>{props.order.created_at}</td>
            <th scope="row">{props.order.order_id}</th>
            <td>{props.order.increment_id}</td>
            <td>{props.order.grand_total}</td>
            <td>{props.order.postcode}</td>
            <td>
                {props.order.skus.map((item, index) => {
                    return <p key={index}>{item.sku}</p>
                })}
            </td>
            <td>
                {props.order.skus.map((item, index) => {
                    return <p key={index}> {item.qty} </p>
                })}
            </td>
            <td><img src='/sucursal.png' /></td>
        </tr>
    )
})

export default TableRowPickup;
