import React, { Component } from 'react'
import equal from 'fast-deep-equal'
class CheckBox extends Component {
    constructor(props) {
        super(props)
        this.state = {
            checked: false,
            name: this.props.order_id
        }
    }

    checkedClick() {
        this.setState({ checked: !this.state.checked })
    }

    checkedAllClick(isChecked) {
        this.setState({ checked: isChecked })
    }

    componentDidUpdate(props) {
        if (!equal(this.props.isChecked, props.isChecked)) {
            this.checkedAllClick(this.props.isChecked)
        }
    }

    render() {
        return (
            <input onChange={() => {
                this.checkedClick()
            }}
                value={this.state.name}
                checked={this.state.checked}
                type="checkbox" name={this.state.name}
                className="checkboxorder" />
        )
    }
}

export default CheckBox