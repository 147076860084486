import React, { useState, useContext, useEffect } from 'react';
import Context from '../../../context';
import Loader from '../../Loader';
import axios from 'axios';
function useInputDataSource(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}
function useInputFormat(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}

const StockGroup = (props) => {
    const [loading, setLoading] = useState(false)
    const dataSource = useInputDataSource('')
    const format = useInputFormat('')


    const { configData } = useContext(Context)
    const { setClearConfig } = useContext(Context)
    const { clearConfig } = useContext(Context)

    useEffect(() => {
         configData.map((config, index) => {
            if (config.path === "data_source") {
                dataSource.addValue(config.value)
            }
            if (config.path === "format") {
                format.addValue(config.value)
            }
        })
    }, [configData])
    function submitHandler(event) {
        event.preventDefault()

        var config_tosend = [
            { path: "data_source", value: dataSource.value(), store_id: localStorage.getItem('store_id') },
            { path: "format", value: format.value(), store_id: localStorage.getItem('store_id') },
        ]
        setLoading(true)
        axios({
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Glamit-Env': localStorage.getItem('store_id')
            },
            url: localStorage.getItem('url') + '/config_data',
            data: config_tosend
        }).then(function (response) {
            if (response.status === 200) {
                setClearConfig(clearConfig => clearConfig + 1)
            }
            setLoading(false)
        }).catch(e => {
            if (e.response.status === 401 || e.response.status === 403) {
                window.location.href = window.location.origin + "/login"
            }
        });
    }
    return (

        <div className="main-container" id="main">
            <div className="title">Orígen</div>
            <div className="actions-container">
            </div>
            <form onSubmit={submitHandler}>

                <div className="grid-container">
                    <div className="display-table"><div className="label-container"><label className="" >Fuente de datos:</label></div> </div>
                    <div className="value-container">
                        <div className="display-table">
                            <select className="form-control" {...dataSource.bind} >
                                <option value=""></option>
                                <option value="ftp">FTP</option>
                                <option value="api" selected="selected">API</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="display-table"><div className="label-container"><label className="" >Formato de datos:</label></div> </div>
                    <div className="value-container">
                        <div className="display-table">
                            <select className="form-control" {...format.bind} >
                                <option value=""></option>
                                <option value="json" selected="selected">JSON</option>
                                <option value="csv">CSV</option>
                                <option value="xml">XML</option>
                                <option value="excel">Excel</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="display-table">
                        <div className="label-container">
                            <label className="">        </label>
                        </div>
                    </div>
                    <div className="display-table">
                        <input type="submit" name="Edit" className="btn btn-primary" />
                    </div>
                </div>
            </form>
            <div>{loading && (<Loader />)}</div>
        </div>
    );
}

export default StockGroup;