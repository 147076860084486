import React, {useEffect} from 'react';
import jwt_decode from "jwt-decode";

const Auth = () => {
  useEffect(() => {
    localStorage.setItem('token', null);
    localStorage.setItem('role', null);
    localStorage.setItem('PickupChannel', null);
    localStorage.setItem('store_id', null);
    localStorage.setItem('plataforma', null);
    localStorage.setItem('is_multiwarehouse', null);
    localStorage.setItem('front_url', null);
    localStorage.setItem('seller_id', null);
    localStorage.setItem('store_name', null);

    var url = new URL(window.location.href);
    var idToken = url.searchParams.get('idToken');
    var decoded = jwt_decode(idToken);
    var roles = new Array();
    var stores = new Array();
    var i;
    localStorage.setItem('user_email', decoded["email"]);
    for (i = 0; i < decoded['cognito:groups'].length; i++) {
      if (decoded['cognito:groups'][i] === "ROLE_GLAMIT_ADMIN") {
        roles.push("ROLE_GLAMIT_ADMIN");
        roles.push("ADMIN");
        localStorage.setItem('store_id', 104);
      }
      if (decoded['cognito:groups'][i].indexOf("_OMNI_") > 0) {
        var role = decoded['cognito:groups'][i].split("_");
        stores.push(role[1])
        localStorage.setItem('store_id', role[1]);
        roles.push(role[3]);
      }

    }
    if (roles.length === 0) {
      alert("Este email no está autorizado para ingresar. Por favor intente con otro.");
      let logout;
      if (window.location.href.indexOf(".uat") > 0 || window.location.href.indexOf("localhost") > 0) {
        logout = "https://api.auth.uat.glamit.com.ar/auth/logout?state=" + btoa(window.location.origin + "/login");
      } else {
        logout = "https://api.auth.glamit.com.ar/auth/logout?state=" + btoa(window.location.origin + "/login");
      }

      return window.location.href = logout;
    }
    localStorage.setItem('token', idToken);
    localStorage.setItem('role', roles);

    if (window.location.href.indexOf(".uat") > 0) {
      localStorage.setItem('url', 'https://omnichannelhub.uat.glamit.com.ar');
    } else {
      localStorage.setItem('url', window.location.protocol + '//omnichannelhub.glamit.com.ar');
    }

    if (window.location.href.indexOf("localhost") > 0) {
      localStorage.setItem('url', 'http://localhost:8081');
    }

    const uniqueStores = stores.filter(function (item, pos, self) {
      return self.indexOf(item) == pos;
    })

    if (roles.indexOf("ROLE_GLAMIT_ADMIN") !== -1) {
      window.location.href = window.location.origin + "/switchStore"
    } else {
      if (roles.indexOf("PICKUP") !== -1) {
        if (roles.length === 1 && roles.indexOf("PICKUP") === 0) {
          window.location.href = window.location.origin + "/home"
        } else {
          if (uniqueStores.length > 1) {
            window.location.href = window.location.origin + "/home"
          } else {
            if (roles.indexOf("PICKUP") !== -1 && roles.indexOf("TELEVENTA") !== -1) {
              window.location.href = window.location.origin + "/home"
            } else {
              window.location.href = window.location.origin + "/home"
            }
          }
        }
      } else if (roles.indexOf("TELEVENTA") !== -1) {
        if (roles.length === 1 && roles.indexOf("TELEVENTA") === 0) {
          window.location.href = window.location.origin + "/televenta"
        } else {
          if (uniqueStores.length > 1) {
            window.location.href = window.location.origin + "/switchStore"
          } else {
            window.location.href = window.location.origin + "/home"
          }
        }
      } else {
        if (uniqueStores.length > 1) {
          window.location.href = window.location.origin + "/switchStore"
        } else {
          window.location.href = window.location.origin + "/home"
        }
      }
    }
  }, [])
  return (
    <div></div>
  );

}

export default Auth;