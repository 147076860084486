import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jwt_decode from "jwt-decode";

function useStoreList() {
    const [stores, setStores] = useState([])

    var idToken = localStorage.getItem('token');
    var decoded = jwt_decode(idToken);
    useEffect(() => {
        axios({
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Glamit-Env': localStorage.getItem('store_id')
            },
            url: localStorage.getItem('url') + '/store'
        }).then(response => {
            if (response.data.length > 0) {
                response.data.map((item, index) => {

                    if (decoded['cognito:groups'].indexOf("ROLE_" + item.store_id + "_OMNI_PICKUP") !== -1) {
                         return setStores(prevStores => [...prevStores, item])

                    }
                })
            }

        }).catch(e => {
            window.location.href = window.location.origin + "/home"
        })
    }, [])
    return { stores }
}

function MenuStoreList() {
    const { stores } = useStoreList()

    function setStoreToApp(store) {
        localStorage.setItem('PickupChannel', null);
        localStorage.setItem('store_id', store.store_id);
        localStorage.setItem('store_name', store.store_name);
        window.location.href = window.location.origin + "/home"
    }


    return (
        <ul className="MuiList-root MuiMenu-list MuiList-padding">
            {stores.map((store, index) => {
                return (
                    <li key={index} className="nav-item"><a className="nav-link" onClick={() => setStoreToApp(store)} role="button">{store.storecode.charAt(0).toUpperCase() + store.storecode.slice(1)}</a></li>
                )

            })}
        </ul>
    )
}

export default MenuStoreList;
