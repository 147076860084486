import React, { useState } from 'react';
import Context from '../../context'
import UnasigmentReasonsTableRow from '../Rows/UnasigmentReasonsTableRow';
import Loader from '../Loader';
import Nav from '../Nav';
import useGetUnasigmentReasons from '../../tools/useGetUnasigmentReasons';
import axios from 'axios';
import Role from '../../tools/role';

function useInputUnasignmentReason(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}

function useInputUnasignmentReasonId(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}

function UnasignmentReasons() {

    if (localStorage.getItem('token') === null || localStorage.getItem('token') === 'null') {
        window.location.href = window.location.origin
    }
    Role(["ADMIN"])

    const [clearUnasignmentReason, setClearUnasignmentReason] = useState(1)
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [textModal, setTextModal] = useState('')
    const [isOpenModalForm, setIsOpenModalForm] = useState(false)
    const [textModalForm, setTextModalForm] = useState('')
    const [action, setAction] = useState('new')


    const unasignmentReason = useInputUnasignmentReason('')
    const unasignmentReasonId = useInputUnasignmentReasonId('')

    function clearToStart() {
        setClearUnasignmentReason(clearUnasignmentReason => clearUnasignmentReason + 1)
    }
    const {
        unasignmentReasons,
        loading,
        error
    } = useGetUnasigmentReasons(clearUnasignmentReason)


    function submitHandler(event) {
        event.preventDefault()
        if(action==="add"){
            var url=localStorage.getItem('url') + '/unasignment_reasons_add';
            var unasignment_reason_tosend = {
                reason: unasignmentReason.value()
            }
        }
        if(action==="edit"){
            var url=localStorage.getItem('url') + '/unasignment_reasons_edit';
            var unasignment_reason_tosend = {
                reason: unasignmentReason.value(),
                id: unasignmentReasonId.value()
            }
        }

        axios({
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Glamit-Env': localStorage.getItem('store_id')
            },
            url: url,
            data: unasignment_reason_tosend
        }).then(function (response) {
            if (response.status === 200) {
                closePopup()
                clearToStart();
            }
        }).catch(e => {
            if (e.response.status === 401 || e.response.status === 403) {
                window.location.href = window.location.origin + "/login"
            } else {
                if (axios.isCancel(e)) return
                setTextModalForm(e.name + ': ' + e.response.data.message)
                setIsOpenModalForm(true);
            }
        });

    }

    function closePopup() {
        setIsOpenModal(false);
    }
    function closeModalForm() {
        setIsOpenModalForm(false);
    }
    function showModal(status, ur) {
        setIsOpenModalForm(false);
        if (status === 'new') {
            setAction('add')
            setTextModal('New Razon de desasignación')
            unasignmentReason.clear()
            unasignmentReasonId.clear()
        }
        if (status === 'edit') {
            setAction('edit')
            setTextModal('Edit Razon de desasignación')
            unasignmentReason.addValue(ur.reason)
            unasignmentReasonId.addValue(ur.id)
        }
        setIsOpenModal(true);
    }

    return (

        <Context.Provider value={{ clearToStart, showModal }}>

            {isOpenModal === true &&
                <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-lg" role="document">
                        <form onSubmit={submitHandler}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">{textModal}</h5>
                                    <button className="close" type="button" data-dismiss="modal" aria-label="Close" onClick={() => closePopup()} >
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    {isOpenModalForm === true &&
                                        <div className="alert alert-warning alert-dismissible fade show" role="alert">
                                            {textModalForm}
                                            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => closeModalForm()}>
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    }
                                    <div className="form-row">
                                            <label className="small control-label" >Razon:</label>
                                            <input className="form-control" type="text" required {...unasignmentReason.bind} />
                                            <input className="form-control" type="hidden" required {...unasignmentReasonId.bind} />
                                     
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn btn-secondary" type="button" data-dismiss="modal" onClick={() => closePopup()}>Cancelar</button>
                                    <input type="submit" name="Edit" className="btn btn-primary" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            }
             <div className="page-header">
        <Nav />
      </div>
            <div className="container">
                
                <div className="card mt-3">
                    <div className="card-header">
                        <div className="row float-left col-12">
                            <h1 style={{ width: '100%' }}>Razones de desasignación</h1>
                            <button type='button' className="btn btn-primary float-left" onClick={() => showModal('new')} >Crear</button>
                        </div>
                    </div>
                    <div className="card-body">
                        <table className="table mt-2 table-sm table-responsive-lg">
                            <thead className="thead-dark">
                                <tr>
                                    <th scope="col">Id</th>
                                    <th scope="col">Razon de desasignación</th>
                                    <th scope="col">Edit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {unasignmentReasons.map((ur, index) => {
                                    return <UnasigmentReasonsTableRow ur={ur} key={index} index={index} />
                                })}
                            </tbody>
                        </table>
                        <div>{loading && (<Loader />)}</div>
                        <div>{error && 'Error'}</div>
                    </div>  </div>
            </div>
        </Context.Provider>
    );
}

export default UnasignmentReasons;
