import React, { useState, useContext, useEffect } from 'react';
import Context from '../../../context';
import Loader from '../../Loader';
import axios from 'axios';
function useInputApiUrl(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    const [disabled, setDisabled] = useState(false)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value),
            disabled: disabled
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
        disabled: (arg) => setDisabled(arg)
    }
}
function useInputBasicAuthUsername(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    const [disabled, setDisabled] = useState(false)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value),
            disabled: disabled
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
        disabled: (arg) => setDisabled(arg)
    }
}
function useInputBasicAuthPassword(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    const [disabled, setDisabled] = useState(false)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value),
            disabled: disabled
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
        disabled: (arg) => setDisabled(arg)
    }
}
function useInputAuthToken(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    const [disabled, setDisabled] = useState(false)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value),
            disabled: disabled
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
        disabled: (arg) => setDisabled(arg)
    }
}
function useInputHeaders(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    const [disabled, setDisabled] = useState(false)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value),
            disabled: disabled
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
        disabled: (arg) => setDisabled(arg)
    }
}
function useInputBasicAuth(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}
function useInputAuthUrl(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}
function useInputAuthParams(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}
function useInputAuthHeaders(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}
function useInputMultipleChannel(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}
function useInputChannelParam(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}
function useInputChannelsSplit(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}


const ApiGroup = (props) => {
    const [loading, setLoading] = useState(false)
    const apiUrl = useInputApiUrl('')
    const basicAuthUsername = useInputBasicAuthUsername('')
    const basicAuthPassword = useInputBasicAuthPassword('')
    const authToken = useInputAuthToken('')
    const headers = useInputHeaders('')
    const basicAuth = useInputBasicAuth('')
    const authUrl = useInputAuthUrl('')
    const authParams = useInputAuthParams('')
    const authHeaders = useInputAuthHeaders('')
    const multipleChannel = useInputMultipleChannel('')
    const channelParam = useInputChannelParam('')
    const channelsSplit = useInputChannelsSplit('')
    
    const { configData } = useContext(Context)
    const { setClearConfig } = useContext(Context)
    const { clearConfig } = useContext(Context)


    useEffect(() => {
        configData.map((config, index) => {
            if (config.path === "api_url") {
                apiUrl.addValue(config.value)
            }
            if (config.path === "basic_auth_username") {
                basicAuthUsername.addValue(config.value)
            }
            if (config.path === "basic_auth_password") {
                basicAuthPassword.addValue(config.value)
            }
            if (config.path === "auth_token") {
                authToken.addValue(config.value)
            }
            if (config.path === "headers") {
                headers.addValue(config.value)
            }
            if (config.path === "basic_auth") {
                basicAuth.addValue(config.value)
            }
            if (config.path === "auth_url") {
                authUrl.addValue(config.value)
            }
            if (config.path === "auth_params") {
                authParams.addValue(config.value)
            }
            if (config.path === "auth_headers") {
                authHeaders.addValue(config.value)
            }
            if (config.path === "multiple_channel") {
                multipleChannel.addValue(config.value)
            }
            if (config.path === "channel_param") {
                channelParam.addValue(config.value)
            }
            if (config.path === "channels_split") {
                channelsSplit.addValue(config.value)
            }
        })
    }, [configData])
    function submitHandler(event) {
        event.preventDefault()

        var config_tosend = [
            { path: "api_url", value: apiUrl.value(), store_id: localStorage.getItem('store_id') },
            { path: "basic_auth_username", value: basicAuthUsername.value(), store_id: localStorage.getItem('store_id') },
            { path: "basic_auth_password", value: basicAuthPassword.value(), store_id: localStorage.getItem('store_id') },
            { path: "auth_token", value: authToken.value(), store_id: localStorage.getItem('store_id') },
            { path: "headers", value: headers.value(), store_id: localStorage.getItem('store_id') },
            { path: "basic_auth", value: basicAuth.value(), store_id: localStorage.getItem('store_id') },
            { path: "auth_url", value: authUrl.value(), store_id: localStorage.getItem('store_id') },
            { path: "auth_params", value: authParams.value(), store_id: localStorage.getItem('store_id') },
            { path: "auth_headers", value: authHeaders.value(), store_id: localStorage.getItem('store_id') },
            { path: "multiple_channel", value: multipleChannel.value(), store_id: localStorage.getItem('store_id') },
            { path: "channel_param", value: channelParam.value(), store_id: localStorage.getItem('store_id') },
            { path: "channels_split", value: channelsSplit.value(), store_id: localStorage.getItem('store_id') },
        ]
        setLoading(true)
        axios({
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Glamit-Env': localStorage.getItem('store_id')
            },
            url: localStorage.getItem('url') + '/config_data',
            data: config_tosend
        }).then(function (response) {
            if (response.status === 200) {
                setClearConfig(clearConfig => clearConfig + 1)
            }
            setLoading(false)
        }).catch(e => {
            if (e.response.status === 401 || e.response.status === 403) {
                window.location.href = window.location.origin + "/login"
            }
        });


    }

    return (

        <div className="main-container" id="main">
            <div className="title">Api</div>
            <div className="actions-container">
            </div>
            <form onSubmit={submitHandler}>
                <div className="grid-container">
                    <div className="display-table"><div className="label-container"><label className="" >Url:</label></div> </div>
                    <div className="value-container">
                        <div className="display-table">
                            <input className="form-control form-control-sm" type="text"   {...apiUrl.bind} />
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="display-table">
                        <div className="label-container">
                            <label className=""> Autenticación básica Username:          </label>
                        </div>
                    </div>
                    <div className="value-container">
                        <div className="display-table">
                            <input className="form-control form-control-sm" type="text"   {...basicAuthUsername.bind} />
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="display-table">
                        <div className="label-container">
                            <label className=""> Autenticación básica Password:          </label>
                        </div>
                    </div>
                    <div className="value-container">
                        <div className="display-table">
                            <input className="form-control form-control-sm" type="password"   {...basicAuthPassword.bind} />
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="display-table">
                        <div className="label-container">
                            <label className=""> Token de autenticación:          </label>
                        </div>
                    </div>
                    <div className="value-container">
                        <div className="display-table">
                            <input className="form-control form-control-sm" type="text"   {...authToken.bind} />
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="display-table">
                        <div className="label-container">
                            <label className=""> Encabezados:          </label>
                        </div>
                    </div>
                    <div className="value-container">
                        <div className="display-table">
                            <textarea className="form-control" type="text"  {...headers.bind} rows="4" ></textarea>
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="display-table"><div className="label-container"><label className="" >Utiliza endpoint de autenticación?:</label></div> </div>
                    <div className="value-container">
                        <div className="display-table">
                            <select className="form-control" {...basicAuth.bind} >
                                <option value="1">Si</option>
                                <option value="0">No</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="display-table">
                        <div className="label-container">
                            <label className=""> Endpoint de autenticación:          </label>
                        </div>
                    </div>
                    <div className="value-container">
                        <div className="display-table">
                            <input className="form-control form-control-sm" type="text"   {...authUrl.bind} />
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="display-table">
                        <div className="label-container">
                            <label className=""> Parámetros:          </label>
                        </div>
                    </div>
                    <div className="value-container">
                        <div className="display-table">
                            <input className="form-control form-control-sm" type="text"   {...authParams.bind} />
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="display-table">
                        <div className="label-container">
                            <label className=""> Encabezados autenticación:          </label>
                        </div>
                    </div>
                    <div className="value-container">
                        <div className="display-table">
                            <textarea className="form-control" placeholder='Llenar si aplica. Un JSON, ejemplo: {"Content-Type": "application/json"}' type="text"  {...authHeaders.bind} rows="4" ></textarea>
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="display-table"><div className="label-container"><label className="" >Usa un request por cada channel?:</label></div> </div>
                    <div className="value-container">
                        <div className="display-table">
                            <select className="form-control" {...multipleChannel.bind} >
                                <option value="1">Si</option>
                                <option value="0">No</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="display-table">
                        <div className="label-container">
                            <label className=""> Parámetro en URL:          </label>
                        </div>
                    </div>
                    <div className="value-container">
                        <div className="display-table">
                            <input className="form-control form-control-sm" type="text"   {...channelParam.bind} />
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="display-table">
                        <div className="label-container">
                            <label className=""> Listado de channels:          </label>
                        </div>
                    </div>
                    <div className="value-container">
                        <div className="display-table">
                            <input className="form-control form-control-sm" type="text" placeholder="Ingresar los ids de tienda separados por coma sin espacio"  {...channelsSplit.bind} />
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="display-table">
                        <div className="label-container">
                            <label className="">        </label>
                        </div>
                    </div>
                    <div className="display-table">
                        <input type="submit" name="Edit" className="btn btn-primary" />
                    </div>
                </div>
            </form>
            <div>{loading && (<Loader />)}</div>
        </div>
    );
}

export default ApiGroup;