import React, { useState } from 'react';
import Context from '../../context'
import UsersTableRow from '../Rows/UsersTableRow';
import Loader from '../Loader';
import Nav from '../Nav';
import useGetUsers from '../../tools/useGetUsers';
import axios from 'axios';
import Role from '../../tools/role';


function useInputChannelId(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    const [disabled, setDisabled] = useState(false)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value),
            disabled: disabled
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
        disabled: (arg) => setDisabled(arg)
    }
}
function useInputEmail(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}
function useInputRole(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}
function useInputUserId(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}

function Users() {

    if (localStorage.getItem('token') === null || localStorage.getItem('token') === 'null') {
        window.location.href = window.location.origin
    }
    Role(["ADMIN"])

    const [clearUsers, setClearUsers] = useState(1)
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [textModal, setTextModal] = useState('')
    const [isOpenModalForm, setIsOpenModalForm] = useState(false)
    const [textModalForm, setTextModalForm] = useState('')

    const channelId = useInputChannelId('')
    const email = useInputEmail('')
    const role = useInputRole('')
    const userId = useInputUserId('')


    function clearToStart() {
        setClearUsers(clearUsers => clearUsers + 1)
    }
    const {
        users,
        loading,
        error
    } = useGetUsers(clearUsers)


    function submitHandler(event) {
        event.preventDefault()
        var action = true;

        var user_tosend = {
            id:userId.value(),
            channel_id: channelId.value(),
            email: email.value(),
            role: role.value(),
            store_id: localStorage.getItem('store_id')
        }
        if (action === true) {
            axios({
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Glamit-Env': localStorage.getItem('store_id')
                },
                url: localStorage.getItem('url') + '/users',
                data: user_tosend
            }).then(function (response) {
                if (response.status === 200) {
                    closePopup()
                    clearToStart();
                }
            }).catch(e => {
                if (e.response.status === 401 || e.response.status === 403) {
                    window.location.href = window.location.origin + "/login"
                } else {
                    if (axios.isCancel(e)) return
                    setTextModalForm(e.name + ': ' + e.response.data.message)
                    setIsOpenModalForm(true);
                }
            });
        }
    }

    function closePopup() {
        setIsOpenModal(false);
    }
    function closeModalForm() {
        setIsOpenModalForm(false);
    }
    function showModal(status, user) {


        setIsOpenModalForm(false);
        if (status === 'new') {
            setTextModal('Nuevo Usuario')
            channelId.clear()
            userId.clear()
            email.clear()
            role.clear()
        }
        if (status === 'edit') {

            setTextModal('Editar Usuario')
            userId.addValue(user.id)
            channelId.addValue(user.channel_id)
            email.addValue(user.email)
            role.addValue(user.role)
        }
        setIsOpenModal(true);
    }

    return (

        <Context.Provider value={{ clearToStart, showModal }}>

            {isOpenModal === true &&
                <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-lg" role="document">
                        <form onSubmit={submitHandler}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">{textModal}</h5>
                                    <button className="close" type="button" data-dismiss="modal" aria-label="Close" onClick={() => closePopup()} >
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body modal-channel">
                                    {isOpenModalForm === true &&
                                        <div className="alert alert-warning alert-dismissible fade show" role="alert">
                                            {textModalForm}
                                            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => closeModalForm()}>
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    }
                                    <div className="form-row">
                                        <div className="form-group col-md-4 ">
                                            <label className="small control-label" >Channel Id:</label>
                                            <input className="form-control" type="number" required  {...channelId.bind} />
                                            <input type="hidden" {...userId.bind}/>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label className="small control-label" >Email:</label>
                                            <input className="form-control" type="text" required {...email.bind} />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label className="small control-label" >Role:</label>
                                            <input className="form-control" type="text" required {...role.bind} />
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn btn-secondary" type="button" data-dismiss="modal" onClick={() => closePopup()}>Cancelar</button>
                                    <input type="submit" name="Edit" className="btn btn-primary" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            }
             <div className="page-header">
        <Nav />
      </div>
            <div className="container">
                <div className="card mt-3">
                    <div className="card-header">
                        <div className="row float-left col-12">
                            <h1 style={{ width: '100%' }}>Usuarios</h1>
                            <button type='button' className="btn btn-primary float-left" onClick={() => showModal('new')} >Crear</button>
                        </div>
                    </div>
                    <div className="card-body">
                        <table className="table mt-2 table-sm table-responsive-lg">
                            <thead className="thead-dark">
                                <tr>
                                    <th scope="col">Channel id</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Role</th>
                                    <th scope="col">Edit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map((user, index) => {
                                    return <UsersTableRow user={user} key={index} index={index} />
                                })}
                            </tbody>
                        </table>
                        <div>{loading && (<Loader />)}</div>
                        <div>{error && 'Error'}</div>
                    </div>  </div>
            </div>
        </Context.Provider>
    );
}

export default Users;
