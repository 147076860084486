import React, { useEffect, useState } from 'react';
import moment from 'moment'

const OrdersPrepared = (props) => {

    const [ordersPrepared, setOrdersPrepared] = useState([]);
    const [channelsCount, setchannelsCount] = useState([]);

    useEffect(() => {
        if (props.data !== undefined) {
            setOrdersPrepared(props.data);
            setQty(props.data);
        }
    }, [props.data])

    function setQty(array) {
        var channels = new Array();
        array.map((item, index) => {
            if (channels[item.channel_name] === undefined) {
                channels[item.channel_name] = 1;
            } else {
                channels[item.channel_name] = channels[item.channel_name] + 1;
            }
        })
        var r = new Array();
        for (const [key, value] of Object.entries(channels)) {
            r.push({ name: key, total: value })

        }
        setchannelsCount(r)
    }

    return (
        <div className="card-container">
            <div className="card-border">
                <div className="card-title-text">
                    Pedidos preparados
                </div>
                <div className="charts-daterange">{moment(props.dateFrom).format("DD/MM/YYYY")} - {moment(props.dateTo).format("DD/MM/YYYY")}</div>
                <div className="chart-container" >
                    <div className="progressbar-wrapper progressbar-total">
                        <div className="display-table">
                            <div className="table-cell">
                                <div className="progressbar-label">Total</div>
                            </div>
                        </div>
                        <div className="display-table">
                            <div className="table-cell">
                                <div className="progress-container round-xlarge">
                                    <div className="progressbar round-xlarge" style={{ width: "100%" }}></div>
                                </div>
                            </div>
                        </div>
                        <div className="display-table">
                            <div className="table-cell">
                                <div className="progressbar-number">{props.data.length}</div>
                            </div>
                        </div>
                    </div>
                    {channelsCount.map(function (value, key, channelsCount) {

                        var total_channel = parseFloat(value.total * 100 / ordersPrepared.length).toFixed(2)
                        return (<div className="progressbar-wrapper" key={value.name}>
                            <div className="display-table">
                                <div className="table-cell">
                                    <div className="progressbar-label">{value.name}</div>
                                </div>
                            </div>
                            <div className="display-table">
                                <div className="table-cell">
                                    <div className="progress-container round-xlarge">
                                        <div className="progressbar round-xlarge" style={{ width: total_channel + "%" }}></div>
                                    </div>
                                </div>
                            </div>
                            <div className="display-table">
                                <div className="table-cell">
                                    <div className="progressbar-number">{value.total}</div>
                                </div>
                            </div>
                        </div>)

                    })}

                </div>
            </div>
        </div>
    );
}
export default OrdersPrepared;
