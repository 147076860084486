import axiosUtils from './axiosUtils';

/**
 * @param  {String} url       Relative path
 * @param  {Object} data      JSON data converts to URI: ?key=value&foo=bar
 * @param  {Object} options   Config for requester
 * @return {Promise}          Thenable/Catcheable
 */
export function _get(url, data = {}, options = {}) {
    return axiosUtils.get(url, {params: data, ...options});
}
