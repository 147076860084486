import {useEffect, useState} from 'react';
import {getItem} from "./utils/storageUtil";
import {_get} from "./utils/apiRequester";

export default function useGetConfigData(clearConfig) {

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [configData, setConfigData] = useState([])

    useEffect(() => {
        setConfigData([])
    }, [clearConfig])

    async function getConfigData() {
        const url = getItem('url')
        return await _get(`${url}/config_data`);
    }

    useEffect(() => {
        setLoading(true)
        setError(false)
        let cancel
        getConfigData().then(response => {
            const {data} = response;
            setConfigData(data);
        });
    }, [clearConfig])

    return {
        loading,
        error,
        configData
    }
}
