import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import copy from 'copy-to-clipboard';

import Context from '../../../context';
import QuoteModal from '../CheckoutModal';
import useSaveQuote from '../../../tools/useSaveQuote';
import useGetVtexQuote from '../../../tools/useGetVtexQuote';

import { Snackbar } from 'material-ui';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faCopy,
  faEnvelope,
  faLink,
  faPaperPlane,
  faShoppingCart,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';

function useInputCodigo(defaultValue = '') {
  const [value, setValue] = useState(defaultValue);
  return {
    bind: {
      value,
      onChange: (event) => setValue(event.target.value),
    },
    clear: () => setValue(''),
    value: () => value.replace(/[^a-zA-Z0-9\u00f1\u00d1]/gi, '-'),
    addValue: (val) => setValue(val),
  };
}

function useInputClientEmail(defaultValue = '') {
  const [value, setValue] = useState(defaultValue);
  return {
    bind: {
      value,
      onChange: (event) => setValue(event.target.value),
    },
    clear: () => setValue(''),
    value: () => value,
    addValue: (val) => setValue(val),
  };
}

const CartModal = (props) => {
  const { setIsOpenModal } = useContext(Context);
  const { itemsCart, setItemsCart } = useContext(Context);
  const { showModal } = useContext(Context);
  const [itemBorrado, setitemBorrado] = useState(false);
  const [checkoutModal, setcheckoutModal] = useState(false);
  const [itemsNoDuplicates, setitemsNoDuplicates] = useState([]);
  const inputCodigo = useInputCodigo('');
  const inputClientEmail = useInputClientEmail('');
  const [codigoVendedor, setcodigoVendedor] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [action, setAction] = useState('');
  const [getUrl, setGetUrl] = useState(false);
  const [url, setUrl] = useState('');
  const [viewMail, setviewMail] = useState(false);
  const [quoteDataToSave, setQuoteDataToSave] = useState([]);
  const [sendQuoteData, setSendQuoteData] = useState(false);
  useSaveQuote(sendQuoteData, quoteDataToSave);
  const [nroItems, setnroItems] = useState(0);

  const [urlCartVtex, seturlCartVtex] = useState('');
  const [typeVtexCart, settypeVtexCart] = useState('');
  const [viewMailVtex, setviewMailVtex] = useState(false);
  const [dataVtex, setdataVtex] = useState();

  const [quoteVtex, setquoteVtex] = useState(false);
  const { urlVtexCart, orderFormId, loading } = useGetVtexQuote(
    quoteVtex,
    itemsNoDuplicates,
    codigoVendedor,
  );

  function closeModal() {
    setIsOpenModal(false);
  }

  const styleHeaderDialog = {
    backgroundColor: 'rgba(0,0,0,0.05)',
    WebkitBoxShadow: '0 1px 14px -6px #000',
    MozBoxShadow: '0 1px 14px -6px #000',
    boxShadow: '0 1px 14px -6px #000',
  };

  const deleteItem = (item) => {
    const toDelete = item.skuOmni;
    setItemsCart(itemsCart.filter((item) => item.skuOmni !== toDelete));
    setitemBorrado(true);
  };

  const totalSum = () => {
    if (itemsCart.length !== 0) {
      const subtotals = [];
      itemsCart.forEach((element) => {
        subtotals.push(
          element.price
            .substring(2, element.price.length + 1)
            .replace('.', '')
            .replace(',', '.'),
        );
      });
      const total = parseFloat(
        subtotals.reduce((acc, cur) => parseFloat(acc) + parseFloat(cur)),
      ).toLocaleString('es');
      return total;
    }
  };

  function removeDuplicated() {
    const itemsQty = [];
    itemsCart.forEach((element) => {
      const qty = itemsCart.filter((f) => f.skuOmni === element.skuOmni).length;
      element.qty = qty;
      if (itemsQty.filter((f) => f.skuOmni === element.skuOmni).length === 0) {
        itemsQty.push(element);
      }
    });
    return itemsQty;
  }

  function createQuote() {
    setcodigoVendedor(inputCodigo.value());
    setAction('redirect');
    const list = removeDuplicated();
    const listAux = [];
    list.forEach((item) => {
      const itemPost = {
        sku_omni: item.skuOmni,
        origin_sku: item.origin_sku,
        color: item.color,
        size: item.size,
        qty: item.qty,
      };
      setitemsNoDuplicates((i) => [...i, itemPost]);
    });
    setcheckoutModal(true);
  }

  function createQuoteVtex() {
    setcodigoVendedor(inputCodigo.value());
    setAction('redirect');
    settypeVtexCart('redirect');
    setItemsVtex();
    if (quoteVtex) {
      setquoteVtex(false);
    } else {
      setquoteVtex(true);
    }
  }

  function setItemsVtex() {
    const list = removeDuplicated();
    const listAux = [];
    list.forEach((item) => {
      const itemPost = {
        sku_omni: item.skuOmni,
        origin_sku: item.origin_sku,
        color: item.color,
        size: item.size,
        qty: item.qty,
      };
      setitemsNoDuplicates((i) => [...i, itemPost]);
    });
  }

  useEffect(() => {
    if (urlVtexCart !== '') {
      setquoteVtex(false);
      seturlCartVtex(urlVtexCart);
      setDataToVtex();
      if (action === 'redirect') {
        setcheckoutModal(true); //redireccion
      }
      if (action === 'share' && typeVtexCart === 'mail') {
        setcheckoutModal(true);
      }
    }
  }, [urlVtexCart]);

  function setDataToVtex() {
    var data = {
      store_id: localStorage.getItem('store_id'),
      channel_id: localStorage.getItem('PickupChannel'),
      user_id: null,
      user_email: localStorage.getItem('user_email'),
      seller_code: inputCodigo.value(),
      quote_id: orderFormId,
      type: typeVtexCart,
    };
    setdataVtex(data);
  }

  function saveQuoteVtex() {
    setDataToVtex();
    if (action === 'share') {
      if (typeVtexCart === 'whatsapp') {
        setQuoteDataToSave(dataVtex);
        setSendQuoteData(true);
      }
    }
  }

  function generateLinkVtex() {
    setcodigoVendedor(inputCodigo.value());
    setAction('share');
    settypeVtexCart('whatsapp');
    setItemsVtex();
    setquoteVtex(true);
    setviewMailVtex(false);
  }

  function sendMailVtex() {
    if (inputClientEmail.value() === '') {
      alert('Email del Cliente es un campo obligatorio');
      return;
    }
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
    );

    if (!pattern.test(inputClientEmail.value())) {
      alert('No es valido Email del Cliente');
      return;
    }
    setcodigoVendedor(inputCodigo.value());
    setClientEmail(inputClientEmail.value());
    setAction('share');
    setItemsVtex();
    settypeVtexCart('mail');
    setquoteVtex(true);
  }

  useEffect(() => {}, [itemsCart]);

  function shareQuote() {
    if (viewMail) {
      if (inputClientEmail.value() === '') {
        alert('Email del Cliente es un campo obligatorio');
        return;
      }
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
      );

      if (!pattern.test(inputClientEmail.value())) {
        alert('No es valido Email del Cliente');
        return;
      }
      setcodigoVendedor(inputCodigo.value());
      setClientEmail(inputClientEmail.value());
      setAction('share');
      const list = removeDuplicated();
      const listAux = [];
      list.forEach((item) => {
        const itemPost = {
          sku_omni: item.skuOmni,
          origin_sku: item.origin_sku,
          color: item.color,
          size: item.size,
          qty: item.qty,
        };
        setitemsNoDuplicates((i) => [...i, itemPost]);
      });
      setcheckoutModal(true);
    }
  }

  useEffect(() => {
    if (getUrl) {
      axios({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          'Glamit-Env': localStorage.getItem('store_id'),
        },
        url: localStorage.getItem('url') + '/quote',
        params: {
          codigoVendedor: codigoVendedor + '_omni',
        },
        data: JSON.stringify(removeDuplicated()),
      })
        .then(function (response) {
          setGetUrl(false);
          if (response.status === 200) {
            return setUrl(
              response.data +
                '&codigovendedor=' +
                codigoVendedor +
                '_omni&utm_source=omnichannel_dashboard&utm_content=' +
                codigoVendedor +
                '&utm_medium=whatsapp',
            );
          }
        })
        .catch((e) => {
          alert(
            'Su acción no funcionó como debía. Por favor, intente más tarde o contacte al administrador',
          );

          if (e.response !== undefined) {
            if (e.response.status === 401 || e.response.status === 403) {
              window.location.href = window.location.origin + '/login';
            }
          }
        });
    }
  }, [getUrl]);

  function getLinkQuote() {
    setcodigoVendedor(inputCodigo.value());
    setGetUrl(true);
  }

  function setSendMail() {
    setviewMail(true);
    shareQuote();
  }

  function saveQuoteData(type, url_string) {
    var url = new URL(url_string);
    var quote_id = url.searchParams.get('quote_id');
    var data = {
      store_id: localStorage.getItem('store_id'),
      channel_id: localStorage.getItem('PickupChannel'),
      user_id: null,
      user_email: localStorage.getItem('user_email'),
      seller_code: inputCodigo.value(),
      quote_id: window.atob(quote_id),
      type: type,
    };
    setQuoteDataToSave(data);
    setSendQuoteData(true);
  }

  function handleClose() {
    setitemBorrado(false);
  }

  return (
    <Context.Provider
      value={{
        itemsCart,
        setcheckoutModal,
        setIsOpenModal,
        setItemsCart,
        setitemsNoDuplicates,
      }}
    >
      <div>
        {checkoutModal === true && (
          <QuoteModal
            items={itemsNoDuplicates}
            codigoVendedor={codigoVendedor}
            clientEmail={clientEmail}
            action={action}
            urlQuoteVtex={urlCartVtex}
            dataVtex={dataVtex}
          />
        )}

        <MuiThemeProvider>
          <Snackbar
            bodyStyle={{ backgroundColor: '#ffffff' }}
            style={{ height: '100%', left: '80%' }}
            anchororigin={{ vertical: 'top', horizontal: 'right' }}
            message={
              <div>
                <FontAwesomeIcon className='text-danger mr-1' icon={faCheck} />{' '}
                <span style={{ fontWeight: '500' }} className='text-black-50'>
                  Item eliminado!
                </span>
              </div>
            }
            open={itemBorrado}
            onRequestClose={handleClose}
            key='deleteItem'
            autoHideDuration={2000}
          ></Snackbar>
        </MuiThemeProvider>

        <div
          className='modal'
          tabIndex='-1'
          role='dialog'
          style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.7)' }}
        >
          <div className='modal-dialog modal-md' role='document'>
            <div className='modal-content'>
              <div
                className='modal-header pt-2 pb-0 border-0'
                style={{ styleHeaderDialog }}
              >
                <div className='row w-100 text-center'>
                  <div className='col-12'>
                    <h6 className='modal-title'>
                      Carrito
                      <span className='badge badge-pill badge-success ml-1 h6'>
                        {itemsCart.length}
                      </span>
                      <span
                        style={{ fontWeight: '500' }}
                        className={
                          itemsCart.length > 1 || itemsCart.length == 0
                            ? 'd-inline'
                            : 'd-none'
                        }
                      >
                        {' '}
                        ítems{' '}
                      </span>
                      <span
                        style={{ fontWeight: '500' }}
                        className={
                          itemsCart.length == 1 ? 'd-inline' : 'd-none'
                        }
                      >
                        {' '}
                        ítem{' '}
                      </span>
                    </h6>
                  </div>
                </div>
                <button
                  className='close'
                  type='button'
                  data-dismiss='modal'
                  aria-label='Close'
                  onClick={() => closeModal()}
                >
                  <span aria-hidden='true'>×</span>
                </button>
              </div>
              {itemsCart.length > 0 && (
                <div className='row w-100 text-center ml-0'>
                  <div className='col-12'>
                    <div
                      className='modal-body table-responsive p-0'
                      style={{ height: '240px' }}
                    >
                      <table
                        className='table table-responsive-sm'
                        style={{ tableLayout: 'auto' }}
                      >
                        <thead className='bg-light '>
                          <tr style={{ fontSize: '0.8rem' }}>
                            <th
                              scope='col'
                              className='pt-2 pb-2 border-0 text-left'
                            >
                              Producto
                            </th>
                            <th scope='col' className='pt-2 pb-2 border-0'>
                              Precio
                            </th>
                            <th scope='col' className='pt-2 pb-2 border-0'>
                              Cantidad
                            </th>
                            <th scope='col' className='pt-2 pb-2 border-0'></th>
                          </tr>
                        </thead>
                        <tbody style={{ fontSize: '0.8rem' }}>
                          {removeDuplicated().map((item, index) => {
                            {
                              /* {itemsCart.map((item, index) => { */
                            }
                            return (
                              <tr key={index}>
                                <td
                                  className='row'
                                  style={{
                                    marginLeft: '0px',
                                    marginRight: '0px',
                                  }}
                                >
                                  <div className='col-4 text-left p-0'>
                                    {item.image != '' && (
                                      <a href={item.image} target='_blank'>
                                        {' '}
                                        <img
                                          className='rounded-lg img-thumbnail border-0 p-0'
                                          width='60px'
                                          height='60px'
                                          src={item.image}
                                        />
                                      </a>
                                    )}
                                    {item.image == '' && (
                                      <a
                                        href='http://kosiuko.glamit.com.ar/skin/frontend/base/default/images/catalog/product/placeholder/image.jpg'
                                        target='_blank'
                                      >
                                        {' '}
                                        <img
                                          className='rounded-lg img-thumbnail border-0 p-0'
                                          width='60px'
                                          height='60px'
                                          src='http://kosiuko.glamit.com.ar/skin/frontend/base/default/images/catalog/product/placeholder/image.jpg'
                                        />
                                      </a>
                                    )}
                                  </div>
                                  <div className='col-8 text-left p-0'>
                                    <span style={{ fontWeight: '500' }}>
                                      {item.name}
                                    </span>
                                    <span className='d-block'>
                                      SKU {item.skuOmni}
                                    </span>
                                    <span className='d-block'>
                                      Color {item.color} Talle {item.size}
                                    </span>
                                  </div>
                                </td>
                                <td>{item.price}</td>
                                <td>{item.qty}</td>
                                <td>
                                  <button
                                    type='button'
                                    className='btn btn-outline-secondary btn-sm'
                                    onClick={() => deleteItem(item)}
                                  >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* {localStorage.getItem("plataforma") !== "vtex" && ( */}
                  <div className='col-12 mb-1 mt-2'>
                    <div className='input-group input-group-sm'>
                      <div className='input-group-prepend'>
                        <span className='input-group-text'>
                          Código Vendedor
                        </span>
                      </div>
                      <input
                        type='text'
                        {...inputCodigo.bind}
                        className='form-control'
                      />
                    </div>
                  </div>
                  {/* )} */}
                  {viewMail && (
                    <div className='col-12 mb-1'>
                      <div className='input-group input-group-sm'>
                        <div className='input-group-prepend'>
                          <span
                            className='input-group-text'
                            style={{ paddingRight: '13px' }}
                          >
                            Email del Cliente
                          </span>
                        </div>
                        <input
                          type='text'
                          {...inputClientEmail.bind}
                          className='form-control'
                        />
                        <div className='input-group-append'>
                          <button
                            className='btn btn-success btn-sm'
                            type='button'
                            onClick={() => setSendMail()}
                          >
                            <FontAwesomeIcon
                              icon={faPaperPlane}
                              className='mr-1'
                            />{' '}
                            Enviar
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  {viewMailVtex && (
                    <div className='col-12 mb-1'>
                      <div className='input-group input-group-sm'>
                        <div className='input-group-prepend'>
                          <span
                            className='input-group-text'
                            style={{ paddingRight: '13px' }}
                          >
                            Email del Cliente
                          </span>
                        </div>
                        <input
                          type='text'
                          {...inputClientEmail.bind}
                          className='form-control'
                        />
                        <div className='input-group-append'>
                          <button
                            className='btn btn-success btn-sm'
                            type='button'
                            onClick={() => sendMailVtex()}
                          >
                            <FontAwesomeIcon
                              icon={faPaperPlane}
                              className='mr-1'
                            />{' '}
                            Enviar
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className='col-12'>
                    {url !== '' &&
                      localStorage.getItem('plataforma') !== 'vtex' && (
                        <div className='input-group mb-2 mt-2'>
                          <input
                            type='text'
                            className='form-control'
                            placeholder={url}
                            disabled
                          />
                          <div className='input-group-append'>
                            <button
                              className='btn btn-outline-secondary btn-sm'
                              type='button'
                              //onClick={() => {navigator.clipboard.writeText(url)}}>
                              onClick={() => {
                                copy(url);
                                saveQuoteData('whatsapp', url);
                              }}
                            >
                              <FontAwesomeIcon icon={faCopy} /> Copiar
                            </button>
                          </div>
                        </div>
                      )}
                    {urlCartVtex !== '' && action === 'share' && (
                      <div className='input-group mb-3 mt-2'>
                        <input
                          type='text'
                          className='form-control'
                          placeholder={urlCartVtex}
                          disabled
                        />
                        <div className='input-group-append'>
                          <button
                            className='btn btn-outline-secondary btn-sm'
                            type='button'
                            onClick={() => {
                              copy(urlCartVtex);
                              saveQuoteVtex();
                            }}
                          >
                            <FontAwesomeIcon icon={faCopy} /> Copiar
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-12 text-right mb-2'>
                    <span
                      className='badge badge-light'
                      style={{
                        fontSize: '0.9rem',
                        fontWeight: '600',
                      }}
                    >
                      Subtotal ${totalSum()}
                    </span>
                  </div>

                  {loading && (
                    <div className='text-center w-100'>
                      <div
                        className='spinner-border text-primary mt-2'
                        role='status'
                      ></div>
                      <span className='d-block font-weight-normal'>
                        Por favor, espere...
                      </span>
                    </div>
                  )}
                  <div className='col-12 mb-2'>
                    {localStorage.getItem('plataforma') === 'vtex' && (
                      <div className='row'>
                        <div className='col-4 text-left'>
                          <button
                            className='btn btn-success btn-sm'
                            type='button'
                            onClick={() => generateLinkVtex()}
                          >
                            <FontAwesomeIcon icon={faLink} className='mr-1' />
                            Generar Link
                          </button>
                        </div>
                        <div className='col-4 text-center'>
                          <button
                            className='btn btn-success btn-sm'
                            type='button'
                            onClick={() => setviewMailVtex(true)}
                          >
                            <FontAwesomeIcon
                              icon={faEnvelope}
                              className='mr-1'
                            />
                            Enviar por Mail
                          </button>
                        </div>
                        <div className='col-4 text-right'>
                          <button
                            className='btn btn-success btn-sm'
                            type='button'
                            onClick={() => {
                              createQuoteVtex();
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faShoppingCart}
                              className='mr-1'
                            />
                            Ir al Carrito
                          </button>
                        </div>
                      </div>
                    )}

                    {localStorage.getItem('plataforma') !== 'vtex' && (
                      <div className='row'>
                        <div className='col-4 text-left'>
                          <button
                            className='btn btn-success btn-sm'
                            type='button'
                            onClick={() => getLinkQuote()}
                          >
                            <FontAwesomeIcon icon={faLink} className='mr-1' />
                            Generar Link
                          </button>
                        </div>
                        <div className='col-4 text-center'>
                          <button
                            className='btn btn-success btn-sm'
                            type='button'
                            onClick={() => setSendMail()}
                          >
                            <FontAwesomeIcon
                              icon={faEnvelope}
                              className='mr-1'
                            />
                            Enviar por Mail
                          </button>
                        </div>
                        <div className='col-4 text-right'>
                          <button
                            className='btn btn-success btn-sm'
                            type='button'
                            onClick={() => createQuote()}
                          >
                            <FontAwesomeIcon
                              icon={faShoppingCart}
                              className='mr-1'
                            />
                            Ir al Carrito
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Context.Provider>
  );
};

export default CartModal;