import React, { useContext } from 'react';
import Context from '../../../context';
import Loader from '../../Loader';


const SkuModal = (props) => {
    const { setIsOpenSkuModal } = useContext(Context)
    function closeModal() {
        document.getElementsByClassName("modal-backdrop")[0].remove();

        setIsOpenSkuModal(false);
    }

    const div = document.createElement("div");
    div.classList.add('modal-backdrop');
    document.body.appendChild(div);

    return (

        <div className="modal show" tabIndex="-1" role="dialog" style={{ display: 'block' }} >
            <div className="modal-dialog modal-md" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">
                            {props.sku.sku_name}</h5>
                        <button className="btn btn-light btn-sm" type="button" data-dismiss="modal" aria-label="Close" onClick={() => closeModal()} >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                    <div className="modal-body table-responsive modal-channel">
                        <img className="mb-3" src={props.sku.sku_img_url} width="280px" />
                        <span className="d-block mb-1">SKU: {props.sku.sku}</span>
                        <span className="d-block mb-1">Color: {props.sku.sku_color}</span>
                        <span className="d-block mb-1">Talle: {props.sku.sku_size}</span>
                        <span className="d-block mb-1">Cantidad: {props.sku.qty}</span>

                    </div>
                </div>
            </div>
            <div>{props.loadingModal && (<Loader />)}</div>
            <div>{props.errorModal && 'Error'}</div>

        </div>
    );
}

export default SkuModal;
