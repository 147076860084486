import React from 'react'

export default () => (
    <div className="card-container">
        <div className="card-border">
            <div id="loading-mask-part" >
                <p className="loader" id="loading_mask_loader">
                <div class="spinner-border" role="status"></div>
                    <span>Por favor, espere...</span>
                </p>
            </div>
        </div>
    </div>

)