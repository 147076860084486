export function getOmsUrl() {
  if (
    window.location.href.indexOf('.uat') > 0 ||
    window.location.href.indexOf('localhost') > 0
  ) {
    return 'https://oms.uat.glamit.com.ar';
  }

  return 'https://oms.glamit.com.ar';
}

export function getOmsApiBaseUrl() {
  if (
    window.location.href.indexOf('.uat') > 0 ||
    window.location.href.indexOf('localhost') > 0
  ) {
    return 'https://api.oms.uat.glamit.com.ar';
  }

  return 'https://api.oms.glamit.com.ar';
}
