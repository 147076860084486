import React, { useState, useContext, useEffect } from 'react';
import Context from '../../../context';
import axios from 'axios';
import Loader from '../../Loader';

const UploadStockModal = () => {
    const { setIsOpenUploadStockModal } = useContext(Context)
    const [file, setFile] = useState();
    const [csvName, setCsvName] = useState();
    const [loading, setLoading] = useState(false);

    const prettyInputFile = {
        display: 'none',
    };

    const prettyLabelFile = {
        cursor: 'pointer',
        padding: '10px 15px 10px',
        marginTop: '20px',
        marginBottom: '20px',
        backgroundColor: '#F5F5F5',
        borderStyle: 'dotted',
        borderColor: '#C0C0C0'
    };

    const handleOnChange = (e) => {
        const isCsv = e.target.files[0] && e.target.files[0].type === 'text/csv';
        if (!isCsv) {
            alert('Solo se permiten archivos de tipo csv');
            return;
        }
        setFile(e.target.files[0]);
        setCsvName(e.target.files[0].name);

    };

    function closeModal() {
        document.getElementsByClassName("modal-backdrop")[0].remove();
        setIsOpenUploadStockModal(false);
    }
    useEffect(() => {
        const div = document.createElement("div");
        div.classList.add('modal-backdrop');
        document.body.appendChild(div);
    }, [])

    function submitHandler(event) {
        const formData = new FormData();
        formData.append("file", file);
        event.preventDefault();
        setLoading(true);
        axios({
            method: 'post',
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Glamit-Env': localStorage.getItem("store_id")
            },
            url: localStorage.getItem('url') + '/stock/upload-csv',
            data: formData
        }).then(function (response) {
            setLoading(false);
            if (response.status === 200) {
                closeModal();
                alert('Se subio con exito el archivo de stocks');
            }
        }).catch(e => {
            setLoading(false);
            alert('Error al importar el archivo de stocks. ' + e.response.data.error)

        }).finally(() => {
            setLoading(false);
        })

    }

    return (
        <div className="modal show" tabIndex="-1" role="dialog" style={{ display: 'block' }} >
            <div className="modal-dialog " role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Importar Stocks </h5>
                        <button className="btn btn-light btn-sm" type="button" data-dismiss="modal" aria-label="Close" onClick={() => closeModal()} >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <form onSubmit={submitHandler}>
                        <div className="modal-body table-responsive">
                            <div>
                                <label id="file-input-label" for='file-input' style={prettyLabelFile}> <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none" /><path d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z" /></svg> Importar archivo de stocks</label><p>{csvName}</p>
                                <input type='file' style={prettyInputFile} accept='.csv' id='file-input' name='file-input' onChange={handleOnChange} />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-secondary" type="button" data-dismiss="modal" onClick={() => closeModal()}>Cancelar</button>
                            <input type="submit" name="Enviar" className="btn btn-primary" />
                            {loading && <Loader />}
                        </div>
                    </form>
                </div>
            </div>

        </div>
    );
}

export default UploadStockModal;