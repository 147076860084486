import React from 'react';
import moment from 'moment'

const DeliveredCount = (props) => {


    return (
        <div className="card-container">
            <div className="card-border">
                <div className="card-title-text">
                    Pedidos Omnichannel (Asignados)
			  	</div>
                <div className="charts-daterange">{moment(props.dateFrom).format("DD/MM/YYYY")} - {moment(props.dateTo).format("DD/MM/YYYY")}</div>
                <div className="chart-container">
                    <div className="number-chart" ><span className="countup">{parseFloat(props.data.pedidos_omni*100/props.data.pedidos_totales).toFixed(2)}</span>%</div>
                    <div className="number-chart-subtitle">(<span className="cant-pedidos-omni">{props.data.pedidos_omni}</span> Asignados / <span className="cant-pedidos-totales">{props.data.pedidos_totales}</span> Totales)</div>
                </div>
            </div>
        </div>


    );
}

export default DeliveredCount;
