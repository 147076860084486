import { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import moment from 'moment';
import axios from 'axios';

import Role from '../../tools/role';
import Nav from '../Nav';

import { printTicket } from '../Rows/OrderTableRow';

import okSound from './assets/sounds/ok.mp3';
import errorSound from './assets/sounds/error.mp3';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBarcode,
  faCheckCircle,
  faExclamationCircle,
  faInfoCircle,
  faSpinner,
  faTimes,
  faPrint,
  faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import barcodeScan from './assets/icons/barcode-scan.png';

import './index.css';

const BARCODE_INPUT_MAX_LENGTH = 25;
const SCANNED_ORDERS_KEY = 'scanned_orders';
const SCANNED_ORDERS_MAX_LENGTH = 100;
const NOTIFICATION_DURATION_MS_LENGTH = 5500;
const READY_FOR_PICKUP_STATE = 'READY_FOR_PICKUP';

const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${localStorage.getItem('token')}`,
  'Glamit-Env': localStorage.getItem('store_id')
};

const notificationType = {
  error: {
    title: 'Error',
    icon: faExclamationCircle,
    sound: new Audio(errorSound)
  },
  info: {
    title: 'Información',
    icon: faInfoCircle,
    sound: new Audio(okSound)
  },
  success: {
    title: 'Éxito',
    icon: faCheckCircle,
    sound: new Audio(okSound)
  },
  default: {
    title: 'Atención',
    icon: faInfoCircle,
    sound: new Audio(errorSound)
  }
};

const redirectToLogin = () => (window.location.href = `${window.location.origin}/login`);

const Inbound = () => {
  if (localStorage.getItem('token') === null || localStorage.getItem('token') === 'null') {
    redirectToLogin();
  }

  Role(['PICKUP', 'ADMIN', 'ADMINMARCA']);

  const [scannedOrders, setScannedOrders] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);

  const barcodeInputRef = useRef(null);

  useEffect(() => {
    try {
      const loadedOrders = JSON.parse(localStorage.getItem(SCANNED_ORDERS_KEY));
      if (loadedOrders) {
        setScannedOrders(loadedOrders);
      }
    } catch (error) {
      setScannedOrders([]);
    }
  }, []);

  useEffect(() => {
    const reFocusInput = () => {
      setTimeout(() => {
        if (barcodeInputRef.current) {
          barcodeInputRef.current.focus();
        }
      }, 500);
    };

    document.addEventListener('mouseup', reFocusInput);
    return () => document.removeEventListener('mouseup', reFocusInput);
  }, []);

  useEffect(() => {
    if (notifications.length > 0) {
      setTimeout(() => {
        if (barcodeInputRef.current) {
          barcodeInputRef.current.focus();
        }
      }, 0);
    }
  }, [notifications]);

  const removeNotification = (key) =>
    setNotifications((prevNotifications) => prevNotifications.filter((n) => n.key !== key));

  const generateNotification = (notification) => {
    const { key, identifier, type, message } = notification;
    if (notifications.some((n) => n.key === key)) {
      return;
    }

    const handleRemove = () => removeNotification(key);

    const { icon, title } = notificationType[type];
    notificationType[type].sound.play();

    const notificationBox = (
      <div key={key} className='notification' onAnimationEnd={handleRemove}>
        <FontAwesomeIcon icon={icon} className={classNames('icon', type)} />
        <div className='content'>
          <div className={classNames('title', type)}>
            {title} {identifier && <span>[{identifier}]</span>}
          </div>

          <div className='message' dangerouslySetInnerHTML={{ __html: message }}></div>

          <button type='button' className='btn btn-outline-secondary btn-sm clear' onClick={handleRemove}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      </div>
    );

    setNotifications((prevNotifications) => [notificationBox, ...prevNotifications]);
    setTimeout(() => removeNotification(key), NOTIFICATION_DURATION_MS_LENGTH);
  };

  const createScannedOrder = (order, increment_id) => ({
    increment_id,
    order: {
      increment_id: order.increment_id,
      customer_name: order.customer_name,
      customer_lastname: order.customer_lastname,
      customer_document: order.customer_document,
      created_at: order.created_at
    },
    scanned_date: moment().format('DD/MM/YYYY HH:mm:ss'),
    remove: () => removeScannedOrder(increment_id)
  });

  const updateScannedOrders = (scannedOrders) => {
    localStorage.setItem(SCANNED_ORDERS_KEY, JSON.stringify(scannedOrders));
    setScannedOrders(scannedOrders);
  };

  const markOrderAsReadyForPickup = (increment_id) => {
    setLoading(true);
    axios({
      method: 'PUT',
      headers,
      url: `${localStorage.getItem(
        'url'
      )}/order/state/by-increment-id?&increment_id=${increment_id}&state=${READY_FOR_PICKUP_STATE}`
    })
      .then((response) => {
        if (response.status === 200) {
          const order = response.data;
          const scannedOrder = createScannedOrder(order, increment_id);

          updateScannedOrders([scannedOrder, ...scannedOrders.slice(0, SCANNED_ORDERS_MAX_LENGTH - 1)]);
          generateNotification({
            key: increment_id,
            identifier: increment_id,
            type: 'success',
            message: `Pedido marcado como recibido.`
          });
          printTicket(scannedOrder.order);
        }
      })
      .catch((error) => {
        console.error({ error });
        if ([401, 403].includes(error.response.status)) {
          redirectToLogin();
        }

        if (axios.isCancel(error)) {
          return;
        }

        generateNotification({
          key: increment_id,
          identifier: increment_id,
          type: 'error',
          message: `${error.response.data.error ?? error.response.data.message}`
        });
      })
      .finally(() => setLoading(false));
  };

  const addScannedOrder = async (event) => {
    barcodeInputRef.current.focus();
    if (event.key !== 'Enter') {
      return;
    }

    const barcode = event.target.value.trim();
    event.target.value = '';

    if (!barcode) {
      return;
    }

    if (barcode.length > BARCODE_INPUT_MAX_LENGTH) {
      generateNotification({
        key: barcode,
        identifier: barcode,
        type: 'error',
        message: `El código de barras es muy largo. <br>El máximo permitido es de ${BARCODE_INPUT_MAX_LENGTH} caracteres.`
      });
      return;
    }

    if (!/^\d+$/.test(barcode)) {
      generateNotification({
        key: barcode,
        identifier: barcode,
        type: 'error',
        message: 'El código de barras es inválido.'
      });
      return;
    }

    const isOrderAlreadyScanned = scannedOrders.some((order) => order.increment_id === barcode);
    if (isOrderAlreadyScanned) {
      const notificationExists = notifications.some((notification) => notification.key === barcode);
      if (notificationExists) {
        return;
      }

      generateNotification({
        key: barcode,
        identifier: barcode,
        type: 'info',
        message: `El pedido ya fue escaneado.`
      });
      return;
    }

    markOrderAsReadyForPickup(barcode);
  };

  const removeScannedOrder = (barcode) =>
    updateScannedOrders(scannedOrders.filter((order) => order.increment_id !== barcode));

  const clearScannedOrders = () => updateScannedOrders([]);

  const isEmptyOrder = (order) => Object.keys(order).length === 0 && order.constructor === Object;

  const handleLabelPrint = (order) => {
    printTicket(order);

    generateNotification({
      key: `print_${order.increment_id}_${Date.now()}`,
      identifier: order.increment_id,
      type: 'info',
      message: `Imprimiendo etiqueta del pedido.`
    });
  };

  const handleAllLabelsPrint = () => {
    scannedOrders
      .filter((scannedOrder) => scannedOrder.order && !isEmptyOrder(scannedOrder.order))
      .forEach((scannedOrder) => printTicket(scannedOrder.order));

    generateNotification({
      key: `print_all_${Date.now()}`,
      identifier: '',
      type: 'info',
      message: `Imprimiendo todas las etiquetas disponibles.`
    });
  };

  const handleFormSubmit = (event) => event.preventDefault();

  return (
    <>
      <Nav />

      <main>
        <div className='inbound'>
          <div className='scanner'>
            <form onSubmit={handleFormSubmit}>
              <img src={barcodeScan} className='icon main' alt='Scan Barcode' />
              <div className='input-group input-group-md'>
                <div className='input-group-prepend'>
                  <span className='input-group-text'>
                    {loading ? (
                      <FontAwesomeIcon icon={faSpinner} className='icon' spin />
                    ) : (
                      <FontAwesomeIcon icon={faBarcode} className='icon barcode' />
                    )}
                  </span>
                </div>
                <input
                  ref={barcodeInputRef}
                  type='text'
                  name='search'
                  title='Barcode'
                  className='form-control input-group barcode'
                  onKeyDown={addScannedOrder}
                  onInput={(event) => {
                    event.target.value = event.target.value.replace(/\D/g, '');
                  }}
                  autoFocus
                  maxLength={BARCODE_INPUT_MAX_LENGTH}
                  disabled={loading}
                />
              </div>
              <div className='tip'>Escaneá para marcar el pedido como recibido</div>
            </form>
          </div>

          <div className='inbound-list'>
            <div className='title'>
              <h3>Últimos pedidos recibidos</h3>
              <span>{scannedOrders.length}</span>
            </div>

            <ul>
              {scannedOrders.length === 0 && <li className='empty'>No hay pedidos escaneados</li>}

              {scannedOrders.map((scannedOrder) => (
                <li key={scannedOrder.increment_id}>
                  <span>{scannedOrder.increment_id}</span>
                  <span>{scannedOrder.scanned_date}</span>
                  <div className='actions'>
                    {scannedOrder.order && !isEmptyOrder(scannedOrder.order) && (
                      <button
                        type='button'
                        className='btn btn-outline-secondary btn-sm print d-inline'
                        onClick={() => handleLabelPrint(scannedOrder.order)}
                        title='Imprimir etiqueta del pedido'
                      >
                        <FontAwesomeIcon icon={faPrint} />
                      </button>
                    )}

                    <button
                      type='button'
                      className='btn btn-outline-secondary btn-sm clear d-inline'
                      onClick={() => removeScannedOrder(scannedOrder.increment_id)}
                      title='Remover pedido del listado'
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  </div>
                </li>
              ))}
            </ul>

            {scannedOrders.length > 0 && (
              <div className='actions'>
                {scannedOrders.some((scannedOrder) => scannedOrder.order && !isEmptyOrder(scannedOrder.order)) && (
                  <button
                    type='button'
                    className='btn btn-outline-secondary btn-sm print-all'
                    onClick={handleAllLabelsPrint}
                    title='Imprimir etiquetas de todos los pedidos del listado'
                  >
                    <FontAwesomeIcon icon={faPrint} /> Imprimir etiquetas
                  </button>
                )}

                <button
                  type='button'
                  className='btn btn-outline-secondary btn-sm clear'
                  onClick={clearScannedOrders}
                  title='Remover pedidos del listado'
                >
                  <FontAwesomeIcon icon={faTrashAlt} /> Vaciar listado
                </button>
              </div>
            )}
          </div>
        </div>
        <div className='notifications'>{notifications.map((notification) => notification)}</div>
      </main>
    </>
  );
};

export default Inbound;
