import {useEffect, useState} from 'react';
import axios from 'axios';

export default function useGetOrdersDelivered(dateFrom, dateTo, clearMetrics) {

    const [loading2, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [ordersDelivered, setOrdersDelivered] = useState()


    useEffect(() => {
        setOrdersDelivered([])
    }, [dateFrom, dateTo, clearMetrics])

    useEffect(() => {
        setLoading(false)
        setError(false)
        let cancel
        axios({
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Glamit-Env': localStorage.getItem('store_id')
            },
            url: localStorage.getItem('url') + '/metrics/ordersassigned?dateFrom=' + dateFrom + '&dateTo=' + dateTo,
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then(response => {
            setLoading(true)
                    return setOrdersDelivered(response.data)
            
        }).catch(e => {
            if (axios.isCancel(e)) return
            setError(true)
        })
        return () => cancel()
    }, [dateFrom, dateTo, clearMetrics])

    return {
        loading2,
        error,
        ordersDelivered
    }
}
