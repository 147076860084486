import React, { useState, useContext, useEffect } from 'react';
import Context from '../../../context';
import Loader from '../../Loader';
import axios from 'axios';
import { Snackbar } from "material-ui";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

function useInputPedidoNoRecibido(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}
function useInputPedidoNoPreparado(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}
function useInputPedidoNoTerminado(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}
function useInputPedidoinCancelar(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}
function useInputPedidoSinDevolver(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}
function useInputPedidoNoRetirado(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}
const Alertas = (props) => {

    const [loading, setLoading] = useState(false)
    const pedidoNoRecibido = useInputPedidoNoRecibido(0);
    const pedidoNoPreparado = useInputPedidoNoPreparado(0);
    const pedidoNoTerminado = useInputPedidoNoTerminado(0);
    const pedidoSinCancelar = useInputPedidoinCancelar(0);
    const pedidoSinDevolver = useInputPedidoSinDevolver(0);
    const pedidoNoRetirado = useInputPedidoNoRetirado(0);
    const { setClearConfig } = useContext(Context)
    const { configData } = useContext(Context)
    const [openSnackbar, setopenSnackbar] = useState(false)
    const [openSnackbarError, setopenSnackbarError] = useState(false)

    useEffect(()=>{
        configData.map((config, index)=>{
            if(config.path === "pedido_no_recibido"){
                pedidoNoRecibido.addValue(config.value)
            }
            if(config.path === "pedido_no_preparado"){
                pedidoNoPreparado.addValue(config.value)
            }
            if(config.path === "pedido_no_terminado"){
                pedidoNoTerminado.addValue(config.value)
            }
            if(config.path === "pedido_sin_cancelar"){
                pedidoSinCancelar.addValue(config.value)
            }
            if(config.path === "pedido_sin_devolver"){
                pedidoSinDevolver.addValue(config.value)
            }
            if(config.path === "pedido_no_retirado"){
                pedidoNoRetirado.addValue(config.value)
            }
        })
    },[configData]);

    function submitHandler(event){
        event.preventDefault()
        var config_tosend = [
            {path: "pedido_no_recibido", value: pedidoNoRecibido.value(), store_id: localStorage.getItem('store_id')},
            {path: "pedido_no_preparado", value: pedidoNoPreparado.value(), store_id: localStorage.getItem('store_id')},
            {path: "pedido_no_terminado", value: pedidoNoTerminado.value(), store_id: localStorage.getItem('store_id')},
            {path: "pedido_sin_cancelar", value: pedidoSinCancelar.value(), store_id: localStorage.getItem('store_id')},
            {path: "pedido_sin_devolver", value: pedidoSinDevolver.value(), store_id: localStorage.getItem('store_id')},
            {path: "pedido_no_retirado", value: pedidoNoRetirado.value(), store_id: localStorage.getItem('store_id')}
            
        ]
        setLoading(true);
        axios({
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Glamit-Env': localStorage.getItem('store_id')
            },
            url: localStorage.getItem('url') + '/config_data',
            data: config_tosend
        }).then(function (response) {
            if (response.status === 200) {
                setClearConfig(clearConfig => clearConfig + 1)
                setopenSnackbar(true);
            }
            setLoading(false)
        }).catch(e => {
            if (e.response.status === 401 || e.response.status === 403) {
                setopenSnackbarError(true);
                window.location.href = window.location.origin + "/login"
            }
        });
    }

    return (
        <div className="main-container" id="main">
            <MuiThemeProvider>
                <Snackbar className="pt-3" bodyStyle={{ backgroundColor: '#ffffff' }}
                    style={{ height: "100%", left: "85%", paddingBottom:"75%" }}
                    anchororigin={{ vertical: 'top', horizontal: 'right' }}
                    message={<div  ><FontAwesomeIcon className="text-success mr-1" icon={faCheck} /> <span style={{ fontWeight: "500" }} className="text-black-50">Configuración guardada!</span></div>}
                    open={openSnackbar}
                    autoHideDuration={2000}
                >
                </Snackbar>
            </MuiThemeProvider>
            <MuiThemeProvider>
                <Snackbar className="pt-3" bodyStyle={{ backgroundColor: '#ffffff' }}
                    style={{ height: "100%", left: "85%", paddingBottom:"75%" }}
                    anchororigin={{ vertical: 'top', horizontal: 'right' }}
                    message={<div  ><FontAwesomeIcon className="text-danger mr-1" icon={faTimes} /> <span style={{ fontWeight: "500" }} className="text-black-50">Error al guardar!</span></div>}
                    open={openSnackbarError}
                    autoHideDuration={2000}
                >
                </Snackbar>
            </MuiThemeProvider>
            <div className="title">Alertas</div>

            <form onSubmit={submitHandler}>
                <div className="row mt-3">
                    <div className="col-4"></div>
                    <div className="col-4">
                        <div className="row mt-2">
                            <div className="col-8 text-right">
                                <span style={{ verticalAlign: "sub" }}>Pedido no recibido:</span>
                            </div>
                            <div className="col-4">
                                <input type="number" min="0" {...pedidoNoRecibido.bind} className="w-75 form-control "></input>
                            </div>

                        </div>
                        <div className="row mt-2">
                            <div className="col-8 text-right">
                                <span style={{ verticalAlign: "sub" }}>Pedido no preparado:</span>
                            </div>
                            <div className="col-4">
                                <input type="number" min="0"  {...pedidoNoPreparado.bind} className="w-75 form-control "></input>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-8 text-right">
                                <span style={{ verticalAlign: "sub" }}>Pedido no terminado:</span>
                            </div>
                            <div className="col-4">
                                <input type="number" min="0"  {...pedidoNoTerminado.bind} className="w-75 form-control "></input>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-8 text-right">
                                <span style={{ verticalAlign: "sub" }}>Pedido sin cancelar:</span>
                            </div>
                            <div className="col-4">
                                <input type="number" min="0"  {...pedidoSinCancelar.bind} className="w-75 form-control "></input>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-8 text-right">
                                <span style={{ verticalAlign: "sub" }}>Pedido sin devolver:</span>
                            </div>
                            <div className="col-4">
                                <input type="number" min="0"  {...pedidoSinDevolver.bind} className="w-75 form-control "></input>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-8 text-right">
                                <span style={{ verticalAlign: "sub" }}>Pedido no retirado:</span>
                            </div>
                            <div className="col-4">
                                <input type="number" min="0"  {...pedidoNoRetirado.bind} className="w-75 form-control "></input>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-8"></div>
                            <div className="col-4">
                                <button type="submit" className="btn btn-primary">Enviar</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-4"></div>
                </div>
            </form>
            <div>{loading && (<Loader />)}</div>

        </div>

    );
}

export default Alertas;