import {
    useState,
    useEffect
} from 'react';
import axios from 'axios';

export default function useGetUsers(clearUsers) {

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [users, setUsers] = useState([])

  
   useEffect(() => {
    setUsers([])
   }, [clearUsers])

    useEffect(() => {
        setLoading(true)
        setError(false)
        let cancel
        axios({
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization' : 'Bearer '+ localStorage.getItem('token'),
                'Glamit-Env': localStorage.getItem('store_id')
            },
            url: localStorage.getItem('url')+'/users',
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then(response => {
            
            if (response.data.length > 0) {
                response.data.map((item, index) => {
                    return setUsers(prevUsers => [...prevUsers, item])
                })
            }
           
            setLoading(false)
        }).catch(e => {
            if (e.response.status === 401||e.response.status===403) {
                window.location.href = window.location.origin+"/login"
            }
            if (axios.isCancel(e)) return
            setError(true)
        })
        return () => cancel()
    }, [clearUsers])

    return {
        loading,
        error,
        users
    }
}