import React, { useState, useContext } from 'react';
import Context from '../../../context';
import Loader from '../../Loader';

const Sidebar = (props) => {
    var role = localStorage.getItem("role").split(',');
    const [loading, setLoading] = useState(false)
    const { setOrdersTeleventa } = useContext(Context)

    function loadPage(page) {
        setLoading(true)
        switch (page) {
            case "OrdersTeleventa":
                allPageToFalse();
                setOrdersTeleventa(true)
                setLoading(false)
                break;
        }

    }

    function allPageToFalse() {
        setOrdersTeleventa(false)
    }
    return (

        <div className="menu-container" id="mySidebar">
            <div className="menu-scroller" id="scroller">

                <div className="filters-container">
                    <div className="filter-container">

                        <div className="filter-options-container">
                            {role.indexOf("ADMIN") !== -1 && (
                                <div className="filter-option">
                                    <div>
                                        <span className="filter-option-text" onClick={() => loadPage("OrdersTeleventa")}>Pedidos de televenta</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div>{loading && (<Loader />)}</div>
        </div>

    );
}

export default Sidebar;
