import React, {useContext, useState} from 'react';
import Context from '../../context';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShoppingCart} from "@fortawesome/free-solid-svg-icons";
import CartModal from '../Modals/CartModal';

function Cart() {
    const {itemsCart, setItemsCart} = useContext(Context);
    const [isOpenModal, setIsOpenModal] = useState(false);

    function showModal(item) {
        setItemsCart(itemsCart);
        setIsOpenModal(true);
    }

    return (
        <Context.Provider value={{itemsCart, setItemsCart, showModal, setIsOpenModal}}>
            {isOpenModal === true &&
                <CartModal itemsCart={itemsCart}/>
            }

            <div>
                <a onClick={showModal.bind(null, itemsCart)}>
                    <div className="d-none d-lg-flex" style={{position: "relative"}}>
                        <FontAwesomeIcon className="text-light mt-3" style={{
                            fontSize: "1rem", marginLeft: "8px",
                            marginRight: "10px",
                            cursor: "pointer"
                        }} icon={faShoppingCart}/>
                        {itemsCart.length > 0 &&
                            <span className="d-block mb-1 badge badge-pill badge-danger"
                                  style={{
                                      marginTop: "0.5rem", fontWeight: "600", position: "absolute",
                                      left: "21px",
                                      padding: "0.2rem 0.32rem 0.26rem !important",
                                      fontSize: "9px"
                                  }}>
                                {itemsCart.length}
                            </span>
                        }
                    </div>
                </a>
                <a onClick={showModal.bind(null, itemsCart)}>
                    <div className="d-md-flex d-lg-none" style={{position: "relative"}}>
                        <FontAwesomeIcon className="text-light" style={{
                            fontSize: "1rem", marginLeft: "8px",
                            marginRight: "10px",
                            cursor: "pointer"
                        }} icon={faShoppingCart}/>
                        {itemsCart.length > 0 &&
                            <span id="cart" className="d-block mb-1 badge badge-pill badge-danger"
                                  style={{
                                      fontWeight: "600", position: "absolute",
                                      left: "21px",
                                      padding: "0.2rem 0.32rem 0.26rem !important",
                                      fontSize: "9px"
                                  }}>
                                {itemsCart.length}
                            </span>
                        }
                    </div>
                </a>
            </div>
        </Context.Provider>
    );


}

export default Cart;