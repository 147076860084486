import React, { useState, useContext, useEffect } from 'react';
import Context from '../../../context';
import Loader from '../../Loader';
import axios from 'axios';

function useInputDescription(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}

const LocationOrderModal = (props) => {
    const { setIsOpenLocationModal } = useContext(Context)
    const { setLocationOrderSend } = useContext(Context)
    const description = useInputDescription('')


    function closeModal() {
        document.getElementsByClassName("modal-backdrop")[0].remove();
        setIsOpenLocationModal(false);
    }
    useEffect(() => {
        const div = document.createElement("div");
        div.classList.add('modal-backdrop');
        document.body.appendChild(div);
    }, [])


    function submitHandler(event) {
        event.preventDefault()
        var order_tosend = {
            channel_id: props.order.assigned_channel_id,
            store_id: localStorage.getItem('store_id'),
            user_email: localStorage.getItem('user_email'),
            order_id: props.order.order_id,
            location: description.value()
        }

        axios({
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Glamit-Env': localStorage.getItem('store_id')
            },
            url: localStorage.getItem('url') + '/order_location_add',
            data: JSON.stringify(order_tosend)
        }).then(function (response) {
            if (response.status === 200) {
                setLocationOrderSend(true)
                closeModal()
            }
        }).catch(e => {
            if (e.response.status === 401 || e.response.status === 403) {
                window.location.href = window.location.origin + "/login"
            } else {
                if (axios.isCancel(e)) return
                alert(e.name + ': ' + e.response.data.message);
            }
        });

    }

    return (

        <div className="modal show" tabIndex="-1" role="dialog" style={{ display: 'block' }} >
            <div className="modal-dialog modal-md" role="document">
                <div className="modal-content">
                    <form onSubmit={submitHandler}>
                        <div className="modal-header">
                            <h5 className="modal-title">
                                Pedido # {props.order.increment_id}</h5>
                            <button className="btn btn-light btn-sm" type="button" data-dismiss="modal" aria-label="Close" onClick={() => closeModal()} >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body table-responsive modal-unassignmentReason">
                            <div className="form-group  ">
                                <label className="float-left control-label" >Ubicación:</label>
                                <textarea className="form-control" type="text" required {...description.bind} rows="4" ></textarea>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-secondary" type="button" data-dismiss="modal" onClick={() => closeModal()}>Cancelar</button>
                            <input type="submit" name="Enviar" className="btn btn-primary" />
                        </div>
                    </form>
                </div>
            </div>
            <div>{props.loadingModal && (<Loader />)}</div>
            <div>{props.errorModal && 'Error'}</div>

        </div>
    );
}

export default LocationOrderModal;