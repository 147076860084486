import {useEffect, useState} from 'react';
import {getItem} from "./utils/storageUtil";
import {_get} from "./utils/apiRequester";

export default function useGetUnassignmentReasons() {

    const [unassignmentReasons, setUnassignmentReasons] = useState([])

    const getUnassigmentReasons = async () => {
        const url = getItem('url')
        return await _get(`${url}/unasignment_reasons`);
    }

    useEffect(() => {
        let texttarea;
        getUnassigmentReasons().then(response => {
            const {data} = response;
            data.map((item, index) => {
                if (item.reason == "Otro:") {
                    texttarea = item;
                } else {
                    return setUnassignmentReasons(prevUnassignmentReasons => [...prevUnassignmentReasons, item])
                }
            })
            if (texttarea !== false) {
                return setUnassignmentReasons(prevUnassignmentReasons => [...prevUnassignmentReasons, texttarea])

            }
        })
    }, [])

    return {unassignmentReasons}
}
