import React, {useContext, useEffect, useState} from 'react';
import moment from 'moment'

import Context from '../../../context';
import Loader from '../../Loader';
import OrderStatus from "../../Rows/OrderTableRow/OrderStatus";

import {
  EXTERNAL_RMA_REQUEST,
  getCurrentOrderState,
  getOmniOrderState,
  GlamitOmsStates,
  RMA_REQUESTED
} from '../../../tools/utils/omsStateUtils';
import {getItem} from "../../../tools/utils/storageUtil";
import {_get} from "../../../tools/utils/apiRequester";

const StatusModal = (props) => {
  const {setIsOpenStatusModal} = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState([]);

  const closeModal = () => {
    setIsOpenStatusModal(false);
    document.getElementsByClassName("modal-backdrop")[0].remove();
  }

  const getStateHistory = async (orderId) => {
    const url = getItem('url');
    return await _get(`${url}/order-history-status?order_id=${orderId}`);
  }

  useEffect(() => {
    const div = document.createElement("div");
    div.classList.add('modal-backdrop');
    document.body.appendChild(div);
    setLoading(true);
    getStateHistory(props.order.order_id)
      .then(response => {
        setLoading(false);
        if (response.status === 200) {
          const omsStateName = GlamitOmsStates.map(s => s.code);
          const rmaRequest = response.data.find(s => s.state === RMA_REQUESTED || s.state === EXTERNAL_RMA_REQUEST);
          const data = response.data.filter(s => omsStateName.includes(s.state));
          if (rmaRequest) {
            data.push(rmaRequest);
          }

          data.sort((a, b) => new Date(a.transition_date) - new Date(b.transition_date));
          setStatus(data);
        }
      }).catch(e => {
      setLoading(false);
      alert(e);
    });
  }, []);

  return (
    <div
      className="modal show"
      tabIndex="-1" role="dialog"
      style={{display: 'block'}}
    >
      <div className="modal-dialog modal-md" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-status">
              <span className="font-weight-light">Pedido:</span> #{props.order.increment_id}
              <br/>
              {(!loading && status.length > 0) &&
                <>
                  <span
                    className="font-weight-light">
                    Estado:</span> <OrderStatus label={getCurrentOrderState(props.order, status)}/>
                </>
              }
            </h5>
            <button
              className="btn btn-light btn-sm" type="button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => closeModal()}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          {!loading &&
            <div className="modal-body table-responsive" style={{textAlign: "left"}}>
              {
                status
                  .map((state, index) => (
                    <div
                      key={index}
                      className="mb-1"
                    >
                      <span className="font-weight-bold">
                        <OrderStatus label={getOmniOrderState(props.order, state)}/>
                      </span> {moment(state.transition_date).add('-3', 'hours').format("DD/MM/YYYY - HH:mm")
                    }
                    </div>
                  ))}
            </div>
          }
        </div>
      </div>
      <div>{loading && (<Loader/>)}</div>
      <div>{props.errorModal && 'Error'}</div>
    </div>
  );
}

export default StatusModal;