import React, { useState, useContext, useEffect } from 'react';
import Context from '../../../context';
import Loader from '../../Loader';
import axios from 'axios';
import useGetChannels from '../../../tools/useGetChannels';
import { MuiThemeProvider } from 'material-ui/styles';
import { Snackbar } from 'material-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

function useInputEmails(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}

function useInputEmailsNotifications(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}

const Notification = (props) => {
    const [loading, setLoading] = useState(false)
    const emails = useInputEmails('')
    const emailsNotifications = useInputEmailsNotifications('')
    const { configData } = useContext(Context)
    const { setClearConfig } = useContext(Context)
    const { clearConfig } = useContext(Context)
    const [openSnackbar, setopenSnackbar] = useState(false)
    const [openSnackbarError, setopenSnackbarError] = useState(false)
    const {
        channels
    } = useGetChannels(localStorage.getItem('store_id'), 1)

    useEffect(() => {
        configData.map((config, index) => {
            if (config.path === "emails") {
                emails.addValue(config.value);
            }
            if(config.path === "emails_notifications"){
                emailsNotifications.addValue(config.value);
            }
        })
    }, [configData])
    function submitHandler(event) {
        event.preventDefault()

        var config_tosend = [
            { path: "emails", value: emails.value(), store_id: localStorage.getItem('store_id') },
            { path: "emails_notifications", value: emailsNotifications.value(), store_id: localStorage.getItem('store_id') }
        ]
        setLoading(true)
        axios({
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Glamit-Env': localStorage.getItem('store_id')
            },
            url: localStorage.getItem('url') + '/config_data',
            data: config_tosend
        }).then(function (response) {
            if (response.status === 200) {
                setClearConfig(clearConfig => clearConfig + 1)
                setopenSnackbar(true);
            }
            setLoading(false)
        }).catch(e => {
            if (e.response.status === 401 || e.response.status === 403) {
                setopenSnackbarError(true);
                window.location.href = window.location.origin + "/login"
            }
        });


    }
    return (

        <div className="main-container" id="main">
            <MuiThemeProvider>
                <Snackbar className="pt-3" bodyStyle={{ backgroundColor: '#ffffff' }}
                    style={{ height: "100%", left: "85%", paddingBottom: "75%" }}
                    anchororigin={{ vertical: 'top', horizontal: 'right' }}
                    message={<div  ><FontAwesomeIcon className="text-success mr-1" icon={faCheck} /> <span style={{ fontWeight: "500" }} className="text-black-50">Configuración guardada!</span></div>}
                    open={openSnackbar}
                    autoHideDuration={2000}
                >
                </Snackbar>
            </MuiThemeProvider>
            <MuiThemeProvider>
                <Snackbar className="pt-3" bodyStyle={{ backgroundColor: '#ffffff' }}
                    style={{ height: "100%", left: "85%", paddingBottom: "75%" }}
                    anchororigin={{ vertical: 'top', horizontal: 'right' }}
                    message={<div  ><FontAwesomeIcon className="text-danger mr-1" icon={faTimes} /> <span style={{ fontWeight: "500" }} className="text-black-50">Error al guardar!</span></div>}
                    open={openSnackbarError}
                    autoHideDuration={2000}
                >
                </Snackbar>
            </MuiThemeProvider>
            <div className="title">Notificaciones</div>

            <form onSubmit={submitHandler}>
                <div class="row mt-3">
                    <div className="col-lg-2 col-md-4"></div>
                    <div className="col-lg-10 col-md-8">
                        <div className="row mt-3">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12">
                                        <span className="float-left" style={{ verticalAlign: "sub" }}>Emails que reciben alertas:</span>
                                    </div>
                                    <div className="col-12">
                                        <input className="form-control form-control-sm w-100" autoComplete="new-password" type="text" placeholder="Ingresar emails separados por coma."   {...emails.bind} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12">
                                        <span className="float-left" style={{ verticalAlign: "sub" }}>Notificaciones Glamit:</span>
                                    </div>
                                    <div className="col-12">
                                        <input className="form-control form-control-sm w-100" autoComplete="new-password" type="text" placeholder="Ingresar emails separados por coma."   {...emailsNotifications.bind} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-12 text-center">
                                <input type="submit" name="Edit" className="btn btn-primary" />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div>{loading && (<Loader />)}</div>

        </div>
    );
}

export default Notification;