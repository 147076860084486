import React, { useState } from 'react';
import Role from '../../tools/role';
import useGetChannels from '../../tools/useGetChannels';
import Loader from '../Loader';
import Nav from '../Nav';
import LocalesTableRow from '../Rows/LocalesTableRow';
import axios from 'axios';
import Context from '../../context'

function useInputHabilitado(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    const [checked, setChecked] = useState(false)
    return {
        bind: {
            value,
            onChange: event => setChecked(event.target.checked),
            checked: checked
        },
        clear: () => setValue(''),
        value: () => checked,
        addValue: (val) => setValue(val),
        addChecked: (arg) => setChecked(arg)
    }
}
function useInputMaxAssignationsIlimitado(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    const [checked, setChecked] = useState(false)
    return {
        bind: {
            value,
            onChange: event => setChecked(event.target.checked),
            checked: checked
        },
        clear: () => setValue(''),
        value: () => checked,
        addValue: (val) => setValue(val),
        addChecked: (arg) => setChecked(arg)
    }
}

function useInputMaxAssignations(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}
function useInputSecurityStock(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}

const Locales = () => {

    if (localStorage.getItem('token') === null || localStorage.getItem('token') === 'null') {
        window.location.href = window.location.origin
    }
    Role(["ADMIN", "ADMINMARCA"])

    const [store, setStore] = useState(localStorage.getItem('store_id'))
    const [clearChanel, setClearChanel] = useState(1)
    const [isOpenModal, setIsOpenModal] = useState(false)
    const Habilitado = useInputHabilitado('')
    const MaxAssignations = useInputMaxAssignations('')
    const SecurityStock = useInputSecurityStock('')
    const MaxAssignationsIlimitado = useInputMaxAssignationsIlimitado(false)
    const [textModal, setTextModal] = useState('')
    const [channelModificar, setchannelModificar] = useState(null)
    const [loadingEdit, setLoadingEdit] = useState(false)

    function clearToStart() {
        setClearChanel(clearChanel => clearChanel + 1)
    }
    const {
        channels,
        loading,
        error
    } = useGetChannels(store, clearChanel)

    function submitHandler(event) {
        setIsOpenModal(false)
        event.preventDefault()
        var action = true;

        if (Habilitado.value() === true) {
            var checked_input = 1;
        } else {
            var checked_input = 0;
        }

        channelModificar.habilitado = checked_input;

        if (MaxAssignationsIlimitado.value() === true) {
            channelModificar.max_assignations = -1;
        } else {
            channelModificar.max_assignations = +MaxAssignations.value();
        }

        channelModificar.security_stock = +SecurityStock.value();
        var channel_tosend = channelModificar;
        setLoadingEdit(true)
        if (action === true) {
            axios({
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Glamit-Env': localStorage.getItem('store_id')
                },
                url: localStorage.getItem('url') + '/channels/setchanel',
                data: channel_tosend
            }).then(function (response) {
                if (response.status === 200) {
                    closePopup()
                    clearToStart();
                }
                setLoadingEdit(false);
            }).catch(e => {
                if (e.response.status === 401 || e.response.status === 403) {
                    window.location.href = window.location.origin + "/login"
                }
            });
        }
    }

    function closePopup() {
        setIsOpenModal(false);
    }

    function showModal(channel) {
        setchannelModificar(channel);
        setTextModal(channel.channel_name)
        if (channel.max_assignations === -1) {
            MaxAssignationsIlimitado.addValue(true)
            MaxAssignationsIlimitado.addChecked(true)
            MaxAssignations.clear()

        } else {
            MaxAssignationsIlimitado.addValue(false)
            MaxAssignationsIlimitado.addChecked(false)
            MaxAssignations.addValue(channel.max_assignations)
        }

        SecurityStock.addValue(channel.security_stock)
        if (channel.habilitado === true) {
            Habilitado.addChecked(true)
        } else {
            Habilitado.addChecked(false)
        }
        setIsOpenModal(true);
    }



    return (
        <Context.Provider value={{ clearToStart, showModal }}>
            <div>
                {isOpenModal === true &&
                    <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block', backgroundColor: "rgba(0,0,0,0.7)" }}>
                        <div className="modal-dialog modal-sm" role="document">
                            <form onSubmit={submitHandler}>
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">{textModal}</h5>
                                        <button className="close" type="button" data-dismiss="modal" aria-label="Close" onClick={() => closePopup()} >
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">

                                        <div className="form-row h-50">
                                            <div className="form-group col-md-10 ">
                                                <label className=" control-label" >Máximo de asignaciones</label>

                                                <div className="">
                                                    <div className="custom-control custom-checkbox p-0">
                                                        <label className="control-label" >Ilimitado</label>
                                                        <label className="switch mb-0 ml-3">
                                                            <input type="checkbox" {...MaxAssignationsIlimitado.bind} />
                                                            <span className="slider round"></span>
                                                        </label>
                                                        {!MaxAssignationsIlimitado.value() &&
                                                            <input className="col-md-4  form-control" type="number"  {...MaxAssignations.bind} />}
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="form-group col-md-10">
                                                <label className="control-label" >Stock de seguridad</label>
                                                <input className="form-control" type="number" {...SecurityStock.bind} />
                                            </div>
                                            <div className="form-group col-md-10 form-inline">
                                                <label className="control-label" >Habilitado</label>
                                                <div className="col-md-2 ">

                                                    <div className="custom-control custom-checkbox p-0">
                                                        <label className="switch mb-0">

                                                            <input type="checkbox" {...Habilitado.bind} />
                                                            <span className="slider round"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="modal-footer">
                                        <button className="btn btn-secondary" type="button" data-dismiss="modal" onClick={() => closePopup()}>Cancelar</button>
                                        <input type="submit" name="Edit" className="btn btn-primary" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                }
                <div className="page-header">
                    <Nav />
                </div>
                <div className="container">
                    <div className="card mt-1 mb-1 border-0">
                        <div className="card-header pb-3 bg-white border-0">
                            <div className="row">
                                <div className="float-left col-12">
                                    <h2 style={{ width: '100%' }}>Locales</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="card-body m-3">
                                    <table className="table table-responsive-sm" style={{ tableLayout: "auto" }}>
                                        <thead className="bg-light ">
                                            <tr style={{ fontSize: "0.9rem" }}>
                                                <th scope="col" className="pt-2 pb-2 border-0" >Local</th>
                                                <th scope="col" className="pt-2 pb-2 border-0 text-center" >Máximo Asignaciones</th>
                                                <th scope="col" className="pt-2 pb-2 border-0 text-center" >Stock de Seguridad</th>
                                                <th scope="col" className="pt-2 pb-2 border-0 text-center" >Habilitado</th>
                                                <th scope="col" className="pt-2 pb-2 border-0 text-right" ></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {channels.map((channel, index) => {
                                                return <LocalesTableRow channel={channel} key={index} index={index} />
                                            })}
                                        </tbody>
                                    </table>
                                    {loadingEdit &&
                                        <div>(<Loader />)</div>
                                    }
                                    {loading &&
                                        <div>(<Loader />)</div>
                                    }
                                    <div>{error && 'Error'}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </Context.Provider>
    )
}
export default Locales;
