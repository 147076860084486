import React, {useEffect, useState} from 'react';
import Context from '../../context';
import Nav from '../Nav';
import Role from '../../tools/role';
import Sidebar from './include/Sidebar';

import OrdersTeleventa from './include/OrdersTeleventa';

function Reportes() {
    if (localStorage.getItem('token') === null || localStorage.getItem('token') === 'null') {
        window.location.href = window.location.origin + "/login"
    }

    Role(["ADMIN", "ADMINMARCA"])
    const style = window.document.createElement("link");
    style.href = process.env.PUBLIC_URL + "/css/Configuration.css";
    style.rel = "stylesheet";
    style.async = true;
    window.document.head.appendChild(style);

    const [ordersTeleventa, setOrdersTeleventa] = useState(false)


    useEffect(() => {
        setOrdersTeleventa(true)
    }, [])
    return (
        <Context.Provider value={{setOrdersTeleventa}}>
            <div className="page-header">
                <Nav/>
            </div>
            <div className="page-container">
                <Sidebar/>
                {ordersTeleventa && (<OrdersTeleventa/>)}
            </div>
        </Context.Provider>
    );
}

export default Reportes
