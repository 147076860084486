import React, { useState, useEffect } from 'react';
import Chart from "react-google-charts";
import moment from 'moment'

const DeliveredGraf = (props) => {
    const [orders, setOrders] = useState([])

    useEffect(() => {
        if (props.data.fechas !== undefined) {
            var msDiff = new Date(props.dateTo).getTime() - new Date(props.dateFrom).getTime();    //Future date - current date
            var diff = Math.floor(msDiff / (1000 * 60 * 60 * 24));
            var i;
            var data = [['Fecha', 'Ordens']]
            for (i = 0; i < diff; i++) {
                data[i + 1] = [moment(props.dateFrom).add(i, 'day').format("YYYY-MM-DD"), parseInt(getOrderByDay(props.data.fechas, moment(props.dateFrom).add(i, 'day').format("YYYY-MM-DD")))]
            }
            setOrders(data)
        }
    }, [props.data.fechas])

    function getOrderByDay(data, fecha) {
        var total = 0;
        data.map((item, index) => {
            if (item.fecha_entregado.indexOf(fecha) >= 0) {
                total = item.total_channel;
            }
        })
        return total;
    }
    return (
        <div className="card-container">
            <div className="card-border">
                <div className="card-title-text">
                    Pedidos Omnichannel (Asignados)
                </div>
                <div className="charts-daterange">{moment(props.dateFrom).format("DD/MM/YYYY")} - {moment(props.dateTo).format("DD/MM/YYYY")}</div>
                <div className="chart-container" >

                    <Chart
                        width={'100%'}
                        height={'300'}
                        chartType="LineChart"
                        loader={<div>Loading Chart</div>}
                        data={orders}
                    />
                </div>

            </div>
        </div>

    );
}

export default DeliveredGraf;
