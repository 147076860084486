import React, { useContext } from 'react';
import '../LocalesTableRow/switch.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import Context from '../../../context';

const LocalesTableRow = React.forwardRef((props, ref) => {

    const { showModal } = useContext(Context)


    return (
        <tr className="flex-wrap align-content-center justify-content-center">
            <td >{props.channel.channel_name}</td>
            <td className="text-center">
                {props.channel.max_assignations=== -1 ?
                 <span className="badge badge-pill badge-warning" style={{ fontSize: "0.7rem" }}>Ilimitado</span>:
                 props.channel.max_assignations
                 }</td>
            <td className="text-center">
                {props.channel.security_stock }
                </td>
            <td className="text-center" >
                {props.channel.habilitado  &&
                    <span className="badge badge-pill badge-success" style={{ fontSize: "0.7rem" }}>Si</span>
                }
                {!props.channel.habilitado &&
                    <span className="badge badge-pill badge-danger" style={{ fontSize: "0.7rem" }}>No</span>
                }

            </td>
            <td className="text-right">
                <button type="button" className="btn btn-primary btn-sm" onClick={showModal.bind(null, props.channel)}>
                <FontAwesomeIcon className="mr-1" icon={faEdit} /> Editar</button>
            </td>
        </tr >
    );
})
export default LocalesTableRow;