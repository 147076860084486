import React, { useState, useEffect } from 'react';
import Context from '../../context'
import Loader from '../Loader/LoaderMetrics';
import Nav from '../Nav';
import Role from '../../tools/role';
import Sidebar from '../Metrics/include/Sidebar';
import DeliveredCount from '../Metrics/include/DeliveredCount';
import DeliveredByChannel from '../Metrics/include/DeliveredByChannel';
import DeliveredGraf from '../Metrics/include/DeliveredGraf';
import PreparationTime from '../Metrics/include/PreparationTime';
import OrdersUnassignment from '../Metrics/include/OrdersUnassignment';
import UnassignmentReasons from '../Metrics/include/UnassignmentReasons';
import useGetPreparationtime from '../../tools/useGetPreparationtime';
import useGetOrdersDelivered from '../../tools/useGetOrdersDelivered';
import useGetUnassignedOrders from '../../tools/useGetUnassignedOrders';
import useGetOrdersPrepared from '../../tools/useGetOrdersPrepared';
import html2canvas from "html2canvas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import OrdersPrepared from './include/OrdersPrepared';

function Metrics() {

    if (localStorage.getItem('token') === null || localStorage.getItem('token') === 'null') {
        window.location.href = window.location.origin
    }

    Role(["ADMIN","ADMINMARCA"])
    const style = document.createElement("link");
    style.href = process.env.PUBLIC_URL + "/css/Metrics.css";
    style.rel = "stylesheet";
    style.async = true;

    document.head.appendChild(style);

    
    const [mySidebar, setMySidebar] = useState(false)
    const [clearMetrics, setClearMetrics] = useState(1)
    var date = new Date();
    date.setDate(date.getDate() - 7);
    var date_to = new Date();
    date_to.setDate(date_to.getDate() + 1);
    const [defdateFrom, setDefdateFrom] = useState(date.toISOString().slice(0, 10))
    const [defdateTo, setDefdateTo] = useState(date_to.toISOString().slice(0, 10))


    const {
        preparationTime,
        loading1,
        error
    } = useGetPreparationtime(defdateFrom, defdateTo, clearMetrics)
    const {
        ordersDelivered,
        loading2,
        error2
    } = useGetOrdersDelivered(defdateFrom, defdateTo, clearMetrics)
    const {
        unassignedOrders,
        loading3,
        error3
    } = useGetUnassignedOrders(defdateFrom, defdateTo, clearMetrics)
    const { ordersPrepared, loading4, error4} = useGetOrdersPrepared(defdateFrom, defdateTo, clearMetrics)


    useEffect(() => {
        if (mySidebar) {
            window.document.getElementById("mySidebar").style.width = "250px";
            // document.getElementById("mySidebar").style.padding = "12px 10px";
            window.document.getElementById("main").style.marginLeft = "270px";
        } else {
            window.document.getElementById("mySidebar").style.width = "0";
            // document.getElementById("mySidebar").style.padding = "12px 0";
            window.document.getElementById("main").style.marginLeft = "10px";
        }
    }, [mySidebar])
    function clearToStart() {
        setClearMetrics(clearMetrics => clearMetrics + 1)
    }
    function openNav() {
        setMySidebar(true)
    }

    function closeNav() {
        setMySidebar(false)
    }

    const printPDF = () => {
        //En "tabla" va el id del elemento html que contiene lo que se va a imprimir
            const domElement = document.getElementById("main");
          
            html2canvas(domElement, {
              allowTaint: true,
              useCORS: true,
              height: 3200,
              windowHeight:3200,
              onclone: document => {
                  //"print" es el id del boton esto es para ocultarlo y que no salga en la impresión
                document.getElementById("print").style.visibility = "hidden";
              }
            }).then(canvas => {
              const imgData = canvas.toDataURL("image/png");
              /*const pdf = new jsPdf("p", "mm", "a4");
              var width = pdf.internal.pageSize.getWidth();
             // var height = pdf.internal.pageSize.getHeight();
              pdf.addImage(imgData, "JPEG", 0, 0, width, 0);
              pdf.save(`${new Date().toISOString()}.pdf`);*/
              saveAs(canvas.toDataURL(), new Date().toISOString()+'.png');
            });
          };
          function saveAs(uri, filename) {
            var link = document.createElement('a');
            if (typeof link.download === 'string') {
              link.href = uri;
              link.download = filename;
    
              //Firefox requires the link to be in the body
              document.body.appendChild(link);
    
              //simulate click
              link.click();
    
              //remove the link when done
              document.body.removeChild(link);
            } else {
              window.open(uri);
            }
          }
    /*
        // NUMBER ANIMATION
        // How long you want the animation to take, in ms
        const animationDuration = 700;
        // Calculate how long each ‘frame’ should last if we want to update the animation 60 times per second
        const frameDuration = 1000 / 60;
        // Use that to calculate how many frames we need to complete the animation
        const totalFrames = Math.round(animationDuration / frameDuration);
        // An ease-out function that slows the count as it progresses
        const easeOutQuad = t => t * (2 - t);
    
        // The animation function, which takes an Element
        const animateProgress = el => {
            el.style.width = '90%';
        };
    
        const animateCountUp = el => {
            let frame = 0;
            const countTo = parseInt(el.innerHTML.toString().replace('.', ''), 10);
            // Start the animation running 60 times per second
            const counter = setInterval(() => {
                frame++;
                // Calculate our progress as a value between 0 and 1
                // Pass that value to our easing function to get our
                // progress on a curve
                const progress = easeOutQuad(frame / totalFrames);
                // Use the progress value to calculate the current count
                const currentCount = countTo * progress;
    
                // If the current count has changed, update the element
                if (parseInt(el.innerHTML, 10) !== currentCount) {
                    el.innerHTML = numberWithDots(currentCount);
                    // el.innerHTML = numberWithComma(currentCount);
                }
    
                // If we’ve reached our last frame, stop the animation
                if (frame === totalFrames) {
                    clearInterval(counter);
                }
            }, frameDuration);
        };
    
        // Run the animation on all elements with a class of ‘countup’
        const runAnimations = () => {
            const countupEls = document.querySelectorAll('.countup');
            const progressEls = document.querySelectorAll('.progressbar');
            countupEls.forEach(animateCountUp);
            progressEls.forEach(animateProgress);
        };
    
        function numberWithDots(x) {
            return Math.round(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }
    
        function numberWithComma(x) {
            return x.toFixed(1).replace(".", ",");
        }
        // END NUMBER ANIMATION
    */
    return (
        <Context.Provider value={{ closeNav, setDefdateFrom, setDefdateTo, clearToStart }}>

            <div className="page-header">
                <Nav />
            </div>
            <div className="page-container">
                <Sidebar dateFrom={defdateFrom} dateTo={defdateTo}/>
                <div className="main-container" id="main">
                    <div className="title">Métricas Omnichannel
                    <button id="print" type="button" className="btn btn-primary" onClick={printPDF} >
          <FontAwesomeIcon className="text-white mr-1" icon={faDownload} />
                            Descargar
                            </button>
                            </div>
                    
                    <div className="actions-container">
                        <a className="openbtn" onClick={openNav}>☰ Ver filtros</a>
                    </div>
                    
                    <div className="grid-container-3cols">
                        {loading2 ? (<DeliveredCount data={ordersDelivered} dateFrom={defdateFrom} dateTo={defdateTo} />) : (
                            <Loader />
                        )}
                        {loading2 ? (<DeliveredByChannel data={ordersDelivered} dateFrom={defdateFrom} dateTo={defdateTo} />) : (
                            <Loader />
                        )}
                        {loading2 ? (<DeliveredGraf data={ordersDelivered} dateFrom={defdateFrom} dateTo={defdateTo} />) : (
                            <Loader />
                        )}
                    </div>
                    <div className="grid-container-3cols">
                        {loading1 ? (<PreparationTime data={preparationTime} dateFrom={defdateFrom} dateTo={defdateTo} />) : (
                            <Loader />
                        )}
                        {!loading3 ? (<OrdersUnassignment data={unassignedOrders} dateFrom={defdateFrom} dateTo={defdateTo} />) : (
                            <Loader />
                        )}
                        {!loading3 ? (<UnassignmentReasons data={unassignedOrders} dateFrom={defdateFrom} dateTo={defdateTo} />) : (
                            <Loader />
                        )}
                    </div>
                    <div className="grid-container-3cols">
                        {loading4 ? (<OrdersPrepared data={ordersPrepared} dateFrom={defdateFrom} dateTo={defdateTo}/>):(
                            <Loader/>
                        )}                        
                    </div>
                </div>
            </div>
        </Context.Provider>
    );
}

export default Metrics;
