import React, { useState, useContext, useEffect } from 'react';
import Context from '../../../context';
import useGetUnasigmentReasonsStore from '../../../tools/useGetUnasigmentReasonsStore';
import Loader from '../../Loader/LoaderMetrics';
import axios from 'axios';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


const UnassignmentReasonsStoreModal = (props) => {
    const { setIsOpenModalUnasigmentReasons } = useContext(Context)

    const { loading,
        error,
        unasigmentReasons,setUnasignmentReasons } = useGetUnasigmentReasonsStore(props.store)
    const [urList,setUrList]=useState([])
    
    useEffect(() => {
        var list= new Array();
        unasigmentReasons.map((ur, index) => { 
            list.push(ur.enable)
        })
        setUrList(list)
    }, [unasigmentReasons])

    const handleChange = (event) => {
        let item = unasigmentReasons[event.target.name]
        item.enable=event.target.checked
        setUnasignmentReasons([...unasigmentReasons],{[event.target.name]:item})
    };
   
    function closeModal() {
        document.getElementsByClassName("modal-backdrop")[0].remove();
        setIsOpenModalUnasigmentReasons(false);
    }
    useEffect(() => {
        const div = document.createElement("div");
        div.classList.add('modal-backdrop');
        document.body.appendChild(div);
    }, [])

    function submitHandler(event) {
        event.preventDefault()
        unasigmentReasons.map((ur, index) => { 
             unasigmentReasons[index].enable=urList[index];
        })
        setUnasignmentReasons(unasigmentReasons)

        axios({
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Glamit-Env': props.store.store_id
            },
            url:localStorage.getItem('url')+'/unasignment_reasons_store',
            data: unasigmentReasons
        }).then(function (response) {
            if (response.status === 200) {
                closeModal()
            }
        }).catch(e => {
            if (e.response.status === 401 || e.response.status === 403) {
                window.location.href = window.location.origin + "/login"
            } else {
                if (axios.isCancel(e)) return
                alert(e.name + ': ' + e.response.data.message)
            }
        });

    }

    return (

        <div className="modal show" tabIndex="-1" role="dialog" style={{ display: 'block' }} >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <form onSubmit={submitHandler}>
                        <div className="modal-header">
                            <h5 className="modal-title">Razones de desasignación Marca {props.store.storecode}
                            </h5>
                            <button className="btn btn-light btn-sm" type="button" data-dismiss="modal" aria-label="Close" onClick={() => closeModal()} >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>

                        <div className="modal-body table-responsive modal-unassignmentReason">
                        {loading && (<Loader />)}
                            <FormGroup row>
                                {unasigmentReasons.map((ur, index) => {
                                    return <FormControlLabel key={index}
                                        style={{  width: "100%" }}
                                        label={ur.reason}
                                        control={<Checkbox checked={ur.enable} onChange={handleChange} name={index.toString()} color="primary" />}

                                    />
                                })}

                            </FormGroup>

                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-secondary" type="button" data-dismiss="modal" onClick={() => closeModal()}>Cancelar</button>
                            <input type="submit" name="Enviar" className="btn btn-primary" />
                        </div>
                    </form>
                </div>
            </div>
            <div>{error && 'Error'}</div>
        </div>
    );
}

export default UnassignmentReasonsStoreModal;