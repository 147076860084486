import { useState, useEffect } from 'react';
import axios from 'axios';

export default function useGetVtexQuote(quoteVtex, items, codigovendedor) {
  const [urlVtexCart, seturlVtexCart] = useState('');
  const [orderFormId, setorderFormId] = useState('');
  const [loading, setloading] = useState(false);

  useEffect(() => {
    if (quoteVtex) {
      let cancel;
      setloading(true);
      axios({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          'Glamit-Env': localStorage.getItem('store_id'),
        },
        params: {
          codigoVendedor: codigovendedor,
          seller_id: localStorage.getItem('seller_id'),
        },
        data: items,
        url: localStorage.getItem('url') + '/createQuoteVtex',
        cancelToken: new axios.CancelToken((c) => (cancel = c)),
      })
        .then((response) => {
          if (response.status === 200) {
            setorderFormId(response.data.orderFormId);
            seturlVtexCart(response.data.url);
            setloading(false);
          }
        })
        .catch((e) => {
          setloading(false);
          console.error(e);
          alert(
            'No se puede armar el carrito vtex. Por favor, intente más tarde o contacte al administrador',
          );
        });
      return () => cancel();
    }
  }, [quoteVtex]);

  return {
    urlVtexCart,
    orderFormId,
    loading,
  };
}
