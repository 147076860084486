import React, { useState, useRef, useCallback } from 'react';
import Context from '../../context'
import TableRowPickup from '../Rows/TableRowPickup';
import Nav from '../Nav';
import Loader from '../Loader';
import useGetPickup from '../../tools/useGetPickup';
import Role from '../../tools/role';

function useInputDateFrom(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)

    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value
    }
}
function useInputDateTo(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)

    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value
    }
}


function Pickup() {

    if (localStorage.getItem('token') === null || localStorage.getItem('token') === 'null') {
        window.location.href = window.location.origin
    }
    Role(["ADMIN"])

    const [pageNumber, setPageNumber] = useState(1)
    const [clearOrder, setClearOrder] = useState(1)
    var date = new Date();
    date.setDate(date.getDate() - 365);
    var date_to = new Date();
    date_to.setDate(date_to.getDate() + 1);
    const [defdateFrom, setDefdateFrom] = useState(date.toISOString().slice(0, 10))
    const [defdateTo, setDefdateTo] = useState(date_to.toISOString().slice(0, 10))
    const DateFrom = useInputDateFrom(defdateFrom)
    const DateTo = useInputDateTo(defdateTo)

    function clearToStart() {
        setPageNumber(pageNumber => 1)
        setClearOrder(clearOrder => clearOrder + 1)
    }

    const {
        orders,
        hasMore,
        loading,
        error
    } = useGetPickup(pageNumber, defdateFrom, defdateTo, 50, clearOrder)

    const observer = useRef()
    const lastOrderElementRef = useCallback(node => {

        if (loading) return
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore === false) {
                setPageNumber(pageNumber => pageNumber + 1)
            }
        })
        if (node) observer.current.observe(node)
    }, [loading, hasMore])

    function submitHandler(event) {
        event.preventDefault()
        setDefdateFrom(defdateFrom => DateFrom.value())
        setDefdateTo(defdateTo => DateTo.value())
        clearToStart()
    }

    return (
        <Context.Provider value={{ clearToStart }}>
             <div className="page-header">
        <Nav />
      </div>
            <div className="container">
                <div className="card mt-3">
                    <div className="card-header">
                        <form onSubmit={submitHandler}>
                            <div className="row float-left col-6">
                                <h3>Pedidos pickup no asignables</h3>
                            </div>
                            <div className="row float-right col-6">
                                <div className="col-5">
                                    <input type="text" className="form-control" placeholder="Date From" {...DateFrom.bind} /></div>
                                <div className="col-5">
                                    <input type="text" className="form-control" placeholder="Date To" {...DateTo.bind} />
                                </div>
                                <div className="col-2">
                                    <button type='submit' className="btn btn-primary">Search</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="card-body">
                        <table className="table mt-2">
                            <thead className="thead-dark">
                                <tr>
                                    <th scope="col">Creado</th>
                                    <th scope="col">Id Orden</th>
                                    <th scope="col">Inc. Id</th>
                                    <th scope="col">Total</th>
                                    <th scope="col">CP</th>
                                    <th scope="col">Skus</th>
                                    <th scope="col">Cant.</th>
                                    <th scope="col">Envío</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orders.map((order, index) => {
                                    if (orders.length === index + 1) {
                                        return <TableRowPickup ref={lastOrderElementRef} order={order} key={index} index={index} />

                                    } else {
                                        return <TableRowPickup order={order} key={index} index={index} />
                                    }
                                })}
                            </tbody>
                        </table>
                        <div>{loading && (<Loader />)}</div>
                        <div>{error && 'Error'}</div>
                    </div>  </div>
            </div>
        </Context.Provider>
    );
}

export default Pickup;
