import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';

import Context from '../../../context';
import Loader from '../../Loader';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const CustomModal = (props) => {
  const { setIsCustomModalOpen } = useContext(Context);
  const { configData } = useContext(Context);

  const [locationOrder, setLocationOrder] = useState(false);
  const [clientData, setClientData] = useState(null);

  function closeModal() {
    document.getElementsByClassName('modal-backdrop')[0].remove();
    setLocationOrder(false);
    setIsCustomModalOpen(false);
  }

  useEffect(() => {
    const div = document.createElement('div');
    div.classList.add('modal-backdrop');
    document.body.appendChild(div);
    if (showLocation(configData) === true) {
      axios({
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Glamit-Env': localStorage.getItem('store_id'),
        },
        url:
          localStorage.getItem('url') +
          '/order_location/' +
          props.order.order_id,
      }).then(function (response) {
        if (response.status === 200) {
          setLocationOrder(response.data.location);
        }
      });
    }
  }, []);

  function showLocation(config) {
    return config.some(
      (element) =>
        element.path === 'save_order_location' && element.value === '1',
    );
  }

  function ucWords(str) {
    return str.toLowerCase().replace(/(?<= )[^\s]|^./g, (a) => a.toUpperCase());
  }

  function handleClick() {
    setClientData({
      email: props.order.customer_email,
      telephone: props.order.customer_phone,
    });
  }

  return (
    <div
      className='modal show'
      tabIndex='-1'
      role='dialog'
      style={{ display: 'block' }}
    >
      <div className='modal-dialog modal-lg' role='document'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5>Pedido #{props.order.increment_id}</h5>
            <button
              className='btn btn-light btn-sm'
              type='button'
              data-dismiss='modal'
              aria-label='Close'
              onClick={() => closeModal()}
            >
              <span aria-hidden='true'>×</span>
            </button>
          </div>

          <div className='modal-body table-responsive'>
            <h5>Compró:</h5>

            <span className='d-block mb-1'>
              Nombre:{' '}
              {`${ucWords(props.order.customer_name)} ${ucWords(
                props.order.customer_lastname,
              )}`}
            </span>

            <span className='d-block mb-1'>
              DNI: {props.order.customer_document}
            </span>

            {locationOrder && (
              <div className='mb-2'>
                <h5>Ubicación de Pedido:</h5>
                <span className='d-block mb-1'>{locationOrder}</span>
              </div>
            )}

            {clientData && (
              <div>
                <span className='d-block mb-1'>Email: {clientData.email}</span>
                <span className='d-block mb-1'>
                  Teléfono: {clientData.telephone}
                </span>
              </div>
            )}

            {props.order?.recipient_full_name && (
              <div className='mb-2'>
                <h5>Retira:</h5>
                <span className='d-block mb-1'>
                  Nombre: {ucWords(props.order.recipient_full_name)}
                </span>
                {props.order.friend_document !== null && (
                  <span className='d-block mb-1'>
                    DNI: {props.order.friend_document}
                  </span>
                )}
              </div>
            )}

            <div className='text-center'>
              {!clientData && (
                <div className='spinner-border text-primary mt-2' role='status'>
                  <span className='sr-only'>Cargando...</span>
                </div>
              )}
            </div>

            {!clientData && (
              <button
                type='button'
                className='btn btn-primary btn-sm'
                onClick={handleClick}
              >
                <FontAwesomeIcon className='mr-1 text-white' icon={faSearch} />{' '}
                Ver más
              </button>
            )}
          </div>
        </div>
      </div>

      <div>{props.loadingModal && <Loader />}</div>

      <div>{props.errorModal && 'Error'}</div>
    </div>
  );
};

export default CustomModal;