import React, { useState } from 'react';

import Context from '../../../context';
import SkuModal from '../../Modals/SkuModal';

function SkuContainer(props) {
  const [isOpenSkuModal, setIsOpenSkuModal] = useState(false);
  const sku = props.sku;

  function closePopup() {
    setIsOpenSkuModal(false);
  }

  function showModal() {
    setIsOpenSkuModal(true);
  }

  return (
    <Context.Provider value={{ setIsOpenSkuModal }}>
      {isOpenSkuModal === true && <SkuModal sku={sku} />}

      <div className='sku-container'>
        <div className='display-table'>
          <div className='check-container cant'>
            {sku.qty}
            <span className='x-size'>x</span>
          </div>
        </div>
        <div className='sku-desc' onClick={showModal}>
          <div className='sku-code'>
            {sku.sku},
            <span className='sku-attrs'>
              Color: {sku.sku_color}, Talle: {sku.sku_size}
            </span>
          </div>
          <div className='sku-name'>{sku.sku_name}</div>
        </div>
      </div>
    </Context.Provider>
  );
}

export default SkuContainer;
