export function getBillingApiBaseUrl() {
  if (
    window.location.href.indexOf('.uat') > 0 ||
    window.location.href.indexOf('localhost') > 0
  ) {
    return 'https://api.billing.uat.glamit.com.ar';
  }

  return 'https://api.billing.glamit.com.ar';
}
