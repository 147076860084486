import React, { useState, useContext, useEffect } from 'react';
import Context from '../../../context';
import Loader from '../../Loader';
import axios from 'axios';
function useInputUseScp(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}
function useInputUseSftp(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}
function useInputHost(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}
function useInputUser(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}
function useInputPassInput(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}
function useInputPort(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}
function useInputPathurl(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}
function useInputPassive(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}

const FtpGroup = (props) => {
    const [loading, setLoading] = useState(false)
    const useScp = useInputUseScp('')
    const useSftp = useInputUseSftp('')
    const host = useInputHost('')
    const user = useInputUser('')
    const passInput = useInputPassInput('')
    const port = useInputPort('')
    const pathurl = useInputPathurl('')
    const passive = useInputPassive('')

    const { configData } = useContext(Context)
    const { setClearConfig } = useContext(Context)
    const { clearConfig } = useContext(Context)


    useEffect(() => {
        configData.map((config, index) => {
            if (config.path === "use_scp") {
                useScp.addValue(config.value)
            }
            if (config.path === "use_sftp") {
                useSftp.addValue(config.value)
            }
            if (config.path === "host") {
                host.addValue(config.value)
            }
            if (config.path === "user") {
                user.addValue(config.value)
            }
            if (config.path === "pass_input") {
                passInput.addValue(config.value)
            }
            if (config.path === "port") {
                port.addValue(config.value)
            }
            if (config.path === "pathurl") {
                pathurl.addValue(config.value)
            }
            if (config.path === "passive") {
                passive.addValue(config.value)
            }

        })
    }, [configData])
    function submitHandler(event) {
        event.preventDefault()

        var config_tosend = [
            { path: "use_scp", value: useScp.value(), store_id: localStorage.getItem('store_id') },
            { path: "use_sftp", value: useSftp.value(), store_id: localStorage.getItem('store_id') },
            { path: "host", value: host.value(), store_id: localStorage.getItem('store_id') },
            { path: "user", value: user.value(), store_id: localStorage.getItem('store_id') },
            { path: "pass_input", value: passInput.value(), store_id: localStorage.getItem('store_id') },
            { path: "port", value: port.value(), store_id: localStorage.getItem('store_id') },
            { path: "pathurl", value: pathurl.value(), store_id: localStorage.getItem('store_id') },
            { path: "passive", value: passive.value(), store_id: localStorage.getItem('store_id') },
        ]
        setLoading(true)
        axios({
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Glamit-Env': localStorage.getItem('store_id')
            },
            url: localStorage.getItem('url') + '/config_data',
            data: config_tosend
        }).then(function (response) {
            if (response.status === 200) {
                setClearConfig(clearConfig => clearConfig + 1)
            }
            setLoading(false)
        }).catch(e => {
            if (e.response.status === 401 || e.response.status === 403) {
                window.location.href = window.location.origin + "/login"
            }
        });


    }
    return (
        <div className="main-container" id="main">
            <div className="title">FTP</div>
            <div className="actions-container">
            </div>
            <form onSubmit={submitHandler}>
                <div className="grid-container">
                    <div className="display-table"><div className="label-container"><label className="" >
                        Usar SCP:</label></div> </div>
                    <div className="value-container">
                        <div className="display-table">
                        <select className="form-control" {...useScp.bind} >
                                <option value="1">Si</option>
                                <option value="0">No</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="display-table">
                        <div className="label-container">
                            <label className=""> Usar SFTP:          </label>
                        </div>
                    </div>
                    <div className="value-container">
                        <div className="display-table">
                        <select className="form-control" {...useSftp.bind} >
                                <option value="1">Si</option>
                                <option value="0">No</option>
                            </select>
                         </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="display-table">
                        <div className="label-container">
                            <label className=""> Host:          </label>
                        </div>
                    </div>
                    <div className="value-container">
                        <div className="display-table">
                            <input className="form-control form-control-sm" type="text"   {...host.bind} />
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="display-table">
                        <div className="label-container">
                            <label className=""> Usuario:          </label>
                        </div>
                    </div>
                    <div className="value-container">
                        <div className="display-table">
                            <input className="form-control form-control-sm" type="text"   {...user.bind} />
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="display-table">
                        <div className="label-container">
                            <label className=""> Contraseña:          </label>
                        </div>
                    </div>
                    <div className="value-container">
                        <div className="display-table">
                            <input className="form-control form-control-sm" type="password"   {...passInput.bind} />
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="display-table">
                        <div className="label-container">
                            <label className=""> Puerto:          </label>
                        </div>
                    </div>
                    <div className="value-container">
                        <div className="display-table">
                            <input className="form-control form-control-sm" type="text"   {...port.bind} />
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="display-table">
                        <div className="label-container">
                            <label className=""> Ruta:          </label>
                        </div>
                    </div>
                    <div className="value-container">
                        <div className="display-table">
                            <input className="form-control form-control-sm" type="text"   {...pathurl.bind} />
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="display-table"><div className="label-container"><label className="" >Usar Modo Pasivo:</label></div> </div>
                    <div className="value-container">
                        <div className="display-table">
                            <select className="form-control" {...passive.bind} >
                                <option value="1">Si</option>
                                <option value="0">No</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="display-table">
                        <div className="label-container">
                            <label className="">        </label>
                        </div>
                    </div>
                    <div className="display-table">
                        <input type="submit" name="Edit" className="btn btn-primary" />
                    </div>
                </div>
            </form>
            <div>{loading && (<Loader />)}</div>
        </div>
    );
}

export default FtpGroup;