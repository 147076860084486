import React from 'react'

export default () => (
  <div id='loading-mask'>
    <p className='loader' id='loading_mask_loader'>
      <div className='spinner-border' role='status'></div>
      <span>Por favor, espere...</span>
    </p>
  </div>
)
