import {useEffect, useState} from 'react';
import {_get} from './utils/apiRequester';
import {getItem} from "./utils/storageUtil";


export default function useSearchOrder(defdateFrom, defdateTo, clearOrder, clearRowOrder, externalId, incrementId, searchDNI, getPickupChannel, page = 1, stateFilter, channelFilter) {

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [dateFrom, setDateFrom] = useState(defdateFrom)
    const [dateTo, setDateTo] = useState(defdateTo)
    const [orders, setOrders] = useState([])
    const [pageNumber, setPageNumber] = useState(page);
    const [pageSize, setPageSize] = useState(0);
    const [totalPages, setTotalPages] = useState(null);
    const [totalOrders, setTotalOrders] = useState(0);
    const [states, setStates] = useState(stateFilter);
    const [channel, setChannel] = useState(channelFilter);

    const getOrders = async (params) => {
        const url = getItem('url')
        return await _get(`${url}/orders/grid`, params);
    }

    useEffect(() => {
        setOrders([])
        setDateFrom(defdateFrom)
        setDateTo(defdateTo)
        setPageNumber(page)
        setStates(stateFilter)
        setChannel(channelFilter)
    }, [clearOrder, externalId, incrementId, searchDNI])

    useEffect(() => {
        setLoading(true)
        setError(false)
        const params = {
            dateFrom,
            dateTo,
            channel_id: channel.join(),
            increment_id: incrementId,
            external_id: externalId,
            dni: searchDNI,
            size: 10,
            page,
            states: states.join()
        };
        getOrders(params).then(response => {
            const {data} = response;
            setOrders(data.items)
            setPageSize(data.paging.perPage);
            setTotalPages(data.paging.pages);
            setTotalOrders(data.paging.total);
        }).catch(() => {
            setError(true)
        }).finally(() => setLoading(false));
    }, [clearRowOrder, page, dateFrom, dateTo, externalId, incrementId, searchDNI, getPickupChannel, states, channel])
    return {
        loading,
        error,
        orders,
        pageNumber,
        pageSize,
        totalPages,
        totalOrders
    }
}
