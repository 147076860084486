import {useEffect, useState} from 'react';
import axios from 'axios';

export default function useGetOrdersPrepared(dateFrom, dateTo, clearMetrics) {
    const [loading4, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [ordersPrepared, setOrdersPrepared] = useState([])


    useEffect(() => {
        setOrdersPrepared([])
    }, [dateFrom, dateTo, clearMetrics])

    useEffect(() => {
        setLoading(false)
        setError(false)
        let cancel
        axios({
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Glamit-Env': localStorage.getItem('store_id')
            },
            url: localStorage.getItem('url') + '/metrics/ordersprepared?dateFrom=' + dateFrom + '&dateTo=' + dateTo,
            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then(response => {
            if (response.data.length > 0) {
                setOrdersPrepared(response.data);
            }
            setLoading(true)
            return;
        }).catch(e => {
            if (axios.isCancel(e)) return
            setError(true)
        })
        return () => cancel()
    }, [dateFrom, dateTo, clearMetrics])

    return {
        loading4,
        error,
        ordersPrepared
    }
}
