import React, { useState, useContext, useEffect } from 'react';
import Context from '../../../context';
import Loader from '../../Loader';
import axios from 'axios';
import useGetChannels from '../../../tools/useGetChannels';

function useInputUseChannel(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}

function useInputPedidosPickup(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}

function useInputSelectedChannel(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}
function useInputPedidosEnvio(defaultValue = '') {
    const [value, setValue] = useState(defaultValue)
    return {
        bind: {
            value,
            onChange: event => setValue(event.target.value)
        },
        clear: () => setValue(''),
        value: () => value,
        addValue: (val) => setValue(val),
    }
}

const SourcingEngine = (props) => {
    const [loading, setLoading] = useState(false)
    const useChannel = useInputUseChannel('')
    const pedidosPickup = useInputPedidosPickup('')
    const selectedChannel = useInputSelectedChannel('')
    const pedidosEnvio = useInputPedidosEnvio('')

    const { configData } = useContext(Context)
    const { setClearConfig } = useContext(Context)
    const { clearConfig } = useContext(Context)
    const {
        channels
    } = useGetChannels(localStorage.getItem('store_id'), 1)

    useEffect(() => {
        configData.map((config, index) => {
            if (config.path === "use_channel") {
                useChannel.addValue(config.value)
            }
            if (config.path === "pedidos_pickup") {
                pedidosPickup.addValue(config.value)
            }
            if (config.path === "selected_channel") {
                selectedChannel.addValue(config.value)
            }
            if (config.path === "pedidos_envio") {
                pedidosEnvio.addValue(config.value)
            }
        })
    }, [configData])
    function submitHandler(event) {
        event.preventDefault()

        var config_tosend = [
            { path: "use_channel", value: useChannel.value(), store_id: localStorage.getItem('store_id') },
            { path: "selected_channel", value: selectedChannel.value(), store_id: localStorage.getItem('store_id') },
            { path: "pedidos_pickup", value: pedidosPickup.value(), store_id: localStorage.getItem('store_id') },
            { path: "pedidos_envio", value: pedidosEnvio.value(), store_id: localStorage.getItem('store_id') },
        ]
        setLoading(true)
        axios({
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Glamit-Env': localStorage.getItem('store_id')
            },
            url: localStorage.getItem('url') + '/config_data',
            data: config_tosend
        }).then(function (response) {
            if (response.status === 200) {
                setClearConfig(clearConfig => clearConfig + 1)
            }
            setLoading(false)
        }).catch(e => {
            if (e.response.status === 401 || e.response.status === 403) {
                window.location.href = window.location.origin + "/login"
            }
        });


    }
    return (

        <div className="main-container" id="main">
            <div className="title">Sourcing Engine</div>
            <div className="actions-container">
            </div>
            <form onSubmit={submitHandler}>
                <div className="grid-container">
                    <div className="display-table"><div className="label-container"><label className="" >
                        Usa channel por defecto:</label></div> </div>
                    <div className="value-container">
                        <div className="display-table">
                            <select className="form-control" {...useChannel.bind} >
                                <option value="1">Si</option>
                                <option value="0">No</option>
                            </select> </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="display-table"><div className="label-container"><label className="" >
                        Elegir channel:</label></div> </div>
                    <div className="value-container">
                        <div className="display-table">
                            <select className="form-control" {...selectedChannel.bind} >
                                <option value=""></option>
                                {channels.map((ch, index) => {
                                    return <option key={index} value={ch.channel_id}>{ch.channel_name}</option>
                                })}
                            </select> </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="display-table"><div className="label-container"><label className="" >
                        Asignar automáticamente pedidos de pickup asignables:</label></div> </div>
                    <div className="value-container">
                        <div className="display-table">
                            <select className="form-control" {...pedidosPickup.bind} >
                                <option value="1">Si</option>
                                <option value="0">No</option>
                            </select> </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="display-table"><div className="label-container"><label className="" >
                    ¿Asignar automáticamente pedidos de envío?:</label></div> </div>
                    <div className="value-container">
                        <div className="display-table">
                            <select className="form-control" {...pedidosEnvio.bind} >
                                <option value="1">Si</option>
                                <option value="0">No</option>
                            </select> </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="display-table">
                        <div className="label-container">
                            <label className="">        </label>
                        </div>
                    </div>
                    <div className="display-table">
                        <input type="submit" name="Edit" className="btn btn-primary" />
                    </div>
                </div>
            </form>
            <div>{loading && (<Loader />)}</div>

        </div>
    );
}

export default SourcingEngine;