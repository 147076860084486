import React, { useState } from 'react';
import Context from '../../context';
import Nav from '../Nav';
import Role from '../../tools/role';
import Sidebar from '../Configuration/include/Sidebar';
import StockGroup from '../Configuration/include/StockGroup';
import FtpGroup from '../Configuration/include/FtpGroup';
import ApiGroup from '../Configuration/include/ApiGroup';
import MappingGroup from '../Configuration/include/MappingGroup';
import General from '../Configuration/include/General';
import SourcingEngine from '../Configuration/include/SourcingEngine';
import Notification from '../Configuration/include/Notification';
import Sincronizaciones from '../Configuration/include/Sincronizaciones';
import useGetConfigData from '../../tools/useGetConfigData';
import Alertas from './include/Alertas';
import Televenta from '../Configuration/include/Televenta';            

function Configuration() {
    if (localStorage.getItem('token') === null || localStorage.getItem('token') === 'null') {
        window.location.href = window.location.origin + "/login"
    }

    Role(["ADMIN"])
    const style = window.document.createElement("link");
    style.href = process.env.PUBLIC_URL + "/css/Configuration.css";
    style.rel = "stylesheet";
    style.async = true;

    window.document.head.appendChild(style);

    const [stockGroup, setStockGroup] = useState(true)
    const [ftpGroup, setFtpGroup] = useState(false)
    const [apiGroup, setApiGroup] = useState(false)
    const [mappingGroup, setMappingGroup] = useState(false)
    const [general, setGeneral] = useState(false)
    const [sourcingEngine, setSourcingEngine] = useState(false)
    const [notification, setNotification] = useState(false)
    const [sincronizaciones, setSincronizaciones] = useState(false)
    const [televenta, setTeleventa] = useState(false)
    const [clearConfig, setClearConfig] = useState(1)
    const [alertas, setAlertas] = useState(false)
    const {
        loading,
        error,
        configData
    } = useGetConfigData(clearConfig)

    return (
        <Context.Provider value={{ configData, clearConfig, setClearConfig, setStockGroup, setFtpGroup, setApiGroup, setMappingGroup, setGeneral, setSourcingEngine, setNotification, setSincronizaciones, setAlertas, setTeleventa }}>


            <div className="page-header">
                <Nav />
            </div>
            <div className="page-container">
                <Sidebar />
                {stockGroup && (<StockGroup data={configData} />)}
                {ftpGroup && (<FtpGroup />)}
                {apiGroup && (<ApiGroup />)}
                {mappingGroup && (<MappingGroup />)}
                {general && (<General />)}
                {alertas && (<Alertas/>)}
                {sourcingEngine && (<SourcingEngine />)}
                {notification && (<Notification />)}
                {sincronizaciones && (<Sincronizaciones />)}
                {televenta && (<Televenta/>)}

            </div>
        </Context.Provider>
    );
}

export default Configuration
